import { render, staticRenderFns } from "./CssTransformWidget.vue?vue&type=template&id=10d5a46e&scoped=true&"
import script from "./CssTransformWidget.vue?vue&type=script&lang=js&"
export * from "./CssTransformWidget.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10d5a46e",
  null
  
)

export default component.exports