<template>
  <div>
    <sideBarProps blockProps="main" activeTab="base" :tagData="tagData" :settingConstructor="settingConstructor" />

    <hr/>
<!--    <b-button v-if="elem.name === 'mainDiv'" variant="danger" class="" v-on:click.stop="addParent()">Переместить родителя</b-button>-->

    <b-button variant="outline-danger"  size="sm" class="" v-on:click.stop="deleteElem()">
      {{$t('Constructor.General.DeleteItem')}}
    </b-button>
    <b-button variant="outline-warning" size="sm" class="ml-2" v-on:click.stop="copyElem()">
      {{$t('Constructor.General.DuplicateElement')}}
    </b-button>
  </div>
</template>

<script>
import sideBarProps from "./_sideBarProps"
import TagDataEditService from "@/helpers/constructor/TagDataEditService"
import Swal from "sweetalert2";

export default {
  name: "elemMain",
  components: { sideBarProps },
  props: ['settingConstructor', 'tagData'],
  data() {
    return {
      elem: {}
    }
  },
  mounted() {
    if (this.settingConstructor.drags.activeElem === null) {
      return
    }
    this.elem = this.tagData.htmlElements[this.settingConstructor.drags.activeElem]
  },
  methods: {
    ...TagDataEditService,
    deleteElem() {
      Swal.fire({
        title: this.$t('Constructor.Dialog.DeleteElem.Title'),
        text: this.$t('Constructor.Dialog.DeleteElem.Text'),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t('General.Base.Delete'),
        cancelButtonText: this.$t('General.Base.Cancel')
      }).then((result) => {
        if (result.isConfirmed) {
          this.TDESDelElemWithChild(this.settingConstructor.drags.activeElem)
          this.settingConstructor.drags.activeElem = null
          this.settingConstructor.sideBar.prop = false
          this.settingConstructor.sideBar.elem = true
        }
      })
    },
    copyElem() {
      this.TDESCopyElem(this.settingConstructor.drags.activeElem, null, this.tagData.htmlElementsTree[this.settingConstructor.drags.activeElem])
    },
    addParent() {

    }
  }
}
</script>

<style scoped>

</style>
