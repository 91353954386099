<template>
  <div>
      <b-tabs lazy pills justified small content-class="pt-0" >
        <b-tab
            v-for="(element, index) in BasePropElems[blockProps]"
            :key="index"
            :active="activeTab === index"
            :title="$t('Constructor.PropsTabs.' + index)"
            title-link-class="font-size-11 pt-1 pb-1 pl-1 pr-1"
        >
          <hr class="mt-2 mb-2"/>
          <div
              v-for="(property, index) in element"
              :key="index"
          >
            <RenderProp
                :tagData="tagData"
                :blockProps="blockProps"
                :settingConstructor="settingConstructor"
                :prop="property"
                :elem="elem"
                :activeElemId="activeElemId"
                :BasePropElems="BasePropElems"
            />
          </div>
        </b-tab>
      </b-tabs>
  </div>
</template>

<script>
// import deepmerge from 'deepmerge' //@TODO: выпилить либу совсем, вроде она используется еще в одном месте, для копирования обьекта (чтобы ссылки не было)
import RenderProp from './_renderProp'

export default {
  name: "sideBarProps",
  props: ['settingConstructor', 'tagData', 'blockProps', 'activeTab'],
  data() {
    return {
      BasePropElems: {},
      elem:{},
      activeElemId: null
    }
  },
  components: { RenderProp },
  // computed: {
  //   activeElemId: function() {
  //     if (this.settingConstructor.cssEditor.editAnotherElem !== null) {
  //       return this.settingConstructor.cssEditor.editAnotherElem
  //     }
  //   }
  // },
  mounted() {
    if (this.settingConstructor.drags.activeElem === null) {
      return
    }

    if (this.settingConstructor.cssEditor.editAnotherElem !== null) {
      this.elem = this.tagData.htmlElements[this.settingConstructor.cssEditor.editAnotherElem]
      this.activeElemId = this.settingConstructor.cssEditor.editAnotherElem
    } else {
      this.elem = this.tagData.htmlElements[this.settingConstructor.drags.activeElem]
      this.activeElemId = this.settingConstructor.drags.activeElem
    }

    this.$store.dispatch('tagEditorPropElem/getPatterns', this.elem).then(data => {
      this.BasePropElems = data
    })
  },
  created() {

  }
}
</script>
