<template>
  <div>
    <popper
        trigger="clickToOpen"
        :options="{
            placement: 'left',
            modifiers: {
              offset: {
                offset: '0,10px'
              }
            }
        }"
    >
      <div class="popperColor bg-white">

        <sketch-picker v-model="color" />

        <div class="colorsBlock">

          <div
              v-for="(colorPattern, index) in colorsPattern"
              :key="index"
              :class="checkColorOnTransparent(colorPattern)"
              :style="{'background-color': colorPattern}"
              @click.stop="color = colorPattern"
          ></div>
        </div>

      </div>

      <div
          :class="'colorPick ' + checkColorOnTransparent(color)"
          :style="{'background-color': color}"
          slot="reference">
      </div>
    </popper>

  </div>
</template>

<script>
import { Chrome } from 'vue-color'
import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';
import {isString} from "lodash";

export default {
  name: "ColorWidget",
  props: [ "value" ],
  components: {
    'sketch-picker': Chrome,
    'Popper': Popper
  },
  data() {
    return {
      colorsPattern: [
        'rgb(244, 67, 54)',
        'rgb(233, 30, 99)',
        'rgb(156, 39, 176)',
        'rgb(103, 58, 183)',
        'rgb(63, 81, 181)',
        'rgb(33, 150, 243)',
        'rgb(3, 169, 244)',
        'rgb(0, 188, 212)',
        'rgb(0, 150, 136)',
        'rgb(76, 175, 80)',
        'rgb(139, 195, 74)',
        'rgb(205, 220, 57)',
        'rgb(255, 235, 59)',
        'rgb(255, 193, 7)',
        'rgb(255, 152, 0)',
        'rgb(255, 87, 34)',
        'rgb(121, 85, 72)',
        'rgb(158, 158, 158)',
        'rgb(96, 125, 139)',
        'rgb(171, 178, 191)',
        'rgb(255, 255, 255)',
        'rgb(221, 221, 221)',
        'rgb(170, 170, 170)',
        'rgb(153, 153, 153)',
        'rgb(102, 102, 102)',
        'rgb(51, 51, 51)',
        'rgb(0, 0, 0)',
        'rgba(255, 255, 255, 0)'
      ],
      show: true
    }
  },
  computed: {
    color: {
      get: function() {
        if (this.value) {
          return this.value
        }
        return 'rgba(255, 255, 255, 0)';
      },
      set: function(val) {
        if (!isString(val)) {
          val = 'rgba('+val.rgba.r+','+val.rgba.g+','+val.rgba.b+','+val.rgba.a+')'
        }

        this.$emit('change', val)
        this.$emit('input', val)
      }
    }
  },
  methods: {
    checkColorOnTransparent(value) {
      if (value === null) {
        return 'transparent'
      }
      if (value === 'rgba(255, 255, 255, 0)') {
        return 'transparent'
      }
      return ''
    },
    // toogleTooltip() {
    //   if (this.show) {
    //     this.$refs.tooltip.$emit('close')
    //     return
    //   }
    //   this.$refs.tooltip.$emit('open')
    // }
    // onOpen() {
    //   this.$refs.tooltip.$emit('open')
    // },
    // onClose() {
    //   this.$refs.tooltip.$emit('close')
    // }
  },
  // mounted() {
  //   console.log(this.value)
  // }
}
</script>

<style scoped>
  .colorPick {
    height: 22px;
    width: 22px;
    border: 1px solid rgb(232, 232, 232);
    border-radius: 4px;
    display: inline-block;
    vertical-align: top;
    transition: transform .1s ease;
    cursor: pointer;
  }
  .colorPick:hover {
    transform: scale(1.1);
  }
  .vc-chrome {
    box-shadow: none;
  }
  .popperColor {
    z-index: 10;
    box-shadow: 0 0 2px rgb(0 0 0 / 30%), 0 4px 8px rgb(0 0 0 / 30%);
  }
  .colorsBlock {
    background: #ffffff;
    display: flex;
    flex-flow: wrap;
    margin-left: 8px;
  }
  .colorsBlock div {
    margin: 5px;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    transition: transform .1s ease;
    box-shadow: 0 0 1px #aaa;
    cursor: pointer;
  }
  .colorsBlock div:hover {
    transform: scale(1.5);
  }
  .transparent {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMElEQVQ4T2N89uzZfwY8QFJSEp80A+OoAcMiDP7//483HTx//hx/Ohg1gIFx6IcBALl+VXknOCvFAAAAAElFTkSuQmCC);
  }
</style>