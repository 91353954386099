<template>
  <b-input-group
      v-if="basePropElems.js.actions[actionObj.type].child.widgets === 'getElem'"
  >
    <b-form-select
        class="col-8"
        :options="getAllElements()"
        v-model="actionObj.data.elemId"
    ></b-form-select>
  </b-input-group>
</template>

<script>
import TagDataGetElemsHelper from "@/helpers/constructor/TagDataGetElemsHelper";

export default {
  name: "GetElemWidget",
  props: ["tagData", "settingConstructor", "basePropElems", "actionObj"],
  data() {
    return {
      dataPattern: {
        elemId: null
      }
    }
  },
  created() {
    if (this.actionObj.data === null) {
      this.actionObj.data = this.dataPattern
    }
  },
  methods: {
    ...TagDataGetElemsHelper,
    getAllElements() {
      // if (this.actionObj.data.elemId !== null) {
      //   return this.getAllElementsToOptions()
      // }
      return [...[{value: null, text: this.$t('General.Base.SelectItem')}], ...this.getAllElementsToOptions()]
    }
  }
}
</script>
