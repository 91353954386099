<template>
  <b-input-group size="sm">
    <b-form-select
        v-model="select"
        :options="optionsSelect"
        size="sm"
    >
      <template #first>
        <b-form-select-option :value="null" disabled>---</b-form-select-option>
      </template>
    </b-form-select>

    <b-form-input
        :placeholder=" $t('General.Form.NoSet')"
        v-model="numb"
        type="number"
        class="col-6 propField mousetrap"
        v-b-tooltip.hover.ds1000.dh0
        maxlength = "11"
    >
    </b-form-input>

    <multiselect
        v-if="units"
        track-by="code"
        label="name"
        v-model = "unit"
        :options="units"
        class="mousetrap multiselect-sm form-control"
        :show-labels="false"
        :searchable="false"
        :deactivated = "(units.length === 1)"
        placeholder = "--"
    >
    </multiselect>

<!--    <b-form-select-->
<!--        v-if="units"-->
<!--        class="col-6"-->
<!--        :options="units"-->
<!--        v-model="unit"-->
<!--        :disabled="(units.length === 1)"-->
<!--        v-b-tooltip.hover.ds1000.dh0-->
<!--    ></b-form-select>-->
  </b-input-group>
</template>

<script>
import Multiselect from "vue-multiselect";
import { prepareDataForMultiselect } from "@/helpers/constructor/MethodsForChangePropsElem";

export default {
  name: "SelectoNumberField",
  components: { Multiselect },
  props: [ "value", "options", "units" ],
  data: function() {
    return {
      // select: null,
      // numb: '',
      // unit: null,
      optionsSelect: []
    }
  },
  computed: {
    select: {
      get: function() {
        if (typeof this.value === 'string') {
          return this.value
        }
        return null
      },
      set: function(val) {
        if (this.value === val) {
          return
        }
        this.changeValue(val)
      }
    },
    numb: {
      get: function() {
        if (typeof this.value === 'object' && this.value !== null) {
          return this.value.val
        }
        return ''
      },
      set: function(value) {
        if (value === '') {
          if ((this.value.val !== undefined && value === this.value.val) || value === this.value) {
            return
          } else {
            this.changeValue(null)
            return
          }
        }

        if (this.value !== null && this.value.val !== undefined && this.value.val === value) {
          return
        }

        let data = {
            val: value
        }
        if (this.unit) {
            data.unit = this.unit.code
        }

        this.changeValue(data)

      }
    },
    unit: {
      get: function () {
        if (typeof this.value === 'object' && this.value !== null && this.value.unit) {
          return prepareDataForMultiselect(this.value.unit)
        }
        if (this.units) {
          return this.units[0]
        }
        return null
      },
      set: function (value) {
        if (!this.units) {
          return
        }
        if (this.numb === '') {
          return
        }
        if (this.value !== null && this.value.unit !== undefined && this.value.unit === value.code) {
          return
        }

        let val = {
          val: this.numb,
          unit: value.code
        }
        this.changeValue(val)
      }
    }
  },
  methods: {
    // ...prepareDataForMultiselect,
    changeValue(val) {
      // if (val === null) {
      //   return
      // }
      this.$emit('change', val)
      this.$emit('input', val)
    }
  },
  mounted() {

    if (!this.value && this.units) {
      this.unit = this.units[0]
    }
    if (typeof this.value === 'object' && this.value !== null) {
      this.numb = this.value.val
      this.unit = prepareDataForMultiselect(this.value.unit)
    } else if (this.value !== null) {
      this.select = this.value
    }

    this.options.forEach(elem => {
      this.optionsSelect.push(
        { value: elem.code, text: elem.name }
      )
    })

  }
}
</script>

<style scoped>

</style>