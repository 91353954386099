import { render, staticRenderFns } from "./BorderRadiusWidget.vue?vue&type=template&id=25d6f949&scoped=true&"
import script from "./BorderRadiusWidget.vue?vue&type=script&lang=js&"
export * from "./BorderRadiusWidget.vue?vue&type=script&lang=js&"
import style0 from "./BorderRadiusWidget.vue?vue&type=style&index=0&id=25d6f949&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25d6f949",
  null
  
)

export default component.exports