<template>
  <div>

    <loading v-if="userGalleryData === null"  />

      <div v-if="userGalleryData === null || userGalleryData.length === 0" class="row">
        <div class="col-12 text-center p-5">
          <h5>{{$t('Constructor.Gallery.UserImageEmptyH5')}}</h5>

          <p class="lead mt-4">{{$t('Constructor.Gallery.UserImageEmptyText')}}</p>
        </div>
      </div>
      <div v-if="userGalleryData !== null && userGalleryData.length > 0" class="row">

        <div class="col-4 p-2 text-center"
             v-for="(img, index) in userGalleryData"
             :key="index"
        >
          <b-row align-h="between">
            <b-col cols="auto" class="font-size-11 pt-1">{{getFileSize(img.file_size)}}Mb.</b-col>
            <b-col cols="auto" class="ml-auto">
              <b-button squared variant="link" pill v-b-tooltip.hover :title="$t('General.Base.Delete')" v-on:click.stop="deleteImage(img)" class="deleteImgButton">
                <i class="mdi mdi-close-thick"></i>
              </b-button>
            </b-col>
          </b-row>

          <b-img-lazy
              fluid
              v-b-tooltip.hover :title="img.user_file_name"
              :src="getUrlThumbFile(img)"
              :alt="img.user_file_name">
          </b-img-lazy>
          <div  v-if="requestImage" class="blockSelectImg w-100">
            <b-button squared variant="light" size="sm" v-on:click.stop="setResponseImage(img)" >
              {{$t('General.Base.Select')}}
            </b-button>
          </div>
        </div>
      </div>



    <div class="row mt-4">
      <div class="col-12">
            <!-- file upload -->
            <vue-dropzone
                id="dropzone"
                ref="myVueDropzone"
                :use-custom-slot="true"
                :options="dropzoneOptions"
                v-on:vdropzone-success="uploadFileComplete"
            >
              <div class="dropzone-custom-content">
                <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                <h4>{{$t('Components.FileUpload.Info')}}</h4>
              </div>
            </vue-dropzone>
<!--            <div class="text-center mt-4">-->
<!--              <button type="button" class="btn btn-primary">Отправить файлы</button>-->
<!--            </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import Swal from "sweetalert2";
import loading from "./_loading";

// @TODO: добавить разрешение в 36 строку 2000x2000px. рядом с мб

export default {
  name: "userGallery",
  components: { vueDropzone: vue2Dropzone, loading},
  // prop: [ 'requestImage' ],
  data() {
    return {
      title: "Form File Upload",
      items: [
        {
          text: "Forms",
          href: "/"
        },
        {
          text: "Form File Upload",
          active: true
        }
      ],
      dropzoneOptions: {
        url: process.env.VUE_APP_SERVER + "user/user-file/upload-file",
        thumbnailWidth: 150,
        maxFilesize: 10,
        withCredentials: true,
        acceptedFiles: 'image/*,application/json'
      },
      storageServer: process.env.VUE_APP_STORAGE_SERVER
    };
  },
  computed: {
    requestImage: function() {
      if (!this.$store.state.userGallery.requestImage || this.$store.state.userGallery.requestImage === 'svg') {
        return false
      }
      return true
    },
    userGalleryData: function () {
      return this.$store.state.userGallery.gallery
    }
  },
  methods: {
    getUrlThumbFile(file) {
      if (file.file_type === "image/svg+xml") {
        return process.env.VUE_APP_STORAGE_SERVER + file.file_name
      }
      return process.env.VUE_APP_STORAGE_SERVER + '200x200_' + file.file_name
    },
    getUrlFile(file_name) {
      return process.env.VUE_APP_STORAGE_SERVER + file_name
    },
    uploadFileComplete (file, response) {
      if (response.status === 'error') {
        this.$store.dispatch('notification/error', this.$t("Notification.FileUploadError")); //@TODO: текст поправить
        return
      }
      this.$store.dispatch('userGallery/pushItemGallery', response.data)
      //@TODO: обновить доступный файловый лимит
    },
    setResponseImage ( img ) {
      this.$emit('setResponseImage', {
        provider: 'eefim',
        imageId: img.id,
        url: this.getUrlFile(img.file_name),
        urlThumb: this.getUrlThumbFile(img),
        userFileName: img.user_file_name
      })
    },
    getFileSize(file_size) {
      return Math.round(file_size/1024/1024 * 100) / 100
    },
    deleteImage(img) {
      Swal.fire({
        title: this.$t('Constructor.Gallery.Delete.Title'),
        text: this.$t('Constructor.Gallery.Delete.Text'),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t('Constructor.Gallery.Delete.ConfirmButtonText'),
        cancelButtonText: this.$t('General.Base.Cancel')
      }).then(result => {
        if (result.isConfirmed) {

          this.$store.dispatch('userGallery/deleteImg', img).then(() => {
            Swal.fire(
                this.$t('Constructor.Gallery.Delete.SuccessTittle'),
                this.$t('Constructor.Gallery.Delete.SuccessText'),
                "success"
            );
          })
        }
      });
    },
  }
}
</script>

<style scoped>

</style>
