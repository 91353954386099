<template>
  <div v-if="showProp" class="mt-2">
    <b-form-group
        :label-cols-sm="4"
        :label="$t('Constructor.Props.' + blockProps + '.' + prop.name + '.label')"
        label-for="backgroundSize"
        class="mb-1 pt-0 pb-0"
        label-class="p-1"
        v-b-tooltip.viewport.hover.ds500.dh0.html.left="getTooltipLabel()"
    >
      <b-form-select
          class="col-12"
          :options="prop.options"
          v-model="mainSelectValue"
          v-b-tooltip.hover
          name="backgroundSize"
          placeholder="auto"
          size="sm"
      ></b-form-select>

      <b-badge
          v-if="elemTextDefaultProp"
          variant="info"
          class="stuckBadge"
          v-b-tooltip.ds1000.dh0.viewport.left
          :title="elemTextDefaultProp"
          :style="{ backgroundColor: elemTextDefaultProp}"
      >{{elemTextDefaultProp}}</b-badge>

      <b-form-text v-if="prop.description">
        {{prop.description}}
        <i
            v-if="prop.tooltip"
            v-b-tooltip.hover
            :title="prop.tooltip"
            class="far fa-question-circle"></i>
      </b-form-text>
    </b-form-group>



    <b-form-group
        v-if="mainSelectValue === 'manual'"
        label-cols-sm="4"
        :label="$t('Constructor.Props.' + blockProps + '.' + prop.name + '.backgroundSizeManual.x.label')"
        label-for="backgroundSizeX"
        class="mb-1 pt-0 pb-0"
        label-class="p-1"
    >
      <b-input-group  size="sm" >
        <b-form-input
            placeholder="auto"
            v-model="manualValueX"
            type="number"
            class="col-6 propField"
            name="backgroundSizeX"
        >
        </b-form-input>
        <b-form-select
            class="col-6"
            :options="units"
            v-model="manualValueXUnit"
            :disabled="manualValueXUnit === null ? true : false"
        ></b-form-select>
      </b-input-group>
<!--      <b-form-text>-->
<!--        описать свойство Х-->
<!--        <i-->
<!--            v-b-tooltip.hover-->
<!--            title="описать свойство Х"-->
<!--            class="far fa-question-circle"></i>-->
<!--      </b-form-text>-->
    </b-form-group>

    <b-form-group
        v-if="mainSelectValue === 'manual'"
        label-cols-sm="4"
        :label="$t('Constructor.Props.' + blockProps + '.' + prop.name + '.backgroundSizeManual.y.label')"
        label-for="backgroundSizeY"
        class="mb-1 pt-0 pb-0"
        label-class="p-1"
    >
      <b-input-group  size="sm">
        <b-form-input
            placeholder="auto"
            v-model="manualValueY"
            type="number"
            class="col-6 propField"
            name="backgroundSizeY"
        >
        </b-form-input>
        <b-form-select
            class="col-6"
            :options="units"
            v-model="manualValueYUnit"
            :disabled="manualValueYUnit === null ? true : false"
        ></b-form-select>
      </b-input-group>
<!--      <b-form-text>-->
<!--        описать свойство Y-->
<!--        <i-->
<!--            v-b-tooltip.hover-->
<!--            title="описать свойство Y"-->
<!--            class="far fa-question-circle"></i>-->
<!--      </b-form-text>-->
    </b-form-group>
  </div>
</template>

<script>
import PropsHelper from "@/helpers/constructor/sideBar/props/PropsComputed";
import ShowProp from '@/helpers/constructor/sideBar/props/MethodShowProp'
import HistoryHelper from '@/helpers/constructor/HistoryHelper'

export default {
  name: "WidgetBackgroundSize",
  props: [ 'elem', 'prop', 'tagData', 'indexElem', 'settingConstructor', 'nameProp' ],
  data: function() {
    return {
      units: ['px', '%'],
      defaultUnit: 'px',
      blockProps: 'css'
    }
  },
  computed: {
    ...ShowProp,
    ...PropsHelper,
    mainSelectValue: {
      get: function() {
        if (this.elemCurrentProp.backgroundSize === undefined) {
          return null
        }
        if (typeof(this.elemCurrentProp.backgroundSize) === 'string' ) {
          return this.elemCurrentProp.backgroundSize
        }
        return 'manual'
      },
      set: function(value) {
        if (value === 'manual') {
          this.HistorySet(this.elemCurrentProp, 'backgroundSize', {0: 'auto', 1: 'auto'})
          // this.$set(this.elemCurrentProp, 'backgroundSize', ['auto', 'auto'])
          return
        }
        this.HistorySet(this.elemCurrentProp, 'backgroundSize', value)
        // this.$set(this.elemCurrentProp, 'backgroundSize', value)
      }
    },
    manualValueX: {
      get: function() {
        return this.getManualValue(0)
      },
      set: function(value) {
        return this.setManualValue(0, value)
      }
    },
    manualValueXUnit: {
      get: function() {
        return this.getManualValueUnit(0)
      },
      set: function(value) {
        return this.setManualValueUnit(0, value)
      }
    },
    manualValueY: {
      get: function() {
        return this.getManualValue(1)
      },
      set: function(value) {
        return this.setManualValue(1, value)
      }
    },
    manualValueYUnit: {
      get: function() {
        return this.getManualValueUnit(1)
      },
      set: function(value) {
        return this.setManualValueUnit(1, value)
      }
    },
  },
  methods: {
    ...HistoryHelper,
    getManualValue(index) {
      if (this.elemCurrentProp.backgroundSize === undefined ||
          typeof(this.elemCurrentProp.backgroundSize) === 'string' ||
          typeof(this.elemCurrentProp.backgroundSize[index]) === 'string'
      ) {
        return null
      }

      return this.elemCurrentProp.backgroundSize[index].val
    },
    setManualValue(index, value) {
      if (value === '') {
        this.HistorySet(this.elemCurrentProp.backgroundSize, index, 'auto')
        // this.$set(this.elemCurrentProp.backgroundSize, index, 'auto')
        return
      }

      let unit = null
      if (index === 0) {
        unit = this.manualValueXUnit
      } else {
        unit = this.manualValueYUnit
      }
      this.HistorySet(this.elemCurrentProp.backgroundSize, index, {
        val: value,
        unit: unit !== null ? unit : this.defaultUnit
      })
      // this.$set(this.elemCurrentProp.backgroundSize, index, {
      //   val: value,
      //   unit: this.manualValueXUnit !== null ? this.manualValueXUnit : this.defaultUnit
      // })
    },
    getManualValueUnit(index) {
      if (this.elemCurrentProp.backgroundSize === undefined ||
          typeof(this.elemCurrentProp.backgroundSize) === 'string' ||
          typeof(this.elemCurrentProp.backgroundSize[index]) === 'string'
      ) {
        return null
      }
      return this.elemCurrentProp.backgroundSize[index].unit
    },
    setManualValueUnit(index, value) {
      if (value === null) {
        return
      }
      this.HistorySet(this.elemCurrentProp.backgroundSize[index], 'unit', value)
      // this.$set(this.elemCurrentProp.backgroundSize[index], 'unit', value)
    },
    getTooltipLabel() {
      return this.$t('Constructor.Props.' + this.blockProps + '.' + this.prop.name + '.labelTooltip') ?
          this.$t('Constructor.Props.' + this.blockProps + '.' + this.prop.name + '.labelTooltip') : null
    }
  }
}
</script>

<style scoped>

</style>
