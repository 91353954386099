import { allStyleReset, styleResetElemTag, styleResetElemType } from './patterns/styleElem/ResetBase'
import {styleDefault, styleDefaultTag} from './patterns/styleElem/Default' //allStyleDefault
import lodash from 'lodash'
import deepmerge from "deepmerge";

export default class ElemStyleHelper {
    constructor() { // style = 'default'
        // if (style === 'default') {
        //
        // }
        this.styleSetting = styleDefault
        this.styleSettingTag = styleDefaultTag
    }

    // getStyleElem(patternElem, type_elem) {
    //
    //     if (this.styleSetting[type_elem] === undefined) {
    //         if (styleResetElem[patternElem.tag] === undefined) {
    //             patternElem.css = lodash.cloneDeep({...allStyleReset, ...allStyleDefault, ...patternElem.css })
    //         } else {
    //             patternElem.css = lodash.cloneDeep({...allStyleReset, ...styleResetElem[patternElem.tag], ...allStyleDefault, ...patternElem.css })
    //         }
    //
    //         return patternElem
    //     }
    //     patternElem.css = lodash.cloneDeep({...allStyleReset, ...allStyleDefault, ...this.styleSetting[type_elem], ...patternElem.css  })
    //
    //     return patternElem
    // }


    getStyleElem(elem) {
        if (this.styleSetting[elem.name] === undefined) {
            this.styleSetting = styleDefault[elem.name] !== undefined ? styleDefault[elem.name] : {}
        } else {
            this.styleSetting = this.styleSetting[elem.name]
        }

        return this.processingApplyStyle(elem, this.styleSetting)
    }


    processingApplyStyle(elem, stylePattern) {
        if (elem.elemChild !== undefined) {
            for (let i in elem.elemChild) {
                if (stylePattern !== null && stylePattern.children !== undefined && stylePattern.children[i]) {
                    elem.elemChild[i] = this.processingApplyStyle(elem.elemChild[i], stylePattern.children[i])
                } else {
                    elem.elemChild[i] = this.processingApplyStyle(elem.elemChild[i], null)
                }

            }
        }
        return this.applyStyle(elem, lodash.cloneDeep(stylePattern))
    }

    applyStyle(elem, stylePattern = null) {
        if (elem.tag === null) {
            return elem
        }
        if (stylePattern !== null && stylePattern.children !== undefined) {
            delete stylePattern.children
        }

        elem.css = {...this.getStyleReset(elem), ...elem.css }

        if (this.styleSettingTag[elem.tag] !== undefined) {
            // elem = {...elem, ...this.styleSettingTag[elem.tag] }
            elem = deepmerge(elem, this.styleSettingTag[elem.tag])
        }

        if (stylePattern !== null && stylePattern.mainDiv === undefined) {
            // elem = {...elem, ...stylePattern}
            elem = deepmerge(elem, stylePattern)
            return elem
        }

        // if (styleDefault[elem.name] === undefined) {
        //     elem.css = {...elem.css, ...allStyleDefault}
        //     return elem
        // }
        //
        // elem.css = {...elem.css, ...allStyleDefault, ...styleDefault[elem.name]}
        return elem
    }

    getStyleReset(elem) {
        let total = lodash.cloneDeep(allStyleReset)
        if (styleResetElemTag[elem.tag] !== undefined) {
            total = lodash.cloneDeep({...total, ...styleResetElemTag[elem.tag]})
        }
        if (styleResetElemType[elem.type] !== undefined) {
            total = lodash.cloneDeep({...total, ...styleResetElemType[elem.type]})
        }
        return total
    }
}
