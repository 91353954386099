<template>
    <b-input-group class="mb-1" size="sm">
      <b-input-group-prepend v-if="!newOptions" is-text >

        <input
            type="checkbox"
            :name="'selectOptions_' + radioGroupElemId"
            :value=option.elemIdRadio
            v-model="checkBox"
            v-b-tooltip.viewport.hover.ds500.dh0.html.left
            :title = "$t('Constructor.PropWidgets.CheckboxGroupWidget.PointDefault')"
            :checked="checkBox"
        >

<!--        <b-form-radio-->
<!--            v-model="checkBox"-->
<!--            :name="'selectOptions_' + radioGroupElemId"-->
<!--            :value=option.elemIdRadio-->
<!--        />-->


      </b-input-group-prepend>


      <TextEditor
          :tagData = tagData
          :textElemId = option.elemIdText
          :settingConstructor="settingConstructor"
          :classInput="'col-12'"
      />


<!--      <b-form-input-->
<!--          :placeholder="$t('General.Form.NoSet')"-->
<!--          v-model="tagData.htmlElements[option.elemIdText].main.content"-->
<!--          type="text"-->
<!--          :required="false"-->
<!--          class="col-12 propField"-->
<!--          v-b-tooltip-->
<!--          title="Значение опции"-->
<!--      >-->
<!--      </b-form-input>-->


      <div v-if="!noset" class="input-group-append">
        <b-button
            variant="primary"
            size="sm"
            @click = "goToLabel"
        >
          <i class="mdi mdi-card-text-outline"></i>
        </b-button>

        <b-button
            v-if="!newOptions"
            class=""
            variant="danger"
            v-on:click.stop="deleteOptions(option.labelId)"
            v-b-tooltip.viewport.hover.ds500.dh0.html.left
            :title = "$t('Constructor.PropWidgets.CheckboxGroupWidget.DeleteOption')"
        >
          <i class="fas fa-minus"></i>
        </b-button>
      </div>
    </b-input-group>


</template>

<script>
import HistoryHelper from '@/helpers/constructor/HistoryHelper'
import TextEditor from "@/views/pages/ConstructorHtml/sideBar/sideBarProprs/widgets/TextEditor";
import TagDataGetElemsHelper from "@/helpers/constructor/TagDataGetElemsHelper";
import TagDataEditService from "@/helpers/constructor/TagDataEditService";

export default {
  name: "options",
  components: { TextEditor },
  props: ["option", "index", "newOptions", "elemCurrentProp", "noset", "tagData", "settingConstructor", "radioGroupElemId"],
  data: function() {
    return {

    }
  },
  computed: {
    noSetOption: {
      get: function() {
        for (let i in this.elemCurrentProp.selectOptions) {
          if (this.elemCurrentProp.selectOptions[i].val === null) {
            return true;
          }
        }
        return false
      },
      set: function(val) {
        if (val) {
          this.HistoryPush(this.elemCurrentProp, 'selectOptions', this.option)
          // this.elemCurrentProp.selectOptions.push(this.option)
        } else {
          let indexNull;
          for (let i in this.elemCurrentProp.selectOptions) {
            if (this.elemCurrentProp.selectOptions[i].val === null) {
              indexNull = i
              break
            }
          }
          this.HistoryDelete(this.elemCurrentProp.selectOptions, indexNull)
          // this.$delete(this.elemCurrentProp.selectOptions, indexNull)
        }
      }
    },
    checkBox: {
      get: function() {
        if (this.tagData.htmlElements[this.option.elemIdRadio].main.attr_checked) {
          return this.option.elemIdRadio
        }
        return false
      },
      set: function (checked) {
        let children = this.getAllChildElem(this.radioGroupElemId, {name: "radiobutton"})
        let actions = []
        for (let id in children ) {
          actions.push({obj: this.tagData.htmlElements[children[id]].main, nameProp: 'attr_checked',val: false, type: 'set'})
        }
        if (checked) {
          actions.push({obj: this.tagData.htmlElements[this.option.elemIdRadio].main, nameProp: 'attr_checked',val: true, type: 'set'})
        }

        this.HistorySetPackage(actions)
      }
    }
    // textOption() {
    //   this.option.elemIdText
    // }
  },
  methods: {
    ...HistoryHelper,
    ...TagDataGetElemsHelper,
    ...TagDataEditService,
    addOptions() {
      this.$emit('addOptions', this.option)
    },
    deleteOptions(elemId) {
      this.$emit('deleteOptions', elemId)
    },
    goToLabel: function() {
      this.TDESElemActive(this.option.elemIdText)
    }
  }
}
</script>

<style scoped>

</style>
