// import Vue from 'vue';
import { tagsService } from '@/helpers/fakebackend/tags.service';
import { defaultTagData } from '@/helpers/constructor/patterns/DefaultTagData'
// import deepmerge from "deepmerge";
// import {PatternElems} from "@/helpers/constructor/patterns/PatternElems";
import lodash from 'lodash'
import ElemStyleHelper from "@/helpers/constructor/ElemStyleHelper";
import store from '@/state/store'

export default class TagDataServerHelper {
    static async getTag(tag_id) {
        // let tagData = JSON.parse(localStorage.getItem('tagsData'));
        // if (tagData && tagData.tag_id !== tag_id && tagData.saveVersion !== tagData.version) {
        //     //@TODO: Выдать алерт о необходимости сохранить данные
        //     console.log(tagData.saveVersion, tagData.version, tagData.tag_id, tag_id)
        // }
        let tagData = false

        const dataServ = await tagsService.getTagSource({ id: tag_id })
        if (dataServ.data ) {
            if (dataServ.data.tag_id === null) {
                dataServ.data.tag_id = dataServ.id
            }
            this.saveToLocalStorage(dataServ.data)
            return {
                tagData: dataServ.data,
                tag: dataServ.tag
            }
        }
        // if (tagData && dataServ.data ) {
        //     if (tagData.version > dataServ.data.version) {
        //         this.sendTagToServe(tagData)
        //         return tagData
        //     } else {
        //         this.saveToLocalStorage(dataServ.data)
        //         return dataServ.data
        //     }
        // }

        tagData = lodash.cloneDeep(defaultTagData)
        tagData.htmlElements['e0'] = (new ElemStyleHelper()).getStyleElem(tagData.htmlElements['e0'])
        tagData.tag_id = dataServ.id
        tagData.name = dataServ.name

        this.saveToLocalStorage(tagData)

        return {
            tagData: tagData,
            tag: dataServ.tag
        }
    }

    // static checkErrorLocalStorageTag(tag_id) {
    //     let tagData = JSON.parse(localStorage.getItem('tagsData'));
    //     if (!tagData) {
    //         return null;
    //     }
    //     if (tagData.tag_id !== tag_id && tagData.saveVersion !== tagData.version) {
    //         return 'errorOldTag'
    //         //@TODO: Выдать алерт о необходимости сохранить данные
    //         console.log(tagData.saveVersion, tagData.version, tagData.tag_id, tag_id)
    //     }
    //     if (tagData.tag_id === tag_id && tagData.saveVersion !== tagData.version)
    // }

    static saveToLocalStorage(data) {
        // console.log('save version ' + data.version)
        //@TODO: Доделать автосохранение и востановление из локалсторедж при открытии
        window.localStorage.setItem('tagsData', JSON.stringify(data))
    }

    static deleteLocalStorage() {
        window.localStorage.removeItem('tagsData')
    }

    static async sendTagToServe(tag, mode = 'auto') {
        store.commit('lego/saveState/savingServerOn')
        // const oldVersion = tag.version
        tag.saveVersion = tag.version
        let data = {
            mode: mode,
            tag_source_id: tag.tag_id,
            data: JSON.stringify(tag)
        }
        return tagsService.updateTag(data).then((answer) => {
            // if (answer.id !== tag.tag_id) {
            //     tag.tag_id = answer.id
            // }

            store.commit('lego/saveState/savingServerOff')
            return answer

            // tag.saveVersion = version
            // this.saveToLocalStorage(tag)

        })

        //@TODO: если ошибка сохранения то откатить номер сохраненной версии
        // tag.saveVersion =oldVersion
    }
    static async saveTag(tag, settingConstructor, mode = 'auto') {
        let tagFake = lodash.cloneDeep(tag)
        if (settingConstructor.drags.activeElem !== null) {
            tagFake.htmlElements[settingConstructor.drags.activeElem].active = false
        }

        return this.sendTagToServe(tagFake, mode).then((answer) => {
            let dataWidgetFromServer = JSON.parse(answer.data)
            for (let i in dataWidgetFromServer) {
                tag[i] = dataWidgetFromServer[i]
            }

            this.saveToLocalStorage(tag)
            settingConstructor.startVersion = tag.version
            return answer
        })





    }
}
