<template>
  <div class="mb-4">
<!--    <div>-->
<!--      <b-form-select-->
<!--          class="col-6"-->
<!--          :options="optionsForSelect"-->
<!--          v-model="select"-->
<!--          v-b-tooltip.hover.ds1000.dh0-->
<!--      ></b-form-select>-->
<!--    </div>-->
    <div
        v-for="(shadow, index) in this.elemCurrentProp.boxShadow"
        :key="index"
    >
      <Shadow :shadowElem=shadow :index=index :new-shadow="false" @deleteShadow="deleteShadow"/>
    </div>

    <Shadow :shadowElem=newShadow :index=null :new-shadow="true" @addShadow="addShadow"/>

  </div>
</template>

<script>
import ShowProp from "@/helpers/constructor/sideBar/props/MethodShowProp";
import PropsHelper from "@/helpers/constructor/sideBar/props/PropsComputed";
import Shadow from "./boxShadow"
import HistoryHelper from '@/helpers/constructor/HistoryHelper'

export default {
  name: "BoxShadowWidget",
  props: [ 'elem', 'prop', 'tagData', 'indexElem', 'settingConstructor' ],
  components: { Shadow },
  data: function() {
    return {
      // select: '---',
      // optionsForSelect: [
      //   '---', 'none'
      // ],
      blockProps: 'css',
      newShadow: {
          shiftX: {
            val: 0,
            unit: 'px'
          },
          shiftY: {
            val: 0,
            unit: 'px'
          },
          color: '#000',
          inset: false,
          blur: {
            val: 0,
            unit: 'px'
          },
          stretching: {
            val: 0,
            unit: 'px'
          },
        }
    }
  },
  computed: {
    ...ShowProp,
    ...PropsHelper,

    // boxShadows: function() {
    //   if (this.elemCurrentProp.boxShadow === undefined) {
    //     return this.shadow
    //   }
    //   return this.elemCurrentProp.boxShadow
    // },

    //     {
    //   get: function() {
    //     if (this.elemCurrentProp.boxShadow !== undefined) {
    //       return this.shadow
    //     }
    //     return this.elemCurrentProp.boxShadow
    //   },
    //   set: function(val) {
    //     console.log(val)
    //   }
    // }
  },
  methods: {
    ...HistoryHelper,
    addShadow(shadowElem) {
      if (this.elemCurrentProp.boxShadow === undefined) {
        // this.$set(this.elemCurrentProp, 'boxShadow', [shadowElem])
        this.HistorySet(this.elemCurrentProp, 'boxShadow', [shadowElem])
      } else {
        this.HistoryPush(this.elemCurrentProp, 'boxShadow', shadowElem)
        // this.elemCurrentProp.boxShadow.push(shadowElem)
      }
    },
    deleteShadow(index) {
      if (this.elemCurrentProp.boxShadow.length === 1) {
        this.HistoryDelete(this.elemCurrentProp, 'boxShadow')
        // this.$delete(this.elemCurrentProp, 'boxShadow')
        return
      }
      this.HistoryDelete(this.elemCurrentProp.boxShadow, index)
      // this.$delete(this.elemCurrentProp.boxShadow, index)
    }
  }
}
</script>

<style scoped>

</style>
