<template>
  <div>
    <h4
        v-if="prop && prop.type === 'h4' && showProp"
        class="card-title"
    >
      <i
          v-if="$t('Constructor.PropsHeadings.' + blockProps + '.' + prop.text + '.tooltip')"
          v-b-tooltip
          :title="$t('Constructor.PropsHeadings.' + blockProps + '.' + prop.text + '.tooltip')"
          class="far fa-question-circle"></i>
      {{ $t('Constructor.PropsHeadings.' + blockProps + '.' + prop.text + '.label') }}
    </h4>

    <p
        v-else-if="prop.type === 'text'"
        class="card-text mb-3 mt-3"
    >
      {{prop.text}}
    </p>

    <InlineFormGroup
        v-else-if="prop.type === 'inline'"
        :prop="prop"
        :elem="elem"
        :indexElem="activeElemId"
        :tagData="tagData"
        :blockProps="blockProps"
        :settingConstructor="settingConstructor"
    />

    <!--------------------------------------------------------------widgets-------------------------------------------->
    <!----------------------------------------------------------------------------------PatternLocationWidgetAbsolute-->
    <PatternLocationElem
        v-else-if="prop.type === 'PatternLocationElem'"
        :tagData = tagData
        :indexElem = activeElemId
        :settingConstructor = settingConstructor
        :prop="prop"
        :elem="elem"
        :blockProps="blockProps"
        :nameProp="prop.name"
    />

    <!---------------------------------------------------------------------------------------------CssTransformWidget-->
    <CssTransformWidget
        v-else-if="prop.type === 'CssTransformWidget'"
        :tagData = tagData
        :indexElem = activeElemId
        :settingConstructor = settingConstructor
        :elem="elem"
        :prop="prop"
        :nameProp="prop.name"
    />

    <!-------------------------------------------------------------------------------------------WidgetBackgroundSize-->
    <WidgetBackgroundSize
        v-else-if="prop.type === 'widgetBackgroundSize'"
        :tagData = tagData
        :indexElem = activeElemId
        :settingConstructor = settingConstructor
        :elem="elem"
        :prop="prop"
        :nameProp="prop.name"
    />

    <!-----------------------------------------------------------------------------------------WidgetBackgroundRepeat-->
    <WidgetBackgroundRepeat
        v-else-if="prop.type === 'widgetBackgroundRepeat'"
        :tagData = tagData
        :indexElem = activeElemId
        :settingConstructor = settingConstructor
        :elem="elem"
        :prop="prop"
        :nameProp="prop.name"
    />

    <!-------------------------------------------------------------------------------------------------WidgetMaskSize-->
    <WidgetMaskSize
        v-else-if="prop.type === 'widgetMaskSize'"
        :tagData = tagData
        :indexElem = activeElemId
        :settingConstructor = settingConstructor
        :elem="elem"
        :prop="prop"
        :nameProp="prop.name"
    />

    <!-----------------------------------------------------------------------------------------------WidgetMaskRepeat-->
    <WidgetMaskRepeat
        v-else-if="prop.type === 'widgetMaskRepeat'"
        :tagData = tagData
        :indexElem = activeElemId
        :settingConstructor = settingConstructor
        :elem="elem"
        :prop="prop"
        :nameProp="prop.name"
    />


    <!------------------------------------------------------------------------------------------------BoxShadowWidget-->
    <BoxShadowWidget
        v-else-if="prop.type === 'BoxShadowWidget'"
        :tagData = tagData
        :indexElem = activeElemId
        :settingConstructor = settingConstructor
        :elem="elem"
        :prop="prop"
        :nameProp="prop.name"
    />

    <!-----------------------------------------------------------------------------------------------TextShadowWidget-->
    <TextShadowWidget
        v-else-if="prop.type === 'TextShadowWidget'"
        :tagData = tagData
        :indexElem = activeElemId
        :settingConstructor = settingConstructor
        :elem="elem"
        :prop="prop"
        :nameProp="prop.name"
    />

    <!--------------------------------------------------------------------------------------------TargetMainDivWidget-->
    <TargetMainDivWidget
        v-else-if="prop.type === 'TargetMainDivWidget'"
        :tagData = tagData
        :indexElem = activeElemId
        :settingConstructor = settingConstructor
        :elem="elem"
        :prop="prop"
        :nameProp="prop.name"
    />

    <!--------------------------------------------------------------------------------------------SelectOptionsWidget-->
    <SelectOptionsWidget
        v-else-if="prop.type === 'SelectOptionsWidget'"
        :tagData = tagData
        :indexElem = activeElemId
        :settingConstructor = settingConstructor
        :elem="elem"
        :prop="prop"
        :nameProp="prop.name"
    />

    <!-----------------------------------------------------------------------------------------------RadioGroupWidget-->
    <RadioGroupWidget
        v-else-if="prop.type === 'RadioGroupWidget'"
        :tagData = tagData
        :indexElem = activeElemId
        :settingConstructor = settingConstructor
        :elem="elem"
        :prop="prop"
        :nameProp="prop.name"
    />

    <!--------------------------------------------------------------------------------------------CheckboxGroupWidget-->
    <CheckboxGroupWidget
        v-else-if="prop.type === 'CheckboxGroupWidget'"
        :tagData = tagData
        :indexElem = activeElemId
        :settingConstructor = settingConstructor
        :elem="elem"
        :prop="prop"
        :nameProp="prop.name"
    />

    <!--------------------------------------------------------------------------------------------------ContentWidget-->
    <ContentWidget
        v-else-if="prop.type === 'ContentWidget'"
        :tagData = tagData
        :indexElem = activeElemId
        :settingConstructor = settingConstructor
        :elem="elem"
        :prop="prop"
        :nameProp="prop.name"
    />

    <!--------------------------------------------------------------------------------------------------SvgIconWidget-->
    <SvgIconWidget
        v-else-if="prop.type === 'SvgIconWidget'"
        :tagData = tagData
        :indexElem = activeElemId
        :settingConstructor = settingConstructor
        :elem="elem"
        :prop="prop"
        :nameProp="prop.name"
    />

    <!---------------------------------------------------------------------------------------------------LottieWidget-->
    <LottieWidget
        v-else-if="prop.type === 'LottieWidget'"
        :tagData = tagData
        :indexElem = activeElemId
        :settingConstructor = settingConstructor
        :elem="elem"
        :prop="prop"
        :nameProp="prop.name"
    />

    <!------------------------------------------------------------------------------------------------------RowWidget-->
    <RowWidget
        v-else-if="prop.type === 'RowWidget'"
        :tagData = tagData
        :indexElem = activeElemId
        :settingConstructor = settingConstructor
        :elem="elem"
        :prop="prop"
        :nameProp="prop.name"
    />

    <!--------------------------------------------------------------------------------------------DuplicatePropWidget-->
    <DuplicatePropWidget
        v-else-if="prop.type === 'DuplicatePropWidget'"
        :tagData = tagData
        :indexElem = activeElemId
        :settingConstructor = settingConstructor
        :elem="elem"
        :prop="prop"
        :BasePropElems="BasePropElems"
        :nameProp="prop.name"
    />

    <!------------------------------------------------------------------------------------------------FieldMaskWidget-->
    <FieldMaskWidget
        v-else-if="prop.type === 'FieldMaskWidget'"
        :tagData = tagData
        :indexElem = activeElemId
        :settingConstructor = settingConstructor
        :elem="elem"
        :prop="prop"
        :nameProp="prop.name"
    />

    <!---------------------------------------------------------------------------------------------BorderRadiusWidget-->
    <BorderRadiusWidget
        v-else-if="prop.type === 'BorderRadiusWidget'"
        :tagData = tagData
        :indexElem = activeElemId
        :settingConstructor = settingConstructor
        :elem="elem"
        :prop="prop"
        :nameProp="prop.name"
    />

    <!---------------------------------------------------------------------------------------------BorderRadiusWidget-->
    <BorderWidget
        v-else-if="prop.type === 'BorderWidget'"
        :tagData = tagData
        :indexElem = activeElemId
        :settingConstructor = settingConstructor
        :elem="elem"
        :prop="prop"
        :nameProp="prop.name"
    />


    <!----------------------------------------------------------------------------------------------------end widgets-->
    <FormGroup
        v-else
        :prop="prop"
        :elem="elem"
        :indexElem="activeElemId"
        :tagData="tagData"
        :blockProps="blockProps"
        :settingConstructor="settingConstructor"
        :nameProp="prop.name"
    />
  </div>
</template>

<script>
import FormGroup from './_form_group'
import InlineFormGroup from './_inline_form_group'
import CssTransformWidget from './widgets/CssTransformWidget'
import PatternLocationElem from './widgets/PatternLocationElem'
import WidgetBackgroundSize from "./widgets/WidgetBackgroundSize";
import WidgetMaskSize from "./widgets/WidgetMaskSize";
import BoxShadowWidget from "./widgets/BoxShadowWidget/BoxShadowWidget";
import TextShadowWidget from "./widgets/TextShadowWidget/TextShadowWidget";
import TargetMainDivWidget from "./widgets/TargetMainDivWidget";
import SelectOptionsWidget from "./widgets/SelectOptionsWidget/SelectOptionsWidget";
import RadioGroupWidget from "./widgets/RadioGroupWidget/RadioGroupWidget";
import CheckboxGroupWidget from "./widgets/CheckboxGroupWidget/CheckboxGroupWidget";
import ContentWidget from "./widgets/ContentWidget";
import SvgIconWidget from "./widgets/SvgIconWidget";
import LottieWidget from "./widgets/LottieWidget";
import RowWidget from "./widgets/RowWidget";
import DuplicatePropWidget from "./widgets/DuplicatePropWidget";
import FieldMaskWidget from "./widgets/FieldMaskWidget";
import WidgetBackgroundRepeat from "./widgets/WidgetBackgroundRepeat";
import WidgetMaskRepeat from "./widgets/WidgetMaskRepeat";
import ShowProp from "@/helpers/constructor/sideBar/props/MethodShowProp";
import BorderRadiusWidget from "./widgets/BorderRadiusWidget";
import BorderWidget from "./widgets/BorderWidget";



export default {
  name: "RenderProp",
  props: ['settingConstructor', 'tagData', 'blockProps', 'activeTab', 'activeElemId', 'elem', 'prop', 'BasePropElems'],
  components: {
    FormGroup,
    InlineFormGroup,
    CssTransformWidget,
    PatternLocationElem,
    WidgetBackgroundSize,
    WidgetMaskSize,
    BoxShadowWidget,
    TextShadowWidget,
    TargetMainDivWidget,
    SelectOptionsWidget,
    RadioGroupWidget,
    ContentWidget,
    SvgIconWidget,
    RowWidget,
    DuplicatePropWidget,
    FieldMaskWidget,
    CheckboxGroupWidget,
    WidgetBackgroundRepeat,
    WidgetMaskRepeat,
    LottieWidget,
    BorderRadiusWidget,
    BorderWidget
  },
  computed: {
    ...ShowProp
  }
}
</script>

<style scoped>

</style>