import api from "@/helpers/fakebackend/api";

export const constructorService = {
    previewTag,
    previewTagStop,
    checkReadyPrevTask
};

function previewTag(data, mode) {
    const request = {
        method: 'post',
        path: 'tags/constructor/preview-tag?mode=' + mode,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        properties: data
    }

    return (new api()).sendRequest(request).then(response => {
        return new Promise((resolve, reject) => {
            if (response.data.status === 'success') {
                resolve(response.data.data)
            }
            if (response.data.status === 'error') {
                reject(response.data.data)
            }
        });
    })
}

function previewTagStop(data) {
    const request = {
        method: 'get',
        path: 'tags/constructor/preview-tag-stop',
        params: data
    }

    return (new api()).sendRequest(request).then(response => {
        return new Promise((resolve, reject) => {
            if (response.data.status === 'success') {
                resolve(response.data.data)
            }
            if (response.data.status === 'error') {
                reject(response.data.data)
            }
        });
    })
}

function checkReadyPrevTask(task_id) {
    const request = {
        method: 'get',
        path: 'tags/constructor/check-ready-task',
        params: {
            task_id: task_id
        }
    }

    return (new api()).sendRequest(request).then(response => {
        return new Promise((resolve, reject) => {
            if (response.data.status === 'success') {
                resolve(response.data.data)
            }
            if (response.data.status === 'error') {
                reject(response.data.data)
            }
        });
    })
}
