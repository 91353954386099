<template>
  <div :class="prop.class !== undefined ? prop.class : false">
<!-----------------------------------------------------------------------------------------------------------textarea-->
    <b-form-textarea
        v-if="prop.type === 'textarea'"
        :placeholder="$t('General.Form.NoSet')"
        v-model="valProp"
        :name="blockProps + nameProp"
        style="width: 100%"
        v-b-tooltip.hover.ds1000.dh0.viewport.left
        :title="getTooltip()"
        class="propField mousetrap mb-1"
        :ref="'field' + nameProp"
        maxlength = "5000"
        size="sm"
    ></b-form-textarea>
<!-------------------------------------------------------------------------------------------------------------STRING-->
    <b-form-input
        v-if="prop.type === 'string'"
        :placeholder="$t('General.Form.NoSet')"
        type="text"
        v-model="valProp"
        :name="blockProps + nameProp"
        style="width: 100%"
        v-b-tooltip.hover.ds1000.dh0.viewport.left
        :title="getTooltip()"
        class="propField mousetrap"
        maxlength = "250"
        size="sm"
    ></b-form-input>

<!-------------------------------------------------------------------------------------------------------------NUMBER-->
    <b-input-group v-if="prop.type === 'number'" size="sm">
        <b-form-input
            :placeholder="prop.placeholder !== undefined ? prop.placeholder : $t('General.Form.NoSet')"
            v-model="valPropNumb"
            type="number"
            :name="blockProps + nameProp"
            :required="getRequired(prop)"
            class="col-auto propField mousetrap"
            v-b-tooltip.hover.ds1000.dh0.viewport.left
            :title="getTooltip()"
            maxlength = "11"
            :step = "prop.step === undefined ? 0.01 : prop.step"
        >
        </b-form-input>

      <multiselect
          v-if="prop.type === 'number' && prop.unit"
          track-by="code"
          label="name"
          v-model = "valPropNumbUnit"
          :options="prop.unit"
          class="mousetrap multiselect-sm form-control"
          :show-labels="false"
          :searchable="false"
          :deactivated = "(prop.unit.length === 1)"
          v-b-tooltip.hover.ds1000.dh0.viewport.left
          :title="getTooltip('Unit')"
      >
      </multiselect>

<!--        <b-form-select-->
<!--            v-if="prop.type === 'number' && prop.unit"-->
<!--            class="col-6"-->
<!--            :options="prop.unit"-->
<!--            v-model="valPropNumbUnit"-->
<!--            :disabled="(prop.unit.length === 1)"-->
<!--            v-b-tooltip.hover.ds1000.dh0.viewport.left-->
<!--            :title="getTooltip('Unit')"-->
<!--        ></b-form-select>-->
    </b-input-group>

<!-------------------------------------------------------------------------------------------------------------RANGE-->
    <b-input-group v-if="prop.type === 'range'" size="sm">
      <b-form-input
          :placeholder="prop.placeholder !== undefined ? prop.placeholder : $t('General.Form.NoSet')"
          v-model="valPropNumb"
          type="text"
          :name="blockProps + nameProp"
          :required="getRequired(prop)"
          class="col-3 propField mousetrap"
          v-b-tooltip.hover.ds1000.dh0.viewport.left
          :title="getTooltip()"
          maxlength = "11"
          size="sm"
      >
      </b-form-input>
      <b-form-input
          v-model = "valPropNumb"
          :name = "nameProp"
          class = "col-9 propField mousetrap"
          type = "range"
          :min = "prop.min"
          :max = "prop.max"
          :step = "prop.step"
          v-b-tooltip.hover.ds1000.dh0.viewport.left
          :title = "getTooltip()"
          size="sm"
      ></b-form-input>
    </b-input-group>
<!-------------------------------------------------------------------------------------------------------------SELECT-->

<!--    v-b-tooltip.viewport.hover.ds1000.dh0.html.left="getTooltip()"-->
      <multiselect
          v-if="prop.type === 'select' "
          :placeholder="$t('General.Form.NoSet')"
          :id="'propElemId' + nameProp"
          v-model="selectProp"
          track-by="code"
          label="name"
          :options="localizedOptions(prop.options)"
          :name="blockProps + nameProp"
          class="mousetrap multiselect-sm form-control"
          :show-labels="false"
          :searchable="false"
      >
        <template
            slot="singleLabel"
            slot-scope="props"
        >
          <span :style="props.option.style" v-html="props.option.name"></span>
        </template>

        <template
            slot="option"
            slot-scope="props"
        >
          <span :style="props.option.style" v-html="props.option.name"></span>
          <div v-if="props.option.desc">
            {{ props.option.desc }}
          </div>
        </template>
      </multiselect>

<!--    <div v-if="prop.type == 'select' ">-->
<!--      select {{prop.name}}-->
<!--    </div>-->
<!--------------------------------------------------------------------------------------------------------------COLOR-->

    <ColorWidget v-if="prop.type === 'color'" v-model="valProp" />


    <!-----------------------------------------------------------------------------------------------------radioGroup-->
    <div  v-if="prop.type === 'radioGroup'">
      <b-form-radio-group
          v-model="valProp"
          :options="prop.options"
          :name="blockProps + nameProp"
          buttons
          size="sm"
          button-variant="outline-secondary-2"
          value-field="code"
          class="bg-white zi-1"
      ></b-form-radio-group>

      <b-button
          v-if="prop.reset && valProp"
          @click.stop="resetProp"
          variant="danger"
          class="propResetButton"
          size="sm"
          v-b-tooltip.hover.ds500.dh0.viewport.left="$t('General.Base.Reset')"
      >
        <i class="mdi mdi-close-thick"></i>
      </b-button>
    </div>

    <!-------------------------------------------------------------------------------------------------------checkbox-->
    <b-form-checkbox
        v-if="prop.type === 'checkbox'"
        v-model="valProp"
        :name="blockProps + nameProp"
        class="mt-2 mousetrap"
        v-b-tooltip.hover.ds1000.dh0.viewport.left
        :title="getTooltip()"
        size="sm"
    ></b-form-checkbox>

    <!------------------------------------------------------------------------------------SelectoNumberField--->
    <SelectoNumberField
      v-if="prop.type === 'selectoNumber'"
      :options="localizedOptions(prop.options)"
      v-model="valProp"
      :units="prop.unit"
    />

    <!----------------------------------------------------------------------------------------------------------IMAGE-->
    <ImageField
        v-if="prop.type === 'image'"
        v-model="valProp"
        :mobileData = elemTextDefaultProp
    />

    <b-badge
        v-if="prop.type !== 'color' && prop.type !== 'image' && elemTextDefaultProp"
        variant="info"
        class="stuckBadge"
        v-b-tooltip.hover.ds1000.dh0.viewport.left
        :title = "$t('Constructor.General.BadgeAboutValueInOverScreen')"
    >{{elemTextDefaultProp}}</b-badge>

    <b-badge
        v-if="prop.type === 'color' && elemTextDefaultProp"
        variant="info"
        class="stuckBadge"
        v-b-tooltip.ds1000.dh0.viewport.left
        :title="elemTextDefaultProp"
        :style="{ backgroundColor: elemTextDefaultProp}"
    >{{elemTextDefaultProp}}</b-badge>

  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import TagDataEditService from '@/helpers/constructor/TagDataEditService'
import PropsHelper from "@/helpers/constructor/sideBar/props/PropsComputed";
import ImageField from "./widgets/ImageField";
import lodash from "lodash"
import HistoryHelper from '@/helpers/constructor/HistoryHelper'
import TagDataGetElemsHelper from "@/helpers/constructor/TagDataGetElemsHelper";
import SelectoNumberField from "./widgets/SelectoNumberField";
import ColorWidget from "./widgets/ColorWidget";
import MethodsForChangePropsElem from "@/helpers/constructor/MethodsForChangePropsElem";

export default {
  name: "propElems",
  data() {
    return {
      unit: null
    }
  },
  props: ['nameProp', 'prop', 'elem', 'tagData', 'indexElem', 'blockProps', 'settingConstructor'],
  components: { Multiselect, ImageField, SelectoNumberField, ColorWidget },
  computed: {
    ...PropsHelper,
    valPropNumbUnit: {
      get: function() {
        return this.prepareDataForMultiselect(this.getValPropNumbUnit())
      },
      set: function(value) {
        this.setValPropNumbUnit(value.code)
      }
    },
    valPropNumb: {
      get: function() {
        return this.getValPropNumb()
      },
      set: function(value) {
        this.setValPropNumb(value)
      }
    },
    valProp: {
      get: function() {
        return this.getValProp()
      },
      set: function(value) {
        this.setValProp(value)
      }
    },
    selectProp: {
      get: function() {
        return this.getSelectProp();
      },
      set: function(value) {
        this.setSelectProp(value)
      }
    },
    // numberProp: {
    //   get: function() {
    //     // elem[this.blockProps][nameProp]
    //   },
    //   set: function() {
    //
    //   }
    // }
  },
  methods: {
    ...TagDataEditService,
    ...HistoryHelper,
    ...TagDataGetElemsHelper,
    ...MethodsForChangePropsElem,
    getRequired: (prop) => {
      if (typeof(prop.required)==='boolean') {
        return prop.required
      }

      // elemCurrentProp
      //@TODO: обработатть обьект
      return false
    },
    getTooltip(add = '') {
      return this.$t('Constructor.Props.' + this.blockProps + '.' + this.prop.name + '.tooltip' + add) ?
          this.$t('Constructor.Props.' + this.blockProps + '.' + this.prop.name + '.tooltip' + add) : null
    },
    localizedOptions(options) {
      options = options.map((elem) => {
        let elem2 = lodash.clone(elem)
        elem2.name = this.$t('Constructor.Props.' + this.blockProps + '.' + this.prop.name + '.options.' + elem2.name)
        return elem2
      });
      return options
    },
    resetProp() {
      let actions = [{obj: this.elemCurrentProp, nameProp: this.nameProp, val: null, type: 'delete'}]
      this.HistorySetPackage(actions)
    }
    // saveChange(value) {
    //   let obj = {
    //     // entity: 'htmlElements',
    //     obj: this.elemCurrentProp,
    //     // tagData: this.tagData,
    //     propName: this.nameProp,
    //     value: value,
    //     // path: [ this.indexElem, this.blockProps, this.nameProp, ...addPath ]
    //   }
    //
    //   // let q = this.tagData.htmlElements
    //   // for (let i in obj.path) {
    //   //   q = q[obj.path[i]]
    //   //   console.log(q)
    //   // }
    //   // console.log(q, obj)
    //   // this.$store.dispatch('builder/constructorHistory/set', obj)
    //
    // }
  }
}
</script>
<style>
  .propField:invalid {
    box-shadow: 0 0 4px 2px #ff0000;
  }
  .zi-1 {
    z-index: 1;
  }
  .propResetButton {
    position: absolute;
    top: 3px;
    left: -2px;
    z-index: 0;
    font-size: 11px !important;
    padding: 2px 1px !important;
    line-height: 12px !important;
    opacity: 0.8;
  }
  .propResetButton:hover {
    left: -13px;
    opacity: 1;
  }
</style>
