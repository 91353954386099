<template>

      <b-tabs content-class="text-muted pt-3" >

        <b-tab class="border-0">
          <template v-slot:title>
            <span class="d-inline-block d-sm-none">
              <i class="fas fa-home"></i>
            </span>
            <span class="d-none d-sm-inline-block ">{{$t('Constructor.ElemsList.Tabs.Elems')}}</span>
          </template>

          <div
            v-for="(elements, nameElem) in elems"
            :key="nameElem"
          >
<!--            <h4 v-if="elements.h_big">{{$t('Constructor.ElemsList.TabsElems.' + elements.h_big)}}</h4>-->
            <h5 class="font-size-12 mb-0 mt-2" v-if="elements.h_small">{{$t('Constructor.ElemsList.TabsElems.' + elements.h_small)}}</h5>
            <div class="row m-0" v-if="elements.nameElemsPack">
              <div
                  v-for="(elements, nameElem) in elemPublic[elements.nameElemsPack]"
                  :key="nameElem"
                  class="p-1 newElemBlock"
              >

                <div class="p-0 blocksElemsPatterElem">
                  <div
                      v-for="(element, index) in elements"
                      :key="index"
                  >
                    <ElemContainer
                        :settingConstructor="settingConstructor"
                        :tagData="tagData"
                        :elem_id="index"
                        :elemSingle="element"
                        @onDragStart="onDragStart"
                        @onDragEnd="onDragEnd"
                    />
                    <Elem
                        :tree="{}"
                        :settingConstructor="settingConstructor"
                        :elemSingle="element"
                        :tagData="tagData"
                        :index="index"
                        :draggable_ico="false"
                        @onDragStart="onDragStart"
                        @onDragEnd="onDragEnd"
                    />
                  </div>
                </div>
                <p class="card-title text-center font-size-10 m-0">{{$t("Constructor.Elems." + nameElem + ".Name")}}</p>
              </div>
            </div>
          </div>



<!--          <h5>{{$t('Constructor.ElemsList.TabsElems.Layout')}}</h5>-->
<!--          <div class="row">-->
<!--            <div-->
<!--                v-for="(elements, nameElem) in elemPublic.layout"-->
<!--                :key="nameElem"-->
<!--                class="col-4 p-2 newElemBlock"-->
<!--            >-->
<!--              <p class="card-title text-center mb-0 font-size-11">{{$t("Constructor.Elems." + nameElem + ".Name")}}</p>-->
<!--              <div class="p-0 blocksElemsPatterElem">-->
<!--                <div-->
<!--                    v-for="(element, index) in elements"-->
<!--                    :key="index"-->
<!--                >-->
<!--                  <ElemContainer-->
<!--                      :settingConstructor="settingConstructor"-->
<!--                      :tagData="tagData"-->
<!--                      :elem_id="index"-->
<!--                      :elemSingle="element"-->
<!--                      @onDragStart="onDragStart"-->
<!--                      @onDragEnd="onDragEnd"-->
<!--                  />-->
<!--                  <Elem-->
<!--                      :tree="{}"-->
<!--                      :settingConstructor="settingConstructor"-->
<!--                      :elemSingle="element"-->
<!--                      :tagData="tagData"-->
<!--                      :index="index"-->
<!--                      :draggable_ico="false"-->
<!--                      @onDragStart="onDragStart"-->
<!--                      @onDragEnd="onDragEnd"-->
<!--                  />-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--          <h5 class="mt-4">{{$t('Constructor.ElemsList.TabsElems.Elems')}}</h5>-->
<!--          <div class="row">-->
<!--            <div-->
<!--                v-for="(elements, nameElem) in elemPublic.elems"-->
<!--                :key="nameElem"-->
<!--                class="col-4 p-2 newElemBlock"-->
<!--            >-->
<!--              <p class="card-title text-center mb-0 font-size-11">{{$t("Constructor.Elems." + nameElem + ".Name")}}</p>-->

<!--              <div class="p-0 blocksElemsPatterElem">-->
<!--                <div-->
<!--                    v-for="(element, index) in elements"-->
<!--                    :key="index"-->
<!--                >-->
<!--                  <ElemContainer-->
<!--                      :settingConstructor="settingConstructor"-->
<!--                      :tagData="tagData"-->
<!--                      :elem_id="index"-->
<!--                      :elemSingle="element"-->
<!--                      @onDragStart="onDragStart"-->
<!--                      @onDragEnd="onDragEnd"-->
<!--                  />-->
<!--                  <Elem-->
<!--                      :tree="{}"-->
<!--                      :settingConstructor="settingConstructor"-->
<!--                      :elemSingle="element"-->
<!--                      :tagData="tagData"-->
<!--                      :index="index"-->
<!--                      :draggable_ico="false"-->
<!--                      @onDragStart="onDragStart"-->
<!--                      @onDragEnd="onDragEnd"-->
<!--                  />-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--          <h5 class="mt-4">{{$t('Constructor.ElemsList.TabsElems.Form')}}</h5>-->
<!--          <div class="row">-->
<!--            <div-->
<!--                v-for="(elements, nameElem) in elemPublic.form"-->
<!--                :key="nameElem"-->
<!--                class="col-auto p-2 newElemBlock"-->
<!--            >-->
<!--              <p class="card-title text-center mb-0 font-size-11">{{nameElem}}</p>-->
<!--              <div class="p-0 blocksElemsPatterElem" style="position:relative">-->
<!--                <div-->
<!--                    v-for="(element, index) in elements"-->
<!--                    :key="index"-->
<!--                >-->
<!--                  <ElemContainer-->
<!--                      :settingConstructor="settingConstructor"-->
<!--                      :tagData="tagData"-->
<!--                      :elem_id="index"-->
<!--                      :elemSingle="element"-->
<!--                      @onDragStart="onDragStart"-->
<!--                      @onDragEnd="onDragEnd"-->
<!--                  />-->
<!--                  <Elem-->
<!--                      :tree="element.elemChild !== undefined ? element.elemChild : {}"-->
<!--                      :settingConstructor="settingConstructor"-->
<!--                      :elemSingle="element"-->
<!--                      :tagData="tagData"-->
<!--                      :index="index"-->
<!--                      :draggable_ico="false"-->
<!--                      @onDragStart="onDragStart"-->
<!--                      @onDragEnd="onDragEnd"-->
<!--                  />-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--          <h5 class="mt-4">{{$t('Constructor.ElemsList.TabsElems.Geometry')}}</h5>-->
<!--          <div class="row">-->
<!--            <div-->
<!--                v-for="(elements, nameElem) in elemPublic.geometry"-->
<!--                :key="nameElem"-->
<!--                class="col-4 p-2 newElemBlock"-->
<!--            >-->
<!--              <div class="p-0 blocksElemsPatterElem">-->
<!--                <div-->
<!--                    v-for="(element, index) in elements"-->
<!--                    :key="index"-->
<!--                >-->
<!--                  <ElemContainer-->
<!--                      :settingConstructor="settingConstructor"-->
<!--                      :tagData="tagData"-->
<!--                      :elem_id="index"-->
<!--                      :elemSingle="element"-->
<!--                      @onDragStart="onDragStart"-->
<!--                      @onDragEnd="onDragEnd"-->
<!--                  />-->
<!--                  <Elem-->
<!--                      :tree="{}"-->
<!--                      :settingConstructor="settingConstructor"-->
<!--                      :elemSingle="element"-->
<!--                      :tagData="tagData"-->
<!--                      :index="index"-->
<!--                      :draggable_ico="false"-->
<!--                      @onDragStart="onDragStart"-->
<!--                      @onDragEnd="onDragEnd"-->
<!--                  />-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

        </b-tab>

<!--        <b-tab class="border-0">-->
<!--          <template v-slot:title>-->
<!--            <span class="d-inline-block d-sm-none">-->
<!--              <i class="fas fa-home"></i>-->
<!--            </span>-->
<!--            <span class="d-none d-sm-inline-block">Геометрия</span>-->
<!--          </template>-->
<!--          <div class="row">-->
<!--            <div-->
<!--                v-for="(elements, nameElem) in elemPublic.geometry"-->
<!--                :key="nameElem"-->
<!--                class="col-4 p-2 newElemBlock"-->
<!--            >-->
<!--&lt;!&ndash;              <p class="card-title text-center mb-3">{{nameElem}}</p>&ndash;&gt;-->
<!--              <div class="p-0 blocksElemsPatterElem">-->
<!--                <div-->
<!--                    v-for="(element, index) in elements"-->
<!--                    :key="index"-->
<!--                >-->
<!--                  <ElemContainer-->
<!--                      :settingConstructor="settingConstructor"-->
<!--                      :tagData="tagData"-->
<!--                      :elem_id="index"-->
<!--                      :elemSingle="element"-->
<!--                      @onDragStart="onDragStart"-->
<!--                      @onDragEnd="onDragEnd"-->
<!--                  />-->
<!--                  <Elem-->
<!--                      :tree="{}"-->
<!--                      :settingConstructor="settingConstructor"-->
<!--                      :elemSingle="element"-->
<!--                      :tagData="tagData"-->
<!--                      :index="index"-->
<!--                      :draggable_ico="false"-->
<!--                      @onDragStart="onDragStart"-->
<!--                      @onDragEnd="onDragEnd"-->
<!--                  />-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </b-tab>-->

<!--        <b-tab class="border-0">-->
<!--          <template v-slot:title>-->
<!--            <span class="d-inline-block d-sm-none">-->
<!--              <i class="fas fa-home"></i>-->
<!--            </span>-->
<!--            <span class="d-none d-sm-inline-block">Form</span>-->
<!--          </template>-->
<!--          <div class="row">-->
<!--            <div-->
<!--                v-for="(elements, nameElem) in elemPublic.form"-->
<!--                :key="nameElem"-->
<!--                class="col-auto p-2 newElemBlock"-->
<!--            >-->
<!--              <p class="card-title text-center mb-0 font-size-11">{{nameElem}}</p>-->
<!--              <div class="p-0 blocksElemsPatterElem" style="position:relative">-->
<!--                <div-->
<!--                    v-for="(element, index) in elements"-->
<!--                    :key="index"-->
<!--                >-->
<!--                  <ElemContainer-->
<!--                      :settingConstructor="settingConstructor"-->
<!--                      :tagData="tagData"-->
<!--                      :elem_id="index"-->
<!--                      :elemSingle="element"-->
<!--                      @onDragStart="onDragStart"-->
<!--                      @onDragEnd="onDragEnd"-->
<!--                  />-->
<!--                  <Elem-->
<!--                      :tree="element.elemChild !== undefined ? element.elemChild : {}"-->
<!--                      :settingConstructor="settingConstructor"-->
<!--                      :elemSingle="element"-->
<!--                      :tagData="tagData"-->
<!--                      :index="index"-->
<!--                      :draggable_ico="false"-->
<!--                      @onDragStart="onDragStart"-->
<!--                      @onDragEnd="onDragEnd"-->
<!--                  />-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </b-tab>-->

<!--        <b-tab class="border-0">-->
<!--          <template v-slot:title>-->
<!--            <span class="d-inline-block d-sm-none">-->
<!--              <i class="fas fa-home"></i>-->
<!--            </span>-->
<!--            <span class="d-none d-sm-inline-block">Виджеты</span>-->
<!--          </template>-->
<!--          <div class="row">-->
<!--            <div-->
<!--                v-for="(elements, nameElem) in elemPublic.widget"-->
<!--                :key="nameElem"-->
<!--                class="col-4 p-2 newElemBlock"-->

<!--            >-->
<!--              <p class="card-title text-center mb-3">{{nameElem}}</p>-->
<!--              <div class="p-0" style="position:relative">-->
<!--                <div-->
<!--                    v-for="(element, index) in elements"-->
<!--                    :key="index"-->
<!--                >-->
<!--                  <ElemContainer-->
<!--                      :settingConstructor="settingConstructor"-->
<!--                      :tagData="tagData"-->
<!--                      :elem_id="index"-->
<!--                      :elemSingle="element"-->
<!--                      @onDragStart="onDragStart"-->
<!--                      @onDragEnd="onDragEnd"-->
<!--                  />-->
<!--                  <Elem-->
<!--                      :tree="element.elemChild !== undefined ? element.elemChild : {}"-->
<!--                      :settingConstructor="settingConstructor"-->
<!--                      :elemSingle="element"-->
<!--                      :tagData="tagData"-->
<!--                      :index="index"-->
<!--                      :draggable_ico="false"-->
<!--                      @onDragStart="onDragStart"-->
<!--                      @onDragEnd="onDragEnd"-->
<!--                  />-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </b-tab>-->


      </b-tabs>

</template>

<script>
// import Elem from "../_constructorElem"
import { PatternElems, ChaptersElems } from '@/helpers/constructor/patterns/PatternElems'
import TagDataEditService from '@/helpers/constructor/TagDataEditService'
import deepmerge from 'deepmerge' //@TODO: Заменить на lodash
import ElemStyleHelper from '@/helpers/constructor/ElemStyleHelper'
import Elem from '../constructorArea/elements/elem'
import ElemContainer from "../constructorArea/_elemContainer"
// import lodash from 'lodash'

export default {
  data: function () {
    return {
      elemStyleHelper: null,
      elemCount: 0,
      elemPublic: {
        elems: {},
        layout: {},
        geometry: {},
        form: {},
        widget: {},
        form_input: {},
        over_inputs_form: {},
        buttons: {}
      },
      elemTemplates: {},
      elems: [
         {
           h_big: 'Layout',
           h_small: null,
           nameElemsPack: 'layout'
        },
        {
          h_big: 'Elems',
          h_small: null,
          nameElemsPack: 'elems'
        },
        {
          h_big: 'Form',
          h_small: null,
          nameElemsPack: null
        },
        {
          h_big: null,
          h_small: "InputsForm",
          nameElemsPack: 'form_input'
        },
        {
          h_big: null,
          h_small: "OverInputsForm",
          nameElemsPack: 'over_inputs_form'
        },
        {
          h_big: null,
          h_small: "Buttons",
          nameElemsPack: 'buttons'
        },
        {
          h_big: 'Geometry',
          h_small: null,
          nameElemsPack: 'geometry'
        }
      ],
    }
  },
  name: "sideBarElements",
  props:['tagData', 'settingConstructor'],
  components: {
    Elem, ElemContainer //, Layers
  },
  methods: {
    ...TagDataEditService,
    onDragStart(event, elemObj) {
      this.copyElem(elemObj.name)
    },
    onDragEnd(event, elemObj, index, backlightBlock) {
      let shiftX = event.clientX - event.target.getBoundingClientRect().left;
      let shiftY = event.clientY - event.target.getBoundingClientRect().top;
      this.deleteElem(index, elemObj.name)

      if (backlightBlock !== null) {
        let elemParent = document.getElementById('elemContTrue_' + backlightBlock).getBoundingClientRect();
        let x = Math.ceil(event.clientX - elemParent.x - shiftX - 1)
        let y = Math.ceil(event.clientY - elemParent.y - shiftY - 1)
        this.createElem(elemObj.name,x,y, backlightBlock)
      }
    },
    preparationElemToSideBar(elem) {
      elem.resizable = false
      elem.active = true
      elem.css.position = 'relative'
      return elem
    },
    copyElem(elemName) {
      let elem = deepmerge({}, PatternElems[elemName])
      // elem = this.getPatternElem(elem, elemName)
      elem = this.elemStyleHelper.getStyleElem(elem)
      elem = this.preparationElemToSideBar(elem)

      // if (elem.tag === null) {
        elem.tag = 'div'
      // }

      for (let type in this.elemPublic) {
        if (ChaptersElems[type].includes(elemName)) {
          // elem.id = 'n' + this.elemCount
          this.$set(this.elemPublic[type][elemName], 'n' + this.elemCount, elem )
          break
        }
      }

      // this.$set(this.elemPublic[tag], 'n' + this.elemCount, data )
      this.elemCount++
    },
    deleteElem(index, elemName) {
      for (let type in this.elemPublic) {
        if (ChaptersElems[type].includes(elemName)) {
          this.$delete(this.elemPublic[type][elemName], index)
          break
        }
      }

      let elemContTrue = document.getElementById('elemContTrue_' + index)
      elemContTrue.remove()
      let elemContainer = document.getElementById('workspaceParent').querySelector('.elemContainer')
      elemContainer.remove()
      // this.$delete(this.elemPublic[tag], index)
    },
    createElem(tag,x,y,backlightBlock) {
      let pos = {}
      if (PatternElems[tag].type === undefined) {
        pos.type = pos.name
      }

      if (PatternElems[tag].css.position === 'absolute') {
        pos.css = {
            top: {val: y + 4, unit: 'px'},
            left: {val: x + 4, unit: 'px'}
          }
      }

      let elem = deepmerge(PatternElems[tag], pos)

      // elem = this.getPatternElem(elem, tag)
      elem = this.elemStyleHelper.getStyleElem(elem)
      this.TDEScreateElem(elem, backlightBlock, null)
    },
  },
  mounted() {
    this.elemStyleHelper = new ElemStyleHelper()
    // this.elemTemplates = PatternElems
    for (let type in this.elemPublic) {
      ChaptersElems[type].forEach(elemName => {
        this.$set(this.elemPublic[type], elemName, {})
        this.copyElem(elemName)
      })
    }
  }
}
</script>
<style>
  .blocksElemsPatterElem {
    position: relative;
    height: 70px;
    width: 70px;
    margin: auto;
  }
</style>