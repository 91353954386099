export default {
    // ValPropNumb
    /**
     * For numbers
     * @returns {*|string}
     */
    getValPropNumb: function (nameProp = null, prop = null) {
        if (!nameProp) {
            nameProp = this.nameProp
        }
        if (!prop) {
            prop = this.prop
        }
        if (this.elemCurrentProp[nameProp] === undefined || this.elemCurrentProp[nameProp] === null) {
            if (prop.default !== undefined && typeof prop.default === 'number') {
                return prop.default.val
            }
            return ''
        }
        return this.elemCurrentProp[nameProp].val
    },
    setValPropNumb: function (value, nameProp = null, prop = null) {
        if (!nameProp) {
            nameProp = this.nameProp
        }
        if (!prop) {
            prop = this.prop
        }

        value = parseFloat(value)

        if (prop.callback) {
            prop.callback(this, value)
        }
        if (isNaN(value)) {
            if (prop.default !== undefined && this.settingConstructor.desktopMode === 'default') {
                this.HistorySet(this.elemCurrentProp, nameProp, prop.default)
                return
            }
            // this.$delete(this.elemCurrentProp, this.nameProp)
            this.HistoryDelete(this.elemCurrentProp, nameProp)
            return
        }

        this.HistorySet(this.elemCurrentProp, nameProp, {val: value, unit: this.getValPropNumbUnit(nameProp, prop)})
        // вроде можно просто обьект записывать целиком
        // if (
        //     this.elemCurrentProp[nameProp] === undefined ||
        //     this.elemCurrentProp[nameProp] === null ||
        //     typeof this.elemCurrentProp[nameProp] !== 'object'
        // ) {
        //     this.HistorySet(this.elemCurrentProp, nameProp, {val: value, unit: this.getValPropNumbUnit(nameProp, prop)})
        // } else {
        //     this.HistorySet(this.elemCurrentProp[nameProp], 'val', value)
        // }
    },

    // ValPropNumbUnit
    /**
     * For number unit
     * @returns {*|null}
     */
    getValPropNumbUnit: function (nameProp = null, prop = null) {
        if (!nameProp) {
            nameProp = this.nameProp
        }
        if (!prop) {
            prop = this.prop
        }

        if (this.elemCurrentProp[nameProp] !== undefined && this.elemCurrentProp[nameProp] !== null
            && this.elemCurrentProp[nameProp].unit !== undefined
        ) {
            return this.elemCurrentProp[nameProp].unit
        }
        // if (this.unit) {
        //     return this.unit
        // }
        if (prop.unit === null) {
            return null
        }
        return prop.unit[0].code
    },
    setValPropNumbUnit: function (value, nameProp = null) {
        if (!nameProp) {
            nameProp = this.nameProp
        }
        // if (!prop) {
        //     prop = this.prop
        // }

        // this.unit = value
        if (this.elemCurrentProp[nameProp] !== undefined && typeof this.elemCurrentProp[nameProp] === 'object' &&
            (
                this.elemCurrentProp[nameProp].unit === undefined ||
                this.elemCurrentProp[nameProp].unit !== value
            )
        ) {
            this.HistorySet(this.elemCurrentProp[nameProp], 'unit', value)
            // this.$set(this.elemCurrentProp[this.nameProp], 'unit', value)
        }
    },
    // ValProp
    /**
     * For string, color, radiobutton, checkbox, SelectoNumberField, image
     * @returns {*|null}
     */
    getValProp: function (nameProp = null) {
        if (!nameProp) {
            nameProp = this.nameProp
        }
        if (this.elemCurrentProp[nameProp] === undefined) {
            return null
        }
        return this.elemCurrentProp[nameProp]
    },
    setValProp: function (value, nameProp = null, prop = null) {
        if (!nameProp) {
            nameProp = this.nameProp
        }
        if (!prop) {
            prop = this.prop
        }

        let actions = []

        if (value === null || value === '') {

            if (this.settingConstructor.desktopMode !== 'default') {
                this.HistoryDelete(this.elemCurrentProp, nameProp)
                return
            }

            if (prop.default) {
                value = prop.default
                actions.push({obj: this.elemCurrentProp, nameProp: nameProp, val: value, type: 'set'})
            } else {
                actions.push({obj: this.elemCurrentProp, nameProp: nameProp, val: null, type: 'delete'})
            }
        } else {
            actions.push({obj: this.elemCurrentProp, nameProp: nameProp, val: value, type: 'set'})
        }

        // if (value) {
        //
        // }

        if (prop.callback) {
            prop.callback(this, value, actions)
        }

        this.HistorySetPackage(actions)
    },

    // SelectProp
    /**
     * For select
     */
    getSelectProp: function (nameProp = null, prop = null) {
        if (!nameProp) {
            nameProp = this.nameProp
        }
        if (!prop) {
            prop = this.prop
        }

        if (this.elemCurrentProp[nameProp] === undefined && prop.default === undefined) {
            return false
        }
        let obj = {}
        if (this.elemCurrentProp[nameProp] === undefined && prop.default !== undefined) {
            let name = this.localizedOptions([{name: prop.default}])
            obj = { code: name[0].name, name: name[0].name }
        } else {
            let name = this.localizedOptions([{name: this.elemCurrentProp[nameProp]}])
            obj = { name: name[0].name, code: name[0].name }
        }

        prop.options.forEach(elem => {
            if (elem.code === obj.code) {
                obj.name = elem.name
                return false
            }
        })

        return obj
    },
    setSelectProp: function (value, nameProp = null, prop = null) {
        if (!nameProp) {
            nameProp = this.nameProp
        }
        if (!prop) {
            prop = this.prop
        }

        if (prop.callback) {
            prop.callback(this, value)
        }
        if (value === null || value.code === null) {
            // this.$delete(this.elemCurrentProp, this.nameProp)
            this.HistoryDelete(this.elemCurrentProp, nameProp)
            return
        }
        this.HistorySet(this.elemCurrentProp, nameProp, value.code)
        // this.$set(this.elemCurrentProp, this.nameProp, value.code)
        return {
            code: value.code,
            name: value.name,
            style: value.style
        }
    },
    prepareDataForMultiselect
}
export function prepareDataForMultiselect (unit) {
    return { code: unit, name: unit }
}
