<template>
  <b-sidebar
      id="sidebar-constructor"
      title=""
      :width="settingConstructor.sideBar.withSideBar"
      right
      bg-variant="white"
      visible
      no-close-on-backdrop
      no-close-on-esc
      no-header
      no-header-close
      body-class="p-1 pl-2 overflow-auto"

  >
<!--    sidebar-class="overflow-auto"-->
    <b-collapse v-model="sideBar.elem" id="constructorSideBarElements">
      <sideBarElements :tagData="tagData" :settingConstructor="settingConstructor" />
    </b-collapse>
    <b-collapse v-model="sideBar.prop"  id="constructorSideBarProps">
      <sideBarElem v-if="sideBar.prop" :tagData="tagData" :settingConstructor="settingConstructor" :key="settingConstructor.drags.activeElem" />
    </b-collapse>

<!--    <sideBarElemsLayers :tagData="tagData" :settingConstructor="settingConstructor" />-->
  </b-sidebar>
</template>

<script>
import sideBarElements from "./_sideBarElements"
import sideBarElem from "./sideBarProprs/_sideBarElem"
// import sideBarElemsLayers from "./_sideBarElemsLayers"

export default {
  name: "sideBar",
  props:['tagData', 'sideBar', 'settingConstructor'],
  mounted() {

  },
  components: {
    sideBarElements, sideBarElem //, sideBarElemsLayers
  }
}
</script>

<style scoped>

</style>
