<script>
export default {
  name: "selectCustom",
  functional: true,
  render: function (createElement, context) {
    context.data.elem.main.selectOptions.forEach(elem => {
      context.children.push(
          createElement(
              'option',
              {
                attrs: {
                  value: elem.val,
                  selected: elem.selected === '1' ? true : false
                }
              }, elem.text
          )
      )
    })
    return createElement('select', context.data, context.children)
  }
}
</script>
