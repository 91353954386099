<template>
    <div class="bg-white">

        <b-row align-h="between" class="m-0 p-0 " >
          <b-col cols="auto" class="pl-0">
            <b-button-toolbar key-nav size="sm" class="d-block my-auto">

              <b-button variant="dark" size="sm" class="mr-1 topBarButton" @click="closeTagConfirm()">
                <i class="mdi mdi-window-close align-middle mr-2"></i>
                {{$t('General.Base.Close')}}
              </b-button>
              <b-button variant="dark" size="sm" class="mr-1 topBarButton" @click="saveTag()">
                <i v-if="!this.$store.getters['lego/saveState/getStatusSavingServer']" class="far fa-save align-middle mr-2"></i>
                <b-spinner v-if="this.$store.getters['lego/saveState/getStatusSavingServer']"
                    small class="align-middle mr-1" variant="light" role="status"></b-spinner>
                {{$t('General.Base.Save')}}
              </b-button>
            </b-button-toolbar>
          </b-col>


          <b-col cols="auto" class="pr-0 d-flex">
            <ControlBar :settingConstructor="settingConstructor" :tagData="tagData" />

            <b-button-toolbar key-nav size="sm" class="d-block">
              <b-button
                  variant="dark"
                  type="button"
                  size="sm"
                  class="topBarButton mr-1"
                  @click="initFullScreen"
              >
                <i class="ri-fullscreen-line"></i>
              </b-button>

              <b-button v-if="!previewMode" variant="dark" size="sm" class="mr-1 topBarButton" @click="previewTag()">
                <i class="mdi mdi-monitor-eye align-middle mr-2"></i>
                {{$t('General.Base.Preview')}}
              </b-button>
              <b-button v-if="previewMode" variant="dark" size="sm" class="mr-1 topBarButton" @click="previewTagStop()">
                <i class="mdi mdi-monitor-off align-middle mr-2"></i>
                {{$t('General.Base.StopPreview')}}
              </b-button>
              <b-button v-b-toggle.sidebar-gallery variant="dark" size="sm" class="topBarButton">
                <i class="ri-image-line align-middle mr-2"></i>
                {{$t('General.Base.Gallery')}}
              </b-button>
            </b-button-toolbar>
          </b-col>
        </b-row>

<!--    <div class="col-3"></div>-->
  </div>
</template>

<script>
import TagDataServerHelper from "@/helpers/constructor/TagDataServerHelper";
import TagDataPreviewHelper from "@/helpers/constructor/TagDataPreviewHelper";
import {tagsService} from "@/helpers/fakebackend/tags.service";
import ControlBar from "./_controlBar"
import Swal from "sweetalert2";

export default {
  name: "topBar",
  components: { ControlBar },
  props: ["tagData", "settingConstructor", "closeReturn"],
  data() {
    return {
      previewMode: false
    }
  },
  methods: {
    ...tagsService,
    closeTagConfirm() {
      if (this.settingConstructor.startVersion !== this.tagData.version) {
        Swal.fire({
          title: this.$t('Constructor.Dialog.SaveTag.Title'),
          text: this.$t('Constructor.Dialog.SaveTag.Text'),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: this.$t('Constructor.Dialog.SaveTag.ConfirmButtonText'),
          cancelButtonText: this.$t('General.Base.Cancel')
        }).then((result) => {
          if (result.isConfirmed) {
            this.closeTagWithCheckVersion()
          }
        });
        return;
      }
      this.closeTagWithCheckVersion()
    },
    closeTagWithCheckVersion() {
      if (
          this.settingConstructor.tagMain !== null &&
          this.settingConstructor.tagMain.tag_source_id !== this.tagData.tag_id
      ) {
        Swal.fire({
          title: this.$t('Constructor.Dialog.CloseConstructor.Title'),
          text: this.$t('Constructor.Dialog.CloseConstructor.Desc'),
          icon: "warning",
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: this.$t('General.Base.Yes'),
          cancelButtonText: this.$t('General.Base.No'),
          preConfirm: () => {
            return new Promise((resolve) => {
              this.setWidgetForTag(this.settingConstructor.tagMain.id, this.tagData.tag_id).then(() => {
                this.settingConstructor.tagMain.tag_source_id = this.tagData.tag_id
                resolve();
              })
            });
          },
        }).then(() => {
          this.closeTag()
        })
        return
      }
      this.closeTag()
    },
    closeTag() {
      TagDataServerHelper.deleteLocalStorage()

      if (!this.closeReturn) {
        this.$router.push('/tags')
        return
      }
      this.$router.push('/' + this.closeReturn)

      if (
          this.settingConstructor.tagMain !== null &&
          this.settingConstructor.tagMain.public_tag_source_id !== null &&
          this.settingConstructor.tagMain.tag_source_id === this.tagData.tag_id &&
          this.settingConstructor.tagMain.public_tag_source_id !== this.settingConstructor.tagMain.tag_source_id
      ) {
        this.$bvToast.toast(this.$t('Constructor.Toasts.DoubleSave.Desc'), {
          title: this.$t('Constructor.Toasts.DoubleSave.Title'),
          variant: 'info',
          solid: true
        })
      }

    },
    saveTag() {
      TagDataServerHelper.saveTag(this.tagData, this.settingConstructor, 'auto').then((data) => {
        if (
            this.settingConstructor.tagMain !== null &&
            this.settingConstructor.tagMain.tag_source_id !== this.tagData.tag_id
        ) {
          this.$bvToast.toast(this.$t('Constructor.Toasts.DoubleSave.Desc'), {
            title: this.$t('Constructor.Toasts.DoubleSave.Title'),
            variant: 'info',
            solid: true,
            toaster: 'b-toaster-top-center',
            appendToast: false
          });
        }

        if (data.warnings.length) { //@TODO: Временный вариант. Вынести в отдельный компанент, проработать систему обработки ошибок
          if (data.warnings.includes('downloadFile')) {
            this.$bvToast.toast(this.$t('Constructor.Toasts.Warnings.downloadFile.Desc'), {
              title: this.$t('Constructor.Toasts.Warnings.downloadFile.Title'),
              variant: 'warning',
              solid: true,
              toaster: 'b-toaster-top-center',
              appendToast: false,
              autoHideDelay: 10000
            });
          }
        }
      })





      //  if (
      //      this.settingConstructor.tagMain === null ||
      //      this.settingConstructor.tagMain.tag_source_id !== this.tagData.tag_id
      //  ) {
      //    TagDataServerHelper.saveTag(this.tagData, this.settingConstructor, 'auto')
      //    return
      //  }
      //
      // TagDataServerHelper.saveTag(this.tagData, this.settingConstructor, 'dublicat')

        // Swal.fire({
        //   title: "Перезаписать опубликованную версию?",
        //   text: "Сейчас опубликована другая версия виджета, вы можете перезаписать ее текущей версией или сохранить ее " +
        //       "отдельно чтобы опубликовать позже. ",
        //   icon: "warning",
        //   showCloseButton: true,
        //   showCancelButton: true,
        //   confirmButtonColor: "#34c38f",
        //   cancelButtonColor: "#f46a6a",
        //   confirmButtonText: "Перезаписать",
        //   cancelButtonText: "Сохранить отдельно"
        // }).then((result) => {
        //   if (result.isConfirmed) {
        //     if (result === true) {
        //       TagDataServerHelper.saveTag(this.tagData, this.settingConstructor, 'public')
        //     }
        //   }
        //   if (result.isDismissed) {
        //
        //   }
        // })

    },
    previewTag() {
      // if (this.tagData.saveVersion !== this.tagData.version) {
      //   TagDataServerHelper.saveTag(this.tagData, this.settingConstructor, 'auto').then(() => {
      //     this.previewMode = true
      //     TagDataPreviewHelper.previewTag(this.tagData).then(() => {
      //       this.previewMode = false
      //     })
      //   })
      //   return
      // }

      this.previewMode = true
      TagDataPreviewHelper.previewTag(this.tagData, this.settingConstructor).then(() => {
        this.previewMode = false
      })


      // TagDataPreviewHelper.previewTag(this.tagData, 'previewTag').then((data) => {
      //   if (data.url) {
      //     this.previewMode = true
      //     window.open(data.url);
      //   }
      // })
    },
    previewTagStop() {
      TagDataPreviewHelper.previewTagStop().then(() => {
        this.previewMode = false
      })
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
          !document.fullscreenElement &&
          /* alternative standard method */ !document.mozFullScreenElement &&
          !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
              Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
  }
}
</script>

<style scoped>

</style>
