import {isArray} from "lodash";

export default class RenderElemStyleContainerHelper {
    constructor(constructorElem) {
        this.thisCE = constructorElem
        this.containerObj = null

        this.calculateIndent()
    }

    calculateIndent() {
        if (this.thisCE.elem.active) {
            this.indent = 6
            this.indentMargin = 6
        } else {
            this.indent = 5
            this.indentMargin = 5
        }

        if (this.thisCE.elemSingle) {
            this.indent = 0
            this.indentMargin = 0
        }

        // if (this.thisCE.elemAddCss.transform === undefined) {
        //     return
        // }
        // if (this.thisCE.elemAddCss.transform.translateX !== undefined && this.thisCE.elemAddCss.transform.translateX.unit === '%') {
        //     this.indentMargin = 0
        //     return
        // }
        // if (this.thisCE.elemAddCss.transform.translateY !== undefined && this.thisCE.elemAddCss.transform.translateY.unit === '%') {
        //     this.indentMargin = 0
        //     return
        // }
    }

    getStyleContainer() {
        this.calculateIndent()
        let obj = Object.assign(
            this.getStyleContainerDimensions(),
            // this.getStyleContainerIndent(),
            this.getStyleContainerCordints(),
            // this.getStyleContainerTransform()
        )

        // obj.zIndex = this.thisCE.elemAddCss.zIndex.val

        if (this.thisCE.elem.active) {
            obj.zIndex = 1000
        }
        if (this.thisCE.elem.tag === 'main-div') {
            obj = this.processingCoordMainDiv(obj)
        }
        return obj
    }

    getStyleContainerTransform() {
        if (this.thisCE.elemAddCss['transform'] === undefined) {
            return {}
        }
        let obj = {transform: ''}
        for (let prop in this.thisCE.elemAddCss['transform']) {
            if (!isArray(this.thisCE.elemAddCss['transform'][prop])) {
                obj.transform += prop + '(' + this.thisCE.elemAddCss['transform'][prop].val + this.thisCE.elemAddCss['transform'][prop].unit + ') '
            }
            // else {
            //@TODO: добавить обработку массива когда она будет
            // }
        }
        return obj
    }

    processingCoordMainDiv(obj) {
        // obj.left = this.thisCE.settingConstructor.constructorArea.zeroShift.y + 'px'
        // obj.top = this.thisCE.settingConstructor.constructorArea.zeroShift.y + 'px'
        // delete(obj.right)
        // delete(obj.bottom)
        return obj

        // if (this.thisCE.elem.main.patternPosition === undefined) {
        //     obj.left = this.thisCE.settingConstructor.constructorArea.zeroShift.y + 'px'
        //     obj.top = this.thisCE.settingConstructor.constructorArea.zeroShift.y + 'px'
        //     delete(obj.right)
        //     delete(obj.bottom)
        //     return obj
        // }
        // if (this.thisCE.elem.main.patternPosition === 1) {
        //     obj.left = '0px'
        // }
    }
    getStyleContainerCordints() {
        return {
            'top': this.thisCE.factParam.y - this.indent + 'px',
            'left': this.thisCE.factParam.x - this.indent + 'px',
        }
        // let obj = {};
        // [
        //     { c: 'top', m: 'marginTop'},
        //     { c: 'left', m: 'marginLeft'},
        //     { c: 'bottom', m: 'marginBottom'},
        //     { c: 'right', m: 'marginRight'},
        // ].forEach((elem) => {
        //     if (this.thisCE.elemAddCss[elem.c] !== undefined) {
        //         obj[elem.c] = this.thisCE.elemAddCss[elem.c].val + this.thisCE.elemAddCss[elem.c].unit
        //         obj[elem.m] =
        //             this.thisCE.elemAddCss[elem.m] !== undefined ?
        //                 this.thisCE.elemAddCss[elem.m].val - this.indentMargin + this.thisCE.elemAddCss[elem.m].unit
        //                 : - this.indentMargin + 'px'
        //     }
        // })
        // return obj
    }
    getStyleContainerIndent() {
        let obj = {};
        ['marginTop', 'marginLeft', 'marginBottom', 'marginRight'].forEach((elem) => {
            if (this.thisCE.elemAddCss[elem] !== undefined) {
                obj[elem] = this.thisCE.elemAddCss[elem].val + this.thisCE.elemAddCss[elem].unit
            }
        })
        return obj
    }
    getStyleContainerDimensions() {
        let obj = {};
        obj['width'] = this.thisCE.factParam.width + this.indent * 2 + 'px'
        obj['height'] = this.thisCE.factParam.height + this.indent * 2 + 'px'
        return obj

        // if (this.thisCE.elemSingle) {
        //     let obj = {
        //         padding: '0px',
        //         position: this.thisCE.elemAddCss.position,
        //     };
        //     if (this.thisCE.elemAddCss.width) {
        //         obj.width = parseInt(this.thisCE.elemAddCss.width.val) + this.indent * 2 + this.thisCE.elemAddCss.width.unit
        //     }
        //     if (this.thisCE.elemAddCss.height) {
        //         obj.height = parseInt(this.thisCE.elemAddCss.height.val) + this.indent * 2 + this.thisCE.elemAddCss.height.unit
        //     }
        //     return obj
        // }

        // if this.factParam.width === null

        // let obj = {};
        // ['width', 'height'].forEach((elem) => {
        //     if (this.thisCE.elemAddCss[elem] === undefined) {
        //         // obj[elem] = 'auto'
        //         obj[elem] = this.thisCE.factParam[elem] + this.indent * 2 + 'px'
        //         return
        //     }
        //     let val = parseInt(this.thisCE.elemAddCss[elem].val)
        //     if (this.thisCE.elemAddCss[elem].unit === '%') {
        //         if (elem === 'width') {
        //             val = this.getContainerObj().parentNode.clientWidth
        //         } else {
        //             val = this.getContainerObj().parentNode.clientHeight
        //         }
        //     }
        //
        //     if (val < this.thisCE.factParam[elem]) {
        //         val = this.thisCE.factParam[elem]
        //     }
        //     obj[elem] = val + this.indent * 2 + 'px'
        // })
        //
        // return obj
    }

    getContainerObj() {
        if (this.containerObj !== null) {
            return this.containerObj
        }
        this.containerObj = document.getElementById('elemContainer_' + this.thisCE.index)
        return this.containerObj
    }
}

