<template>
  <div v-if="showProp">
<!--    <div class="card-text mb-3 mt-3">-->
<!--      <em >Расположение окна на сайте удобней настроить в функции предпросмотр.</em>-->
<!--    </div>-->
    <div class="d-flex align-items-center justify-content-between">
      <div class="form-text ">
        {{$t('Constructor.Props.main.PatternLocationElemWidget.Label') }}
        <div class="card-text text-muted font-size-10">{{$t('Constructor.Props.main.PatternLocationElemWidget.Text') }}</div>
      </div>
      <div class="patternLocationElem">
        <div
            v-for="numb of count"
            v-bind:key="numb"
            :class="['patternLocationElem' + numb, activeButton === numb ? ' active' : '']"
            v-on:click.stop="setPattern(numb)"
        ></div>
      </div>
    </div>

  </div>
</template>

<script>
import ShowProp from '@/helpers/constructor/sideBar/props/MethodShowProp'
import HistoryHelper from '@/helpers/constructor/HistoryHelper'

export default {
  name: "PatternLocationElem",
  props: ["tagData", "indexElem", "settingConstructor", "prop", "elem", "blockProps", 'nameProp'],
  data: function () {
    return {
      count: [1,2,3,4,5,6,7,8,9]
    }
  },
  computed: {
    ...ShowProp,
    activeButton: function() {
      return null
    }
  },
  methods: {
    ...HistoryHelper,
    setPattern: function(numb) {
      let css
      if (this.settingConstructor.desktopMode === 'default') {
        css = this.tagData.htmlElements[this.indexElem].css
      } else {
        css = this.tagData.htmlElements[this.indexElem].addCss[this.settingConstructor.desktopMode]
      }

      let actions = this.checkPropTransform(css)
      if (actions === null) {
        actions = []
      }
      // this.$set(this.tagData.htmlElements[this.indexElem].main, 'patternPosition', numb)

      if (numb === 1) {
        actions = actions.concat(this.deleteProp(css, ['right', 'bottom'], true))
        actions =  actions.concat([
          {obj: css, nameProp: 'left',val: {val: 0, unit: 'px'}, type: 'set'},
          {obj: css, nameProp: 'top',val: {val: 0, unit: 'px'}, type: 'set'}
        ])
        // this.$set(css, 'left', {val: 0, unit: 'px'})
        // this.$set(css, 'top', {val: 0, unit: 'px'})
      } else if (numb === 2) {
        actions = actions.concat(this.deleteProp(css, ['right', 'bottom'], true))
        actions =  actions.concat([
          {obj: css, nameProp: 'left',val: {val: 50, unit: '%'}, type: 'set'},
          {obj: css, nameProp: 'top',val: {val: 0, unit: 'px'}, type: 'set'},
          {obj: css.transform, nameProp: 'translateX',val: {val: -50, unit: '%'}, type: 'set'},
        ])
        // this.$set(css, 'left', {val: 50, unit: '%'})
        // this.$set(css, 'top', {val: 0, unit: 'px'})
        // this.$set(css.transform, 'translateX', {val: -50, unit: '%'})
      } else if (numb === 3) {
        actions = actions.concat(this.deleteProp(css, ['left', 'bottom'], true))
        actions =  actions.concat([
          {obj: css, nameProp: 'right',val: {val: 0, unit: 'px'}, type: 'set'},
          {obj: css, nameProp: 'top',val: {val: 0, unit: 'px'}, type: 'set'},
        ])
        // this.$set(css, 'right', {val: 0, unit: 'px'})
        // this.$set(css, 'top', {val: 0, unit: 'px'})
      } else if (numb === 4) {
        actions = actions.concat(this.deleteProp(css, ['right', 'bottom'], true))
        actions =  actions.concat([
          {obj: css, nameProp: 'left',val: {val: 0, unit: 'px'}, type: 'set'},
          {obj: css, nameProp: 'top',val: {val: 50, unit: '%'}, type: 'set'},
          {obj: css.transform, nameProp: 'translateY',val: {val: -50, unit: '%'}, type: 'set'},
        ])
        // this.$set(css, 'left', {val: 0, unit: 'px'})
        // this.$set(css, 'top', {val: 50, unit: '%'})
        // this.$set(css.transform, 'translateY', {val: -50, unit: '%'})
      } else if (numb === 5) {
        actions = actions.concat(this.deleteProp(css, ['right', 'bottom'], true))
        actions =  actions.concat([
          {obj: css, nameProp: 'left',val: {val: 50, unit: '%'}, type: 'set'},
          {obj: css, nameProp: 'top',val: {val: 50, unit: '%'}, type: 'set'},
          {obj: css.transform, nameProp: 'translateX',val: {val: -50, unit: '%'}, type: 'set'},
          {obj: css.transform, nameProp: 'translateY',val: {val: -50, unit: '%'}, type: 'set'},
        ])
        // this.$set(css, 'left', {val: 50, unit: '%'})
        // this.$set(css, 'top', {val: 50, unit: '%'})
        // this.$set(css.transform, 'translateX', {val: -50, unit: '%'})
        // this.$set(css.transform, 'translateY', {val: -50, unit: '%'})
      } else if (numb === 6) {
        actions = actions.concat(this.deleteProp(css, ['left', 'bottom'], true))
        actions =  actions.concat([
          {obj: css, nameProp: 'right',val: {val: 0, unit: 'px'}, type: 'set'},
          {obj: css, nameProp: 'right',val: {val: 0, unit: 'px'}, type: 'set'},
          {obj: css.transform, nameProp: 'translateY',val: {val: -50, unit: '%'}, type: 'set'},
        ])
        // this.$set(css, 'right', {val: 0, unit: 'px'})
        // this.$set(css, 'top', {val: 50, unit: '%'})
        // this.$set(css.transform, 'translateY', {val: -50, unit: '%'})
      } else if (numb === 7) {
        actions = actions.concat(this.deleteProp(css, ['right', 'top'], true))
        actions =  actions.concat([
          {obj: css, nameProp: 'left',val: {val: 0, unit: 'px'}, type: 'set'},
          {obj: css, nameProp: 'bottom',val: {val: 0, unit: 'px'}, type: 'set'},
        ])
        // this.$set(css, 'left', {val: 0, unit: 'px'})
        // this.$set(css, 'bottom', {val: 0, unit: 'px'})
      } else if (numb === 8) {
        actions = actions.concat(this.deleteProp(css, ['right', 'top'], true))
        actions =  actions.concat([
          {obj: css, nameProp: 'left',val: {val: 50, unit: '%'}, type: 'set'},
          {obj: css, nameProp: 'bottom',val: {val: 0, unit: 'px'}, type: 'set'},
          {obj: css.transform, nameProp: 'translateX',val:{val: -50, unit: '%'}, type: 'set'},
        ])
        // this.$set(css, 'left', {val: 50, unit: '%'})
        // this.$set(css, 'bottom', {val: 0, unit: 'px'})
        // this.$set(css.transform, 'translateX', {val: -50, unit: '%'})
      } else if (numb === 9) {
        actions = actions.concat(this.deleteProp(css, ['left', 'top'], true))
        actions =  actions.concat([
          {obj: css, nameProp: 'right',val: {val: 0, unit: 'px'}, type: 'set'},
          {obj: css, nameProp: 'bottom',val: {val: 0, unit: 'px'}, type: 'set'},
        ])
        // this.$set(css, 'right', {val: 0, unit: 'px'})
        // this.$set(css, 'bottom', {val: 0, unit: 'px'})
      }
      this.HistorySetPackage(actions)
    },
    deleteProp(css, array, allmargin) {
      if (allmargin) {
        array = [...array, ...['margin','marginLeft','marginRight','marginTop','marginBottom']]
      }
      let actions = []
      array.forEach((elem) => {
        // this.$delete(css, elem)
        actions.push({obj: css, nameProp: elem, val: null, type: 'delete'})
      })

      if (css.transform !== undefined && css.transform.translateX !== undefined ) {
        actions.push({obj: css.transform, nameProp: 'translateX', val: null, type: 'delete'})
        // this.$delete(css.transform, 'translateX')
      }
      if (css.transform !== undefined && css.transform.translateY !== undefined ) {
        // this.$delete(css.transform, 'translateY')
        actions.push({obj: css.transform, nameProp: 'translateY', val: null, type: 'delete'})
      }
      return actions
    },
    checkPropTransform(css) {
      if (css.transform === undefined) {
        // return [{obj: css, nameProp: 'transform',val: {}, type: 'set'}]
        this.$set(css, 'transform', {})
        return []
      }
      return null
    }
  }
}
</script>

<style scoped>
  .patternLocationElem {
    border-radius: 5px;
    border: 1px solid #e8e8e8;
    height: 62px;
    width: 62px;
    margin: 4px;
    min-width: 62px;
  }
  .patternLocationElem div {
    height: 20px;
    width: 20px;
    float: left;
  }
  .patternLocationElem div:hover {
    background: #5664d2;
    cursor: pointer;
  }
  .patternLocationElem .active {
    background: #3444c1;
    cursor: pointer;
  }
  .patternLocationElem1, .patternLocationElem2, .patternLocationElem4, .patternLocationElem5 {
    border-right: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
  }
  .patternLocationElem3, .patternLocationElem6 {
    border-bottom: 1px solid #e8e8e8;
  }
  .patternLocationElem7, .patternLocationElem8 {
    border-right: 1px solid #e8e8e8;
  }
  .patternLocationElem1 {
    border-radius: 5px 0 0 0;
  }
  .patternLocationElem3 {
    border-radius: 0 5px 0 0;
  }
  .patternLocationElem7 {
    border-radius: 0 0 0 5px;
  }
  .patternLocationElem9 {
    border-radius: 0 0 5px 0;
  }
</style>
