/**
 * This helper need use for edit the tagData. Helper saves history actions and allows to roll you back.
 *
 * You can make package changes and all actions in the package do to roll you back at a time.
 * @TODO: Дописать доку
 */

import Mousetrap from 'mousetrap'
import lodash, {isObject} from 'lodash'
import TagDataServerHelper from "./TagDataServerHelper";

export default {
    HistoryInit() {
        Mousetrap.bind(['mod+z'], () => {
            this._cancelLastAction()
            return false
        })
        Mousetrap.bind(['ctrl+y', 'command+shift+z'], () => {
            this._cancelLastCancel()
            return false
        })
    },

    _cancelLastAction() {
        if (!this.settingConstructor.historySave.history.length) {
            return
        }
        let actions = this.settingConstructor.historySave.history[this.settingConstructor.historySave.history.length - 1]

        let cancelHistory = []
        actions.forEach(action => {
            let value;
            if  (action.type === 'push') {
                value = action.obj[action.nameProp][action.obj[action.nameProp].length - 1]
            } else {
                value = action.obj[action.nameProp]
            }
            cancelHistory.push({
                obj: action.obj,
                nameProp: action.nameProp,
                value: value,
                type: action.type
            })

            if (action.value === undefined) {
                this.$delete(action.obj, action.nameProp)
            } else {
                if  (action.type === 'push') {
                    action.obj[action.nameProp].pop()
                } else {
                    this.$set(action.obj, action.nameProp, action.value)
                }
            }
        })
        this.settingConstructor.historySave.cancelHistory.push(cancelHistory)
        this.settingConstructor.historySave.history.pop()
    },
    _cancelLastCancel() {
        if (!this.settingConstructor.historySave.cancelHistory.length) {
            return
        }
        let actions = this.settingConstructor.historySave.cancelHistory[this.settingConstructor.historySave.cancelHistory.length - 1]

        let historySave = []
        actions.forEach(action => {
            let value;
            if  (action.type === 'push') {
                value = null
            } else {
                value = action.obj[action.nameProp]
            }
            historySave.push({
                obj: action.obj,
                nameProp: action.nameProp,
                value: value,
                type: action.type
            })

            if (action.value === undefined) {
                this.$delete(action.obj, action.nameProp)
            } else {
                if  (action.type === 'push') {
                    action.obj[action.nameProp].push(action.value)
                } else {
                    this.$set(action.obj, action.nameProp, action.value)
                }
            }

        })
        this.settingConstructor.historySave.history.push(historySave)
        this.settingConstructor.historySave.cancelHistory.pop()
    },
    HistoryDelete(obj, nameProp) {
        let oldValue = isObject(obj[nameProp]) ? lodash.cloneDeep(obj[nameProp]) : obj[nameProp]

        this.$delete(obj, nameProp)
        this._versionAdd()
        this._pushHistory([{
            obj: obj,
            nameProp: nameProp,
            value: oldValue,
            type: 'delete'
        }])
    },

    /**
     * Пакетное добавление действий, можно добавлить несколько разных действий, отменяться они будут все разом
     * actions = [
     *  {obj, nameProp, val, type, oldValue}
     * ]
     * @param actions
     * @constructor
     */
    HistorySetPackage(actions) {
        let keyOperation = ''

        let actionsHistory = []
        actions.forEach(action => {
            keyOperation +=action.nameProp

            let oldValue
            if (action.oldValue) {
                oldValue = action.oldValue
            } else {
                oldValue = isObject(action.obj[action.nameProp]) ? lodash.cloneDeep(action.obj[action.nameProp]) :action.obj[action.nameProp]
            }

            // let oldValue = action.obj[action.nameProp]
            if (action.type === 'set') {
                this.$set(action.obj, action.nameProp, action.val)
            } else if (action.type === 'delete') {
                this.$delete(action.obj, action.nameProp)
            } else if (action.type === 'push') {
                action.obj[action.nameProp].push(action.val)
                oldValue = null
            }

            actionsHistory.push(
                {
                    obj: action.obj,
                    nameProp: action.nameProp,
                    value: oldValue,
                    type: action.type
                }
            )
        })
        this._versionAdd()

        if (this.settingConstructor.historySave.state.writing &&
            this.settingConstructor.historySave.state.elemName === keyOperation
        ) {
            this._clearTimer()
            this._setTimer()
            return
        }

        this._pushHistory(actionsHistory)

        this.$set(this.settingConstructor.historySave.state, 'writing', true)
        this.$set(this.settingConstructor.historySave.state, 'elemName', keyOperation)

        this._setTimer()
    },


    HistoryAddInLastAction(obj, nameProp, val = null, type) {
        if (type === 'set' && val !== null) {
            this.HistorySet(obj, nameProp, val)
        } else if (type === 'delete') {
            this.HistoryDelete(obj, nameProp)
        } else {
            throw new Error('undefined type: ' + type)
        }

        if (this.settingConstructor.historySave.history.length === 1) {
            return
        }
        let newAction = this.settingConstructor.historySave.history[this.settingConstructor.historySave.history.length - 1]

        this.settingConstructor.historySave.history.pop()
        this.settingConstructor.historySave.history[this.settingConstructor.historySave.history.length - 1].push(newAction[0])
    },

    HistorySet(obj, nameProp, val) {
        let oldValue = isObject(obj[nameProp]) ? lodash.cloneDeep(obj[nameProp]) : obj[nameProp]
        this.$set(obj, nameProp, val)
        this._versionAdd()

        if (this.settingConstructor.historySave.state.writing &&
            this.settingConstructor.historySave.state.elemName === nameProp
        ) {
            this._clearTimer()
            this._setTimer()
            return
        }


        const currentTime = new Date().getTime()
        if (currentTime - this.settingConstructor.historySave.state.mktime < 100) {
            this._addInLastActionHistory({
                obj: obj,
                nameProp: nameProp,
                value: oldValue,
                type: 'set'
            })
        } else {
            this._pushHistory([{
                obj: obj,
                nameProp: nameProp,
                value: oldValue,
                type: 'set'
            }])
        }


        this.$set(this.settingConstructor.historySave.state, 'mktime', currentTime)
        this.$set(this.settingConstructor.historySave.state, 'writing', true)
        this.$set(this.settingConstructor.historySave.state, 'elemName', nameProp)

        this._setTimer()
    },
    HistoryPush(obj, nameProp, val) {
        this._versionAdd()
        obj[nameProp].push(val)

        this._pushHistory([{
            obj: obj,
            nameProp: nameProp,
            value: null,
            type: 'push'
        }])

    },
    _versionAdd() {
        this.$set(this.tagData, 'version', this.tagData.version + 1)
        if (this.tagData.version % 10) {
            TagDataServerHelper.saveToLocalStorage(this.tagData)
        }
    },
    _addInLastActionHistory(data) {
        if (!this.settingConstructor.historySave.history.length) {
            this._pushHistory([data])
            return
        }
        this.settingConstructor.historySave.history[this.settingConstructor.historySave.history.length - 1].push(data)
        this.settingConstructor.historySave.cancelHistory = []
    },
    _pushHistory(data) {
        this.settingConstructor.historySave.history.push(data)
        this.settingConstructor.historySave.cancelHistory = []
        if (this.settingConstructor.historySave.history.length > 100) { //@TODO: максимальная длинна истории, вынести в конфиг
            this.settingConstructor.historySave.history.splice(0, 50);
        }
    },
    _clearTimer() {
        clearTimeout(this.settingConstructor.historySave.state.timer)
    },
    _setTimer() {
        this.settingConstructor.historySave.state.timer = setTimeout(() => {
            this.$set(this.settingConstructor.historySave.state, 'writing', false)
        }, 2000)
    }
}
