/*
Для работы некоторых функций нужен файл @/helpers/constructor/TagDataEditService
 */

// import TagDataEditService from "@/helpers/constructor/TagDataEditService";

export default {
    // ...TagDataEditService,
    getElemName: function(elem_id, add_id = true) { //@TODO: локализация
        let name =  this.tagData.htmlElements[elem_id].tag
        if (this.tagData.htmlElements[elem_id].main.name !== '') {
            name =  this.tagData.htmlElements[elem_id].main.name
        }
        if (add_id) {
            name +=' (' + elem_id + ')'
        }
        return name
        // if (this.tagData.htmlElements[elem_id].tag === 'input' && this.tagData.htmlElements[elem_id].main.attr_type === 'text') {
        //     return 'Текстовое поле '  + '(' + elem_id + ')';
        // }
        // if (this.tagData.htmlElements[elem_id].tag === 'input' && this.tagData.htmlElements[elem_id].main.attr_type === 'email') {
        //     return 'Поле для email '  + '(' + elem_id + ')';
        // }
        // return this.tagData.htmlElements[elem_id].tag + add_id ? +' (' + elem_id + ')' : +'';
    },

    getArrToOptionsFromElems: function(elem_ids) {
        let data = [];
        elem_ids.forEach(elem_id => {
            data.push({
                text: this.getElemName(elem_id),
                value: elem_id
            })
        })
        return data
    },

    getAllElementsToOptions: function() {
        let data = []
        for (let index in this.tagData.htmlElements) {
            data.push({
                text: this.getElemName(index),
                value: index
            })
        }
        return data
    },

    /**
     * setting не обязательный обьект. В нем задаются настройки для фильтрации
     * Например setting = {tag: ["div"]} - вернет только дивы
     *
     * Сейчас поддерживаются настройки: tag
     *
     * @param noSetEle
     * @param setting
     * @returns {*[]}
     */
    getAllElementsCurrentWinToOptions: function(noSetEle = true, setting = {}) {
        let allElem = this.getAllChildElem(this.settingConstructor.activeMainElemId, setting)

        // let allElem = this.getAllElemWin(this.settingConstructor.activeMainElemId, this.tagData.htmlElementsTree, []);

        let data = []
        if (noSetEle) {
            data.push({
                text: this.$t('General.Base.SelectItem'),
                value: null
            })
        }
        allElem.forEach((elem_id) => {
            data.push({
                text: this.getElemName(elem_id),
                value: elem_id
            })
        })
        return data
    },

    /**
     * setting не обязательный обьект. В нем задаются настройки для фильтрации
     * Например setting = {tag: ["div"]} - вернет только дивы
     *
     * Сейчас поддерживаются настройки: tag, name, type
     *
     * @param elemId
     * @param setting
     * @returns {*[]} elemId
     */
    getAllChildElem(elemId, setting = {}, allChild = true) {
        let allElem = this.TDESrecursElemTree(elemId, [], allChild)
        let tree =  this.TDESsearchElem(allElem, this.tagData.htmlElementsTree)
        allElem = this.getAllElemWin(elemId, tree, [], allChild);

        let elems = []
        allElem.forEach((elem_id) => {
            if (setting.tag !== undefined && !setting.tag.includes(this.tagData.htmlElements[elem_id].tag)) {
                return
            }
            if (setting.name !== undefined && !setting.name.includes(this.tagData.htmlElements[elem_id].name)) {
                return
            }
            if (setting.type !== undefined && !setting.type.includes(this.tagData.htmlElements[elem_id].type)) {
                return
            }
            elems.push(elem_id)
        })
        return elems
    },
    getAllFormElemFromCurrentWin: function () {
        return this.getAllFormElemFromWin(this.settingConstructor.activeMainElemId)
    },
    getAllFormElemFromWin: function (win_id) {
        let data = []
        let allElem = this.getAllElemWin(win_id, this.tagData.htmlElementsTree, []);
        let formTags = ['input', 'textarea'];
        allElem.forEach((elem_id) => {
            if (formTags.includes(this.tagData.htmlElements[elem_id].tag)) {
                data.push(elem_id)
            }
        })

        return data;
    },
    getAllElemWin: function (parent_id, tree, list, allElems = true) {
        for (let elem_id in tree[parent_id]) {
            list.push(elem_id)
            if (allElems && Object.keys(tree[parent_id][elem_id]).length) {
                list = this.getAllElemWin(elem_id, tree[parent_id], list)
            }
        }
        return list;
    },
    getChildElem(elemId) {
        let parentElemList = this.TDESrecursElemTree(elemId, [])
        parentElemList.push(elemId)
        return this.TDESsearchElem(parentElemList, this.tagData.htmlElementsTree)
    }
}
