import { createClient } from 'pexels';

export default class PixelsApi {
    constructor() {
        this.client = createClient('563492ad6f9170000100000109e69866a62f4a11b4b52f1aecddef92');
    }

    getCurated(data) {
        return this.client.photos.curated(data)
    }

    search(data) {
        return this.client.photos.search(data)
    }

}
