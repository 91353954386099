<template>
  <b-form-group
      :label-cols-sm="prop.name !== null ? 4 : 0"
  >
    <template v-slot:label>
      <label>
        {{$t('Constructor.Props.' + blockProps + '.' + prop.name + '.label') }}
      </label>
    </template>

    <Options :option="noOptions" :noset="true" :elemCurrentProp="elemCurrentProp"/>
    <b-form-text class="mb-3">
      {{$t('Constructor.PropWidgets.SelectOptionsWidget.DefaultOptionInfo')}}
    </b-form-text>
    <div
        v-for="(option, index) in this.elemCurrentProp.selectOptions"
        :key="index"
      >
        <Options v-if="option.val !== null" :option=option :index=index :noset="false" :newOptions="false" :elemCurrentProp="elemCurrentProp" @deleteOptions="deleteOptions" />
      </div>

    <Options :option="newOptions" :newOptions="true" :noset="false" @addOptions="addOptions"/>

  </b-form-group>
<!--    -->
<!--  <div class="mb-4">-->






<!--    <div-->
<!--        v-for="(shadow, index) in this.elemCurrentProp.boxShadow"-->
<!--        :key="index"-->
<!--    >-->
<!--      <Shadow :shadowElem=shadow :index=index :new-shadow="false" @deleteShadow="deleteShadow"/>-->
<!--    </div>-->

<!--    <Shadow :shadowElem=newShadow :index=null :new-shadow="true" @addShadow="addShadow"/>-->
<!--  </div>-->
</template>

<script>
import ShowProp from "@/helpers/constructor/sideBar/props/MethodShowProp";
import PropsHelper from "@/helpers/constructor/sideBar/props/PropsComputed";
import Options from "./_options"
import HistoryHelper from '@/helpers/constructor/HistoryHelper'

export default {
  name: "SelectOptionsWidget",
  props: [ 'elem', 'prop', 'tagData', 'indexElem', 'settingConstructor' ],
  components: { Options },
  data: function() {
    return {
      blockProps: 'main',
      noOptions: {
        val: null,
        text: this.$t('Constructor.PropWidgets.SelectOptionsWidget.SelectValue'),
        selected: "1"
      },
      newOptions: {
        val: '',
        text: '',
        selected: "0"
      }
    }
  },
  computed: {
    ...ShowProp,
    ...PropsHelper
  },
  methods: {
    ...HistoryHelper,
    addOptions(newOption) {
      if (this.elemCurrentProp.selectOptions === undefined) {
        // this.$set(this.elemCurrentProp, 'selectOptions', [newOption])
        this.HistorySet(this.elemCurrentProp, 'selectOptions', [newOption])
      } else {
        this.HistoryPush(this.elemCurrentProp, 'selectOptions', newOption)
        // this.elemCurrentProp.selectOptions.push(newOption)
      }
    },
    deleteOptions(index) {
      this.HistoryDelete(this.elemCurrentProp.selectOptions, index)
      // this.$delete(this.elemCurrentProp.selectOptions, index)
    }
  }
}
</script>

<style scoped>

</style>
