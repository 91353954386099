import {isArray} from "lodash";

export const computedStyleElem = {
    elemCss: function() {
        let obj = {}

        // const cancelProp = ['top','left','right','bottom', 'position', 'marginTop', 'marginBottom', 'marginLeft', 'marginRight', 'transform']
        for (let prop in this.elemAddCss) {
            // if (cancelProp.includes(prop)) {
            //     continue
            // }

            // если строка то всегда просто присваеваем значения и идем дальше
            if (typeof(this.elemAddCss[prop]) === 'string') {
                obj[prop] = this.elemAddCss[prop]

                if (prop === 'position' && obj[prop] === 'fixed') {
                    obj[prop] = 'absolute'
                }
                continue
            }

            // если для свойства задан обработчик то запускается обработчик
            let val = this.elemCssPropertyHandlers(prop)
            if (val !== null) {
                obj[prop] = val
                continue
            }

            // если массив то перечисляем через пробел
            if (isArray(this.elemAddCss[prop])) {
                obj[prop] = this.elemAddCss[prop].join(' ')
            }

            // если обьект и есть val то обрабатывается как число
            if (this.elemAddCss[prop].val !== undefined) {
                obj[prop] = this.elemAddCss[prop].unit ? this.elemAddCss[prop].val + this.elemAddCss[prop].unit : this.elemAddCss[prop].val
            }


        }


        // if (this.elem.main.hiddenElem && !this.elem.hidden && !this.elem.active) {
        //     obj['display'] = 'none'
        // }

        if (!this.elem.hidden && !this.elem.active) {
            obj['display'] = 'none'
            // obj['visibility'] = 'hidden'
        }
        // else {
            // obj['visibility'] = 'visible'
        // }
        // if (obj['display'] !== undefined && obj['display'] === 'none') {
        //     delete obj['display']
        //     obj['opacity'] = '0.1'
        // }

        if (this.elemSingle) {
            return obj
        }

        if (this.settingConstructor.showBordersElem === true && !this.elem.active && !['selfText','selfA'].includes(this.elem.type)) {
            obj['outline'] = '1px dashed rgb(45 44 255 / 30%)'
            obj['outlineOffset'] = '-1px'
            // ['Top', 'Bottom', 'Left', 'Right'].forEach(side => {
            //     if (this.elemAddCss['border' + side + 'Style'] === undefined
            //         || this.elemAddCss['border' + side + 'Width'] === undefined
            //         || this.elemAddCss['border' + side + 'Style'] === 'none'
            //         || this.elemAddCss['border' + side + 'Width'].val === 0
            //     ) {
            //         obj['border' + side + 'Style'] = 'dashed'
            //         obj['border' + side + 'Width'] = '1px'
            //         obj['border' + side + 'Color'] = '#9999994C'
            //     }
            // })
        }
        //     (this.elemAddCss.borderStyle === undefined
        //         || this.elemAddCss.borderWidth === undefined
        //         || this.elemAddCss.borderStyle === 'none'
        //         || this.elemAddCss.borderWidth.val === 0)
        // ) {
        //     obj['borderStyle'] = 'dashed'
        //     obj['borderWidth'] = '1px'
        //     obj['borderColor'] = '#9999994C'
        // }
        return obj
    }
}

export const methodsStyleElem = {
    elemCssPropertyHandlers: function(prop) {
        const PropertyHandlers = {
            // width: () => {
            //     if (this.elemAddCss[prop].unit !== '%') {
            //         return null
            //     }
            //     return document.getElementById('elemContainer_' + this.index).parentNode.clientWidth + 'px'
            // },
            // height: () => {
            //     if (this.elemAddCss[prop].unit !== '%') {
            //         return null
            //     }
            //     return document.getElementById('elemContainer_' + this.index).parentNode.clientHeight + 'px'
            // },
            boxShadow: () => {
                let str = ''
                this.elemAddCss[prop].forEach(shadow => {
                    if (shadow.inset) {
                        str += 'inset '
                    }
                    str += shadow.shiftX.val + shadow.shiftX.unit + ' '
                        + shadow.shiftY.val + shadow.shiftY.unit + ' '
                        + shadow.blur.val + shadow.blur.unit + ' '
                        + shadow.stretching.val + shadow.stretching.unit + ' '
                        + shadow.color + ', '
                })
                str = str.slice(0, -2)
                return str
            },
            textShadow: () => {
                let str = ''
                this.elemAddCss[prop].forEach(shadow => {
                    str += shadow.shiftX.val + shadow.shiftX.unit + ' '
                        + shadow.shiftY.val + shadow.shiftY.unit + ' '
                        + shadow.radius.val + shadow.radius.unit + ' '
                        + shadow.color + ', '
                })
                str = str.slice(0, -2)
                return str
            },
            backgroundImage: () => {
                return  'url(' + this.elemAddCss[prop].url + ')'
            },
            backgroundSize: () => {
                let str = ''
                if (typeof(this.elemAddCss[prop][0]) === 'string') {
                    str += this.elemAddCss[prop][0]
                } else {
                    str += this.elemAddCss[prop][0].val + this.elemAddCss[prop][0].unit
                }
                str += ' '
                if (typeof(this.elemAddCss[prop][1]) === 'string') {
                    str += this.elemAddCss[prop][1]
                } else {
                    str += this.elemAddCss[prop][1].val + this.elemAddCss[prop][1].unit
                }
                return str
            },
            backgroundRepeat: () => {
                let str = ''
                if (typeof(this.elemAddCss[prop][0]) === 'string') {
                    str += this.elemAddCss[prop][0]
                } else {
                    str += this.elemAddCss[prop][0].val + this.elemAddCss[prop][0].unit
                }
                str += ' '
                if (typeof(this.elemAddCss[prop][1]) === 'string') {
                    str += this.elemAddCss[prop][1]
                } else {
                    str += this.elemAddCss[prop][1].val + this.elemAddCss[prop][0].unit
                }

                return str
            },
            maskRepeat: () => {
                let str = ''
                if (typeof(this.elemAddCss[prop][0]) === 'string') {
                    str += this.elemAddCss[prop][0]
                } else {
                    str += this.elemAddCss[prop][0].val + this.elemAddCss[prop][0].unit
                }
                str += ' '
                if (typeof(this.elemAddCss[prop][1]) === 'string') {
                    str += this.elemAddCss[prop][1]
                } else {
                    str += this.elemAddCss[prop][1].val + this.elemAddCss[prop][0].unit
                }

                return str
            },
            maskImage: () => {
                return  'url(' + this.elemAddCss[prop].url + ')'
            },
            maskSize: () => {
                let str = ''
                if (typeof(this.elemAddCss[prop][0]) === 'string') {
                    str += this.elemAddCss[prop][0]
                } else {
                    str += this.elemAddCss[prop][0].val + this.elemAddCss[prop][0].unit
                }
                str += ' '
                if (typeof(this.elemAddCss[prop][1]) === 'string') {
                    str += this.elemAddCss[prop][1]
                } else {
                    str += this.elemAddCss[prop][1].val + this.elemAddCss[prop][1].unit
                }

                return str
            },
            transform: () => {
                let str = ''
                for (let prop in this.elemAddCss.transform) {
                    // if (this.elemAddCss.transform[prop] === undefined)
                    if (!isArray(this.elemAddCss.transform[prop])) {
                        str += prop + '(' + this.elemAddCss.transform[prop].val + this.elemAddCss.transform[prop].unit + ') '
                    }
                    // else {
                    //@TODO: добавить обработку массива когда она будет
                    // }
                }
                return str
            },

        }
        if (PropertyHandlers[prop] !== undefined) {
            return PropertyHandlers[prop]()
        }
        return null
    }
}
