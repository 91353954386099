export const PatternServiceElems = {
    serviceArrow: {
        name: 'serviceArrow',
        devBlock: {
            class: []
        },
        draggable: false,
        resizable: false,
        rotatable: false,
        content: '',
        parent: null,
        active: false,
        rotation: 0,
        id: '',
        tag: 'div',
        main:{},
        css: {
            bottom: {
                val: -25,
                unit: 'px'
            },
            left: {
                val: 50,
                unit: '%'
            },
            width: {
                val: 0,
                unit: 'px'
            },
            height: {
                val: 0,
                unit: 'px'
            },
            marginLeft: {
                val: -15,
                unit: 'px'
            },
            borderTopWidth: {
                val: 20,
                unit: 'px'
            },
            borderTopColor: 'transparent',
            borderTopStyle: 'solid',
            borderBottomWidth: {
                val: 20,
                unit: 'px'
            },
            borderBottomColor: 'transparent',
            borderBottomStyle: 'solid',
            borderRightWidth: {
                val: 30,
                unit: 'px'
            },
            borderRightColor: '#ff0000',
            borderRightStyle: 'solid',
            borderLeftWidth: {
                val: 0,
                unit: 'px'
            },
            borderLeftColor: 'none',
            borderLeftStyle: 'none',
            zIndex: {
                val: 1001
            },
            transform: 'rotate(270deg)'
        },
        js: {
            init: {
                scripts: {
                    tooltipServiceArrow: {}
                }
            },
            events: []
        }
    }
}
