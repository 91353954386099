import { render, staticRenderFns } from "./boxShadow.vue?vue&type=template&id=4824b9c3&scoped=true&"
import script from "./boxShadow.vue?vue&type=script&lang=js&"
export * from "./boxShadow.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4824b9c3",
  null
  
)

export default component.exports