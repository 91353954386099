export const mainDiv = {
    name: 'mainDiv',
    type: "div",
    devBlock: {
        class: ['inArea'],
        classBlockTrue: []
    },
    draggable: false,
    resizable: true,
    parent: null,
    active: false,
    possibleChild: true,
    hidden: true,
    tag: 'div',
    main: {
        name: '1',
        content: '',
        hiddenElem: false,
        target: {
            cssSelector: 'body',
            typeRender: 'beforeend'
        }
    },
    addMain: {},
    css: {
        position: 'relative',
        boxSizing: 'border-box',
        width: {
            val: 100,
            unit: '%'
        },
        height: {
            val: 400,
            unit: 'px'
        },
        maxWidth: {
            val: 600,
            unit: 'px'
        },
        zIndex: {
            val: 1000
        },

    },
    addCss: {
        576: {},
        992: {}
    },
    cssPseudoClasses:{},
    cssJs: {},
    js: {
        init: {
            scripts: {}
        },
        events: []
    },
    addJs: {},
    jsBlocks: {}
}

export const defaultTagData = {
    tag_id: null,
    name: '',
    version: 0,
    saveVersion: 0,
    settings: {
        display: {}
    },
    htmlElementIndex: 1,
    htmlElementsTree: {
        e0: {}
    },
    htmlElements: {
        e0: mainDiv
    }
}
