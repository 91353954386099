<template>
  <div>
    <RenderProp
        v-for="(copyProp, index) in prop.props"
        :key="index"
        :tagData="tagData"
        :blockProps="copyProp.block"
        :settingConstructor="settingConstructor"
        :prop="BasePropElems[copyProp.block][copyProp.section][copyProp.nameProp]"
        :elem="elem"
        :activeElemId="indexElem"
    />
  </div>
</template>

<script>

export default {
  name: "DuplicatePropWidget",
  props: [ 'elem', 'prop', 'tagData', 'indexElem', 'settingConstructor', 'BasePropElems' ],
  components: {
    RenderProp: () => import('../_renderProp')
  }
}
</script>

<style scoped>

</style>