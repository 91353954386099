<template>
  <b-form-group
      :label-cols-sm="prop.name !== null ? 4 : 0"
  >
    <template v-slot:label>
      <label
          v-b-tooltip
      >
        {{$t('Constructor.Props.' + blockProps + '.' + prop.name + '.label') }}
      </label>
    </template>

    <div
        v-for="(option, index) in radios"
        :key="index"
      >
        <Options
            v-if="option.val !== null"
            :tagData="tagData"
            :settingConstructor = "settingConstructor"
            :option=option
            :index=index
            :noset="false"
            :newOptions="false"
            :elemCurrentProp="elemCurrentProp"
            :radioGroupElemId = indexElem
            @deleteOptions="deleteRadio"
        />
      </div>

    <b-input-group class="mb-1" size="sm">
      <b-form-input
          :placeholder="$t('General.Form.NoSet')"
          type="text"
          class="col-12"
          v-model="newRadio"
      >
      </b-form-input>

      <div class="input-group-append">
        <b-button
            class=""
            variant="success"
            v-on:click.stop="addRadio()"
            v-b-tooltip.viewport.hover.ds500.dh0.html.left
            :title = "$t('Constructor.PropWidgets.CheckboxGroupWidget.AddOption')"
        >
          <i class="fas fa-plus"></i>
        </b-button>
      </div>
    </b-input-group>

  </b-form-group>
</template>

<script>
import ShowProp from "@/helpers/constructor/sideBar/props/MethodShowProp";
import PropsHelper from "@/helpers/constructor/sideBar/props/PropsComputed";
import Options from "./_options"
import HistoryHelper from '@/helpers/constructor/HistoryHelper'
import TagDataGetElemsHelper from "@/helpers/constructor/TagDataGetElemsHelper";
import TagDataEditService from "@/helpers/constructor/TagDataEditService";
import { PatternElems } from '@/helpers/constructor/patterns/PatternElems'
import lodash from 'lodash'
import ElemStyleHelper from '@/helpers/constructor/ElemStyleHelper'

export default {
  name: "CheckboxGroupWidget",
  props: [ 'elem', 'prop', 'tagData', 'indexElem', 'settingConstructor' ],
  components: { Options },
  data: function() {
    return {
      elemStyleHelper: null,
      blockProps: 'main',
      newRadio: ''
    }
  },
  computed: {
    ...ShowProp,
    ...PropsHelper,
    radios: function() {
      let options = []
      let childs = this.getChildElem(this.indexElem)
      for (let labelId in childs) {
        let radio = {}
        for (let elemId in childs[labelId]) {
          radio.labelId = labelId
          if (this.tagData.htmlElements[elemId].name === 'checkbox' ) {
            radio.elemIdRadio = elemId
          }
          if (this.tagData.htmlElements[elemId].name === 'span' ) {
            radio.elemIdText = elemId
          }
        }
        options.push(radio)
      }
      return options
    }
  },
  methods: {
    ...HistoryHelper,
    ...TagDataGetElemsHelper,
    ...TagDataEditService,
    addRadio() {
      let elemLabel = lodash.cloneDeep(PatternElems.checkboxGroup.elemChild[0])
      elemLabel = this.elemStyleHelper.getStyleElem(elemLabel)

      for (let i in elemLabel.elemChild) {
        if (elemLabel.elemChild[i].name === 'checkbox') {
          // elemLabel.elemChild[i].main.attr_name = this.indexElem
          elemLabel.elemChild[i].main.attr_required = this.elem.main.attr_required
          elemLabel.elemChild[i].main.field_user_name = this.newRadio
        }
        if (elemLabel.elemChild[i].name === 'span') {
          elemLabel.elemChild[i].elemChild[0].main.content = this.newRadio
        }
      }

      this.TDEScreateElem(elemLabel, this.indexElem)
    },
    deleteRadio(index) {
      this.TDESDelElemWithChild(index)
    },
    // getPatternElem(elem, elemName) {
    //   if (elem.elemChild !== undefined) {
    //     for (let i in elem.elemChild) {
    //       elem.elemChild[i] = this.getPatternElem(elem.elemChild[i], elem.elemChild[i].name)
    //     }
    //   }
    //
    //   return this.elemStyleHelper.getStyleElem(elem, elemName)
    // }
  },
  mounted() {
    this.elemStyleHelper = new ElemStyleHelper()
  }
}
</script>

<style scoped>

</style>
