<template>
  <div>
<!--    <b-form-group-->
<!--        label-cols-sm="4"-->
<!--        label="Mask prset"-->
<!--        label-for="fieldMask"-->
<!--    >-->

<!--      <b-form-select-->
<!--          :options="maskVariant"-->
<!--          v-model="maskPreset"-->
<!--          size="sm"-->
<!--      ></b-form-select>-->


<!--    </b-form-group>-->

    <b-form-group
        label-cols-sm="4"
        label="Mask"
        label-for="fieldMask"
    >
      <b-input-group >
        <b-form-input
            :placeholder="$t('General.Form.NoSet')"
            v-model="mask"
            id="fieldMask"
            size="sm"
        ></b-form-input>
      </b-input-group>
    </b-form-group>
<!--    <b-form-input-->
<!--        :placeholder="$t('General.Form.NoSet')"-->
<!--        type="text"-->
<!--        v-model="valProp"-->
<!--        :name="blockProps + nameProp"-->
<!--        style="width: 100%"-->
<!--        v-b-tooltip.hover.ds1000.dh0.viewport.left-->
<!--        :title="getTooltip()"-->
<!--        class="propField mousetrap"-->
<!--        maxlength = "250"-->
<!--        size="sm"-->
<!--    ></b-form-input>-->
  </div>
</template>

<script>
// import TagDataGetElemsHelper from "@/helpers/constructor/TagDataGetElemsHelper";
import HistoryHelper from "@/helpers/constructor/HistoryHelper";
// import TagDataEditService from "@/helpers/constructor/TagDataEditService";

export default {
  name: "FieldMaskWidget",
  props: [ "indexElem", "tagData", "settingConstructor", "elem", "prop" ],
  data: function() {
    return {
      // maskVariant: [
      //   {
      //     text: '+7(000)000-00-00',
      //     value: '+{0}(000)000-00-00',
      //   },
      // ]
    }
  },
  computed: {
    mask: {
      get: function () {
        if (!this.elem.main.fieldMask) {
          return ''
        }
        return this.elem.main.fieldMask;
      },
      set: function (mask) {
        this.HistorySet(this.elem.main, 'fieldMask', mask)
      }
    },
    // maskPreset: {
    //   get: function () {
    //     return null
    //   },
    //   set: function (val) {
    //     this.mask = val
    //   }
    // }
  },
  methods: {
    ...HistoryHelper,
  }
}
</script>

<style scoped>

</style>