<template>
  <div>
    <b-form-group
        inline
        :label-cols-sm="4"
        :label-cols-lg="4"
        :label="$t('General.Base.Event')"
        :label-for="indexEvent + '' + indexElem"
        class="mb-1"
    >
      <b-input-group size="sm">
          <b-form-select
              class="col-12"
              :options="eventOptions"
              v-model="eventData.event"
              :name="indexEvent + '' + indexElem"
          ></b-form-select>
        <div class="input-group-append">
          <b-button
              v-if="!newEvent"
              size="sm"
              variant="dark"
              v-on:click.stop="deleteEvent"
          ><i class="mdi mdi-minus" ></i></b-button>

          <b-button
              v-if="newEvent"
              size="sm"
              variant="dark"
              v-on:click.stop="addEvent"
          ><i class="mdi mdi-plus" ></i></b-button>
        </div>
      </b-input-group >

    </b-form-group>

    <div
      v-if="basePropElems && !newEvent"
    >
      <RenderField
          v-for="(element, index) in basePropElems.js.events[eventData.event].fields"
          :key="index"
          :prop="element"
          :nameProp="index"
          :eventData="eventData.data"
          :settingConstructor = settingConstructor
          :tagData = tagData
        />
    </div>

    <div v-if="!newEvent">

<!--      <div class="pl-2 mb-4 codeLineDashedLeft">-->
<!--        <h5 class="card-title mb-3 mt-3">Пресеты</h5>-->
<!--            <RenderPreset-->
<!--                v-for="(preset, index) in elem.js.events[indexEvent].presets"-->
<!--                :key="index"-->
<!--                :preset = preset-->
<!--                :presetIndex = index-->
<!--                :allActionsOptions = allActionsPresets-->
<!--                :newPreset = false-->
<!--                :elem = elem-->
<!--                :indexEvent="indexEvent"-->
<!--                :basePropElems = "basePropElems"-->
<!--                :tagData = "tagData"-->
<!--                :settingConstructor = settingConstructor-->
<!--            />-->

<!--            <RenderPreset-->
<!--                :preset = presetNewObj-->
<!--                :allActionsOptions = allActionsPresets-->
<!--                :newPreset = true-->
<!--                :elem = elem-->
<!--                :indexEvent="indexEvent"-->
<!--                :basePropElems = "basePropElems"-->
<!--                :tagData = "tagData"-->
<!--                :settingConstructor = settingConstructor-->
<!--            />-->
<!--      </div>-->

      <div class="pl-2 codeLineDashedLeft">
        <h4 v-if="elem.js.events[indexEvent].actions.length" class="card-title mb-3 mt-3">
          {{$t('General.Base.Actions')}}
        </h4>

        <RenderAction
            v-for="(action, index) in elem.js.events[indexEvent].actions"
            :key = index
            :tagData = tagData
            :newAction = false
            :action = action
            :actionIndex = index
            :settingConstructor = settingConstructor
            :basePropElems = "basePropElems"
            :parent = eventData.event
            :elem = elem
            @deleteAction="deleteAction"
        />

        <hr v-if="elem.js.events[indexEvent].actions.length">
        <h4 class="card-title mb-3 mt-3">{{$t('General.Base.AddAction')}}</h4>
        <RenderAction
            :tagData = tagData
            :newAction = true
            :settingConstructor = settingConstructor
            :basePropElems = "basePropElems"
            :parent = eventData.event
            :elem = elem
            @addAction="addAction"
        />
      </div>
    </div>

  </div>
</template>

<script>
// import RenderPreset from './_renderPreset'
import RenderField from './_renderField'
import RenderAction from './_renderAction'
import HistoryHelper from '@/helpers/constructor/HistoryHelper'

export default {
  name: "renderEvent",
  components: { RenderField, RenderAction }, //RenderAction RenderPreset,
  props: ["tagData", "settingConstructor", "indexEvent", "eventData", "indexElem", "basePropElems", "elem", "newEvent"],
  data() {
    return {
      presetNewObj: {
        elemId: null,
        name: null
      }
    }
  },
  computed: {
    eventOptions: function () {
      if (!this.basePropElems) {
        return []
      }
      let data = []

      if (this.newEvent) {
        data.push({ value: null, text: this.$t('General.Base.SelectEvent') })
      }

      let currentEvent = []
      this.elem.js.events.forEach((elem) => {
        currentEvent.push(elem.event)
      })

      for (let indexEvent in this.basePropElems.js.events) {
        if ((!this.newEvent && indexEvent !== this.eventData.event) && currentEvent.includes(indexEvent)) {
          continue
        }
        if (this.newEvent && currentEvent.includes(indexEvent)) {
          continue
        }
        data.push({
          text: this.$t('Constructor.Props.js.Events.' + indexEvent + '.name'),
          value: indexEvent
        })
      }
      return data
    },
    // allActionsPresets: function() {
    //   if (!this.basePropElems) {
    //     return []
    //   }
    //   let data =[
    //     { value: null, text: 'Выберите пресет' }
    //   ]
    //   for (let index in this.basePropElems.js.presets) {
    //     data.push({
    //       text: this.basePropElems.js.presets[index].name,
    //       value: index
    //     })
    //   }
    //   return data
    // }
  },
  methods: {
    ...HistoryHelper,
    deleteEvent() {
      this.HistoryDelete(this.elem.js.events, this.indexEvent)
      // this.$delete(this.elem.js.events, this.indexEvent)
    },
    addEvent() {
      if (!this.eventData.event) {
        return false //@TODO: добавить сообщение о ошибке
      }
      const data = {
        event: this.eventData.event,
        data: {},
        presets: [],
        actions: []
      }

      this.HistoryPush(this.elem.js, 'events', data)
      // this.elem.js.events.push(data)
      this.eventData.event = null
    },
    addAction: function(actionObj) {
      this.HistoryPush(this.elem.js.events[this.indexEvent], 'actions', actionObj)
      // this.elem.js.events[this.indexEvent].actions.push(actionObj)
    },
    deleteAction: function(actionIndex) {
      this.HistoryDelete(this.elem.js.events[this.indexEvent].actions, actionIndex)
      // this.$delete(this.elem.js.events[this.indexEvent].actions, actionIndex)
    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>
