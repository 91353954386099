<template>
  <div>
    <b-form-group
         v-if="showProp"
        :label-cols-sm="prop.labelCol ? prop.labelCol  : 3"
        :label="$t('Constructor.Props.' + blockProps + '.' + prop.name + '.label')"
        :label-for="blockProps + nameProp"
    >
      <template v-slot:label>
        <span
            v-b-tooltip.viewport.hover.ds500.dh0.html.left="getTooltipLabel()"
            :for="blockProps + nameProp"
        >
          {{$t('Constructor.Props.' + blockProps + '.' + prop.name + '.label') }}
        </span>
      </template>

      <PropElem
          :prop="prop"
          :nameProp="nameProp"
          :elem="elem"
          :indexElem="indexElem"
          :tagData="tagData"
          :blockProps="blockProps"
          :settingConstructor="settingConstructor"
      />

      <b-form-text v-if="$t('Constructor.Props.' + blockProps + '.' + prop.name + '.description')" class="mb-2">
        {{$t('Constructor.Props.' + blockProps + '.' + prop.name + '.description')}}
        <i
            v-if="$t('Constructor.Props.' + blockProps + '.' + prop.name + '.tooltip')"
            v-b-tooltip.ds1000.dh0
            :title="$t('Constructor.Props.' + blockProps + '.' + prop.name + '.tooltip')"
            class="far fa-question-circle"></i>
      </b-form-text>
    </b-form-group>
  </div>
</template>

<script>
import PropElem from './_propElems'
import ShowProp from '@/helpers/constructor/sideBar/props/MethodShowProp'
import HistoryHelper from "@/helpers/constructor/HistoryHelper";
import PropsHelper from "@/helpers/constructor/sideBar/props/PropsComputed";

export default {
  name: "form_group",
  props: ['nameProp', 'prop', 'elem', 'tagData', 'indexElem', 'blockProps', 'settingConstructor'],
  components: { PropElem },
  computed: {
    ...ShowProp,
    ...PropsHelper,
    // isExist() {
    //   if (this.elemCurrentProp[this.nameProp]) {
    //     return true
    //   }
    //   return false
    // }
  },
  methods: {
    ...HistoryHelper,
    getTooltipLabel() {
      return this.$t('Constructor.Props.' + this.blockProps + '.' + this.prop.name + '.labelTooltip') ?
          this.$t('Constructor.Props.' + this.blockProps + '.' + this.prop.name + '.labelTooltip') : null
    }
  }
}
</script>

<style scoped>

</style>
