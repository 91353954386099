<template>
  <div v-if="containContent()">
    <b-form-group
        :label-cols-sm="0"
        :label="$t('Constructor.Props.' + blockProps + '.' + prop.name + '.label')"
        label-for="content"
        class="mb-0 pt-0 pb-0"
    >
      <b-form-textarea
          v-if="!selfRedactor()"
          :placeholder="$t('General.Form.NoSet')"
          v-model="content"
          name="content"
          class="propField mousetrap mb-1"
          maxlength = "5000"
          size="sm"
      ></b-form-textarea>

      <div v-if="selfRedactor()">
        <div id="selfTextEditor" style="height: 100px" class="mb-2"></div>
      </div>

    </b-form-group>


  </div>
</template>

<script>
import ShowProp from "@/helpers/constructor/sideBar/props/MethodShowProp";
import PropsHelper from "@/helpers/constructor/sideBar/props/PropsComputed";
import { PatternElemsSettings } from '@/helpers/constructor/patterns/PatternElemsSettings'
import TagDataGetElemsHelper from "@/helpers/constructor/TagDataGetElemsHelper";
import TagDataEditService from "@/helpers/constructor/TagDataEditService";
import HistoryHelper from "@/helpers/constructor/HistoryHelper";

import Quill from 'quill'
import lodash, {clone, pull} from "lodash";
import {PatternElems} from "@/helpers/constructor/patterns/PatternElems";
import ElemStyleHelper from '@/helpers/constructor/ElemStyleHelper'

export default {
  name: "RadioGroupWidget",
  props: [ 'elem', 'prop', 'tagData', 'indexElem', 'settingConstructor' ],
  data: function() {
    return {
      elemStyleHelper: null,
      blockProps: 'main',
      newRadio: '',
      contentElemId: null,
      quill: null
    }
  },
  computed: {
    ...ShowProp,
    ...PropsHelper,
    content: {
      get: function() {
        if (!this.contentElemId) {
          return ''
        }
        return this.tagData.htmlElements[this.contentElemId].main.content
      },
      set: function(val) {
        this.HistorySet(this.tagData.htmlElements[this.contentElemId].main, 'content', val)
      }
    }
  },
  methods: {
    ...TagDataEditService,
    ...TagDataGetElemsHelper,
    ...HistoryHelper,
    containContent() {
      if (PatternElemsSettings[this.elem.name] !== undefined && PatternElemsSettings[this.elem.name].containContent) {
        return true
      }
      return false
    },
    selfRedactor() {
      if (PatternElemsSettings[this.elem.name] !== undefined && PatternElemsSettings[this.elem.name].selfRedactor) {
        return true
      }
      return false
    },
    quillUpdate: function () {
      this.convertFromDelta(this.quill.getContents())
    },
    convertToDelta: function () {
      let elems = this.getAllChildElem(this.indexElem, { type: ['selfText']})

      let delta = { ops: [] }
      for (let elemId in elems) {
        delta.ops.push({
          insert: this.tagData.htmlElements[elems[elemId]].main.content,
          attributes: this.getAttrForDelta(this.tagData.htmlElements[elems[elemId]])
        })
      }

      return delta;
    },
    convertFromDelta: function (delta) {
      let elems = this.getAllChildElem(this.indexElem, { type: ['selfText']})
      let actions = []
      let useElems = []
      for (let id in delta.ops) {
        let attrs = []
        if (delta.ops[id].attributes) {
          attrs = this.getAttrForTagData(delta.ops[id].attributes)
        }

        let mostNameElem = 'selfText'
        if (attrs.link) {
          mostNameElem = 'selfA'
        }

        if (elems[id] === undefined) {
          elems[id] = this.createNewElem(mostNameElem, elems[id - 1])
        } else if (this.tagData.htmlElements[elems[id]].name !== mostNameElem) {
          this.TDESDelElemWithChild(elems[id])
          // delete elems[id]
          let newId = this.createNewElem(mostNameElem, elems[id - 1])
          elems[id] = newId
          // elems.splice(id-1, 0, newId);
        }

        actions =  actions.concat([
          {obj: this.tagData.htmlElements[elems[id]].main, nameProp: 'content', val: delta.ops[id].insert, type: 'set'},
        ])

        let resetElem = this.elemStyleHelper.getStyleElem(clone(this.tagData.htmlElements[elems[id]]))

        actions.push({obj: this.tagData.htmlElements[elems[id]], nameProp: 'css', val: resetElem.css, type: 'set'})


        if (attrs) {
          if (attrs.link) {
            actions.push({obj: this.tagData.htmlElements[elems[id]].main, nameProp: 'attr_href', val: attrs.link, type: 'set'})
          }
          delete attrs.link
          for (let attr in attrs) {
            actions.push({obj: this.tagData.htmlElements[elems[id]].css, nameProp: attr,val: attrs[attr], type: 'set'})
          }
        }

        if (Object.keys(attrs).length && this.tagData.htmlElements[elems[id]].tag === null) {
          actions.push({obj: this.tagData.htmlElements[elems[id]], nameProp: 'tag', val: 'span', type: 'set'})
        }
        useElems.push(elems[id])
      }

      this.HistorySetPackage(actions)

      if (useElems.length < elems.length) {
        for (let i in elems) {
          if (!useElems.includes(elems[i])) {
            // this.TDESDelElem(elems[i])
          }
        }
      }
    },
    getAttrForDelta: function (elem) {
      let attributes = {}
      for (let attr in elem.css) { //@TODO: no css, mobile css
        if (attr === 'fontWeight' && elem.css[attr] === '900') {
          attributes.bold = true
        }
        if (attr === 'fontStyle' && elem.css[attr] === 'italic') {
          attributes.italic = true
        }
        if (attr === 'textDecoration' && elem.css[attr].includes('underline')) {
          attributes.underline = true
        }
        if (attr === 'textDecoration' && elem.css[attr].includes('line-through')) {
          attributes.strike = true
        }
      }

      if (elem.tag === 'a') {
        attributes.link = elem.main.attr_href
      }

      return attributes;
    },
    getAttrForTagData: function (deltaAttrs) {
      let css = {
        fontStyle: 'normal',
        fontWeight: '100',
        link: false,
        textDecoration: []
      }
      for (let attr in deltaAttrs) {
        if (attr === 'bold') {
          if (deltaAttrs[attr]) {
            css.fontWeight = '900'
          } else {
            css.fontWeight = '100'
          }
        }

        if (attr === 'italic') {
          if (deltaAttrs[attr]) {
            css.fontStyle = 'italic'
          } else {
            css.fontStyle = 'normal'
          }
        }

        if (attr === 'link') {
          if (deltaAttrs[attr]) {
            css.link = deltaAttrs[attr]
          } else {
            css.link = false
          }
        }

        if (attr === 'underline') {
          if (deltaAttrs[attr] && !css.textDecoration.includes('underline')) {
            css.textDecoration.push('underline')
          } else if (!deltaAttrs[attr] && css.textDecoration.includes('underline')){
            pull(css.textDecoration, 'underline')
          }
        }

        if (attr === 'strike') {
          if (deltaAttrs[attr] && !css.textDecoration.includes('line-through')) {
            css.textDecoration.push('line-through')
          } else if (!deltaAttrs[attr] && css.textDecoration.includes('line-through')) {
            pull(css.textDecoration, 'line-through')
          }
        }
      }

      return css
    },
    createNewElem: function (nameElem, insertAfterElem) {
      let elemLabel = lodash.cloneDeep(PatternElems[nameElem])
      elemLabel = this.elemStyleHelper.getStyleElem(elemLabel)
      return this.TDEScreateElem(elemLabel, this.indexElem, insertAfterElem)
    },
    initSelfRedactor() {
      if (!this.selfRedactor()) {
        return
      }

      let options = {
        // debug: 'info',
        modules: {
          toolbar:  ['bold', 'italic', 'underline', 'strike', 'link']
        },
        placeholder: this.$t('General.Form.NoSet'),
        // readOnly: true,
        theme: 'bubble'
      };

      this.quill = new Quill('#selfTextEditor', options)
      this.quill.setContents(this.convertToDelta())
      this.quill.on('editor-change', (action) => {
        if (action !== 'text-change') {
          return
        }
        this.quillUpdate()
      });

      let sidebar = document.querySelector('#sidebar-constructor > .b-sidebar-body')
      this.quill.on('selection-change', (range) => {
        if (!range || range.length === 0) {
          sidebar.classList.remove('overflow-y-visible')
          return
        }
        sidebar.classList.add('overflow-y-visible')
      });
    }
  },
  mounted() {
    this.elemStyleHelper = new ElemStyleHelper()
    let elems = this.getAllChildElem(this.indexElem, { tag: [null]})
    if (elems) {
      this.contentElemId = elems[0]
    }

    this.initSelfRedactor()

  }
}
</script>
<style scoped lang="sass">
  //@import "~quill/dist/quill.core.css"
  @import "~quill/dist/quill.bubble.css"


</style>
<style>
  #selfTextEditor {
    border: 1px solid #5664d2;
  }
</style>