export const ContainerHelperMethods = {
    containerClick() {
        if (!this.clickChangeActiveElem) { return }

        let elemBelow = this.checkExistElemUnderContainer()

        if (!elemBelow) {
            return false
        }

        document.getElementById('elemContTrue_' + elemBelow.dataset.id).click()
    },
    containerMove() {
        if (this.elemSingle || this.settingConstructor.drags.drag) {
            return
        }
        if (this.checkExistElemUnderContainer()) {
            this.$refs.elemContainer.classList.add('pointerCursor')
        } else {
            if (this.$refs.elemContainer.classList.contains('pointerCursor')) {
                this.$refs.elemContainer.classList.remove('pointerCursor')
            }
        }
    },
    checkExistElemUnderContainer() {
        if (this.hoverControlButton) {
            return false
        }
        this.$refs.elemContainer.hidden = true
        let elemBelow = document.elementFromPoint(event.clientX, event.clientY)
        this.$refs.elemContainer.hidden = false
        elemBelow = this.ignoreSvg(elemBelow)
        // console.log(elemBelow.tagName, elemBelow.parentNode, elemBelow.classList)
        if (!elemBelow.classList.contains('elemContTrue') || elemBelow.dataset.id === this.elem_id) {
            return false
        }
        return elemBelow
    },
    ignoreSvg(elem) {
        if (elem.tagName === 'path' || elem.tagName === 'g' || elem.tagName === 'svg') {
            return this.ignoreSvg(elem.parentNode)
        }
        return elem
    }
}
