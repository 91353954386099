<template>
    <b-input-group class="mb-1">
      <b-input-group-prepend v-if="noset" is-text>
        <b-form-checkbox
            switch
            class="mr-n2"
            v-model="noSetOption"
            v-b-tooltip.hover
            :title = "$t('Constructor.PropWidgets.SelectOptionsWidget.DefaultOptionTooltip')"
        >
          <span class="sr-only">Switch for following text input</span>
        </b-form-checkbox>
      </b-input-group-prepend>

      <b-input-group-prepend v-if="!newOptions" is-text>

        <input
            type="radio"
            name="selectOptions"
            value="1"
            v-model="option.selected"
            @click.stop="selectedOff"
            :disabled = "noset && !noSetOption"
            v-b-tooltip.hover
            :title="$t('Constructor.PropWidgets.SelectOptionsWidget.PointDefault')"
        >
      </b-input-group-prepend>

      <b-form-input
          :placeholder="$t('General.Form.NoSet')"
          v-model="option.text"
          type="text"
          name="qwe"
          :required="false"
          class="col-12 propField"
          :disabled = "noset && !noSetOption"
          v-b-tooltip.hover
          :title = "$t('Constructor.PropWidgets.SelectOptionsWidget.OptionValue')"
      >
      </b-form-input>

      <div v-if="!noset" class="input-group-append">
        <b-button
            v-if="newOptions"
            class=""
            variant="success"
            v-on:click.stop="addOptions"
            v-b-tooltip.hover
            :title="$t('Constructor.PropWidgets.SelectOptionsWidget.AddOption')"
        >
          <i class="fas fa-plus"></i>
        </b-button>
        <b-button
            v-if="!newOptions"
            class=""
            variant="danger"
            v-on:click.stop="deleteOptions"
            v-b-tooltip.hover
            :title="$t('Constructor.PropWidgets.SelectOptionsWidget.DeleteOption')"
        >
          <i class="fas fa-minus"></i>
        </b-button>
      </div>
    </b-input-group>
</template>

<script>
import HistoryHelper from '@/helpers/constructor/HistoryHelper'
import {cloneDeep} from "lodash";

export default {
  name: "options",
  props: ["option", "index", "newOptions", "elemCurrentProp", 'noset'],
  data: function() {
    return {

    }
  },
  computed: {
    noSetOption: {
      get: function() {
        for (let i in this.elemCurrentProp.selectOptions) {
          if (this.elemCurrentProp.selectOptions[i].val === null) {
            return true;
          }
        }
        return false
      },
      set: function(val) {
        if (val) {
          this.HistoryPush(this.elemCurrentProp, 'selectOptions', this.option)
          // this.elemCurrentProp.selectOptions.push(this.option)
        } else {
          let indexNull;
          for (let i in this.elemCurrentProp.selectOptions) {
            if (this.elemCurrentProp.selectOptions[i].val === null) {
              indexNull = i
              break
            }
          }
          this.HistoryDelete(this.elemCurrentProp.selectOptions, indexNull)
          // this.$delete(this.elemCurrentProp.selectOptions, indexNull)
        }
      }
    }
  },
  methods: {
    ...HistoryHelper,
    addOptions() {
      this.$emit('addOptions', cloneDeep(this.option))
    },
    deleteOptions() {
      this.$emit('deleteOptions', this.index)
    },
    selectedOff() {
      for (let i in this.elemCurrentProp.selectOptions) {
        this.HistorySet(this.elemCurrentProp.selectOptions[i], 'selected', "0")
      }
    }
  }
}
</script>

<style scoped>

</style>
