<template>
  <div :id="'lottie' + elem.id"></div>

<!--:src="elem.main.src"-->
<!--  mode="{{play_mode}}"-->
<!--  background="{{background_color}}"-->
<!--  speed="{{options_speed}}"-->
<!--  style="width: {{width}}; height: {{height}};"-->
<!--  hover-->
<!--  loop-->
<!--  controls-->
<!--  autoplay-->

</template>
<script>
import lottie from 'lottie-web'

export default {
  name: "lottiePlayer",
  props: ['elem'],
  mounted () {
    lottie.loadAnimation({
      container: document.getElementById('maintainer'), // Required
      // animationData: lottiejson,
      path: this.elem.main.src, // Required
      renderer: 'svg', // Required
      loop: true, // Optional
      autoplay: true // Optional
      // name: "Hello World", // Name for future reference. Optional.
    })
  }
}
</script>
