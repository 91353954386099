<template>
  <div>

<!--    <b-alert v-if="requestImage" variant="info" class="p-2 font-size-12" show>-->
<!--      {{$t('Constructor.Gallery.IconsGallery.AlertIcon')}}-->
<!--    </b-alert>-->

<!--    <b-row align-h="between">-->
<!--      <b-col>-->
<!--        <b-input-group size="sm" :prepend="$t('General.Base.Search')">-->
<!--          <b-form-input-->
<!--              v-model="query"-->
<!--              :placeholder="$t('Constructor.Gallery.IconsGallery.Placeholder')"-->
<!--              :title="$t('Constructor.Gallery.IconsGallery.Title')"-->
<!--              v-b-tooltip.hover.ds1000.dh0-->
<!--              debounce="600"-->
<!--          ></b-form-input>-->
<!--          <b-spinner v-if="queryLoading" class="spinnerInInput" small variant="primary" role="status"></b-spinner>-->
<!--        </b-input-group>-->
<!--      </b-col>-->
<!--    </b-row>-->

    <loading v-if="pixelMainImg === null"  />

    <div >
      <b-row class="pl-2">
        <b-col
            v-for="(tag, index) in tags"
            :key = index
            :active = "tag.tag === activeTag"
            active-class="secondary"
            @click="selectTag(tag)"
            cols="auto"
            class="ml-0 mr-0 pl-1 pr-1">
          <b-button size="sm" pill variant="primary">
            {{tag.tag}}
          </b-button>
        </b-col>
      </b-row>


      <b-row>
<!--        <b-col cols="3">-->
<!--          <b-list-group class="mt-2">-->
<!--            <b-list-group-item-->
<!--                v-for="(tag, index) in tags"-->
<!--                :key = index-->
<!--                class="border-top-0 border-left-0 border-right-0 p-1 pl-1 d-flex justify-content-between align-items-center font-size-11"-->
<!--                button-->
<!--                :active = "tag.tag === activeTag"-->
<!--                active-class="secondary"-->
<!--                @click="selectTag(tag)"-->
<!--            >-->
<!--              <span>-->
<!--                {{tag.tag}}-->
<!--              </span>-->
<!--              <b-badge variant="primary" class="pt-1" pill>{{ tag.count_icon }}</b-badge>-->
<!--            </b-list-group-item>-->
<!--          </b-list-group>-->
<!--        </b-col>-->
        <b-col cols="12">

          <div v-if="pixelMainImg !== null && !pixelMainImg.length">
            <h5 class="text-center mt-4">{{$t('General.Base.NothingFound')}}</h5>
          </div>

          <div v-if="pixelMainImg !== null && pixelMainImg.length" class="row mt-2">
            <div class="col-4 p-2 d-flex align-items-center flex-wrap "
                 v-for="(img, index) in pixelMainImg"
                 :key="index"
            >
              <b-img
                  fluid-grow
                  :src="img.thumb.min"
                  alt="Image 1">
              </b-img>

<!--              <div class="blockSelectImg text-right">-->
<!--                <b-button-->
<!--                    squared-->
<!--                    variant="light"-->
<!--                    size="sm"-->
<!--                    v-b-tooltip.hover.ds1000.dh0.manual-->
<!--                    :title="$t('Constructor.Gallery.PixelsGallery.SaveToGallery')"-->
<!--                >-->
<!--                  <i class="far fa-save"></i>-->
<!--                </b-button>-->
<!--              </div>-->

              <div  v-if="requestImage" class="blockSelectImg w-100 align-self-end">
                <b-button squared variant="light" class="w-100" size="sm" v-on:click.stop="setResponseImage(img)">{{$t('General.Base.Select')}}</b-button>
              </div>
            </div>

          </div>

          <b-pagination
              class="mt-2 ml-auto mr-auto"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
              limit="8"
          ></b-pagination>
        </b-col>
      </b-row>
    </div>

  </div>
</template>

<script>
import loading from "./_loading";
import axios from "axios";

export default {
  name: "imageGallery",
  components: { loading },
  // prop: [ 'requestImage' ],
  data() {
    return {
      pixelMainImg: null,
      query: '',
      queryLoading: false,
      // curated: [],
      currentPage: 1,
      totalRows: null,
      perPage: 99,
      tags: [],
      activeTag: null
    };
  },
  computed: {
    requestImage: function() {
      if (this.$store.state.userGallery.requestImage) {
        return true
      }
      return false
    },
    classIcon: function() {
      if (this.requestImage) {
        return 'iconSvg p-1 m-2 selectedIcon'
      }
      return 'iconSvg p-1 m-2'
    }
  },
  mounted() {
    this.getImages(true)
    this.getTags()
  },
  watch: {
    currentPage: function() {
      this.getImages()
    },
    query: function() {
      this.currentPage = 1
      this.getImages()
    },
    activeTag: function() {
      this.currentPage = 1
      this.getImages()
    }
  },
  methods: {
    getImages: function() {
      let request = {
        method: 'get',
        params: {
          query: this.query,
          tag: this.activeTag,
          limit: this.perPage,
          offset: this.currentPage - 1
        },
        url: 'https://icons.eefim.com/api/images'
      }

      return axios(request).then(data => {
        this.pixelMainImg = data.data.items
        // if (curated === true) { this.curated = data.data.icons }
        this.totalRows = data.data.totalRows

        // this.getSvg()
      })
    },
    getTags: function() {
      let request = 'https://icons.eefim.com/api/images/tags'
      return axios(request).then(data => {
        this.tags = data.data
      })
    },
    setResponseImage: function (img) {
      this.$emit('setResponseImage', {
        provider: 'images',
        imageId: img.id,
        url: img.files.source,
        urlProvider: img.files.source,
        urlThumb: img.thumb.min
      })
    },
    selectTag: function(tag) {
      if (this.activeTag === tag.tag) {
        this.activeTag = null
        return
      }
      this.activeTag = tag.tag
    }
    // getSvg: function () {
    //   for (let i in this.pixelMainImg) {
    //     axios(this.pixelMainImg[i].files.xml).then(data => {
    //       this.pixelMainImg[i].xml = data.data
    //     })
    //   }
    // }
  }
}
</script>

<style scoped>
  .iconSvg {
    height: 36px;
    width: 36px;
    border: 2px solid #f1f5f7;
    line-height: 16px;
  }
  .selectedIcon:hover {
    cursor: pointer;
    border-color: #000;
  }
</style>
