<template>
  <div class="mb-4">
    <h4 class="card-title">Тень текста</h4>
    <div
        v-for="(shadow, index) in this.elemCurrentProp.textShadow"
        :key="index"
    >
      <Shadow :shadowElem=shadow :index=index :new-shadow="false" @deleteShadow="deleteShadow"/>
    </div>

    <Shadow :shadowElem=newShadow :index=null :new-shadow="true" @addShadow="addShadow"/>
  </div>
</template>

<script>
import ShowProp from "@/helpers/constructor/sideBar/props/MethodShowProp";
import PropsHelper from "@/helpers/constructor/sideBar/props/PropsComputed";
import Shadow from "./_textShadow"
import HistoryHelper from '@/helpers/constructor/HistoryHelper'

export default {
  name: "TextShadowWidget",
  props: [ 'elem', 'prop', 'tagData', 'indexElem', 'settingConstructor' ],
  components: { Shadow },
  data: function() {
    return {
      blockProps: 'css',
      newShadow: {
          shiftX: {
            val: 0,
            unit: 'px'
          },
          shiftY: {
            val: 0,
            unit: 'px'
          },
          color: '#000',
          radius: {
            val: 0,
            unit: 'px'
          },
        }
    }
  },
  computed: {
    ...ShowProp,
    ...PropsHelper
  },
  methods: {
    ...HistoryHelper,
    addShadow(shadowElem) {
      if (this.elemCurrentProp.textShadow === undefined) {
        this.HistorySet(this.elemCurrentProp, 'textShadow', [shadowElem])
        // this.$set(this.elemCurrentProp, 'textShadow', [shadowElem])
      } else {
        this.HistoryPush(this.elemCurrentProp, 'textShadow', shadowElem)
        // this.elemCurrentProp.textShadow.push(shadowElem)
      }
    },
    deleteShadow(index) {
      if (this.elemCurrentProp.textShadow.length === 1) {
        this.HistoryDelete(this.elemCurrentProp, 'textShadow')
        // this.$delete(this.elemCurrentProp, 'textShadow')
        return
      }
      this.HistoryDelete(this.elemCurrentProp.textShadow, index)
      // this.$delete(this.elemCurrentProp.textShadow, index)
    }
  }
}
</script>

<style scoped>

</style>
