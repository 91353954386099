var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m-0 p-0 position-relative",attrs:{"id":"workspaceParent"}},[_c('ContainerRuler',{attrs:{"settingConstructor":_vm.settingConstructor,"constAreaLeft":_vm.settingConstructor.constructorArea.left,"constAreaTop":_vm.settingConstructor.constructorArea.top,"workspaceHeight":_vm.settingConstructor.workspace.height,"workspaceWidth":_vm.settingConstructor.workspace.width}}),_c('div',{ref:"workspace",class:_vm.settingConstructor.drags.activeElem ? 'workspaceActiveContainer' : '',style:({
                // width: settingConstructor.workspace.width + 'px' ,
                height: _vm.settingConstructor.workspace.height + 'px'
            }),attrs:{"id":"workspace"},on:{"mousedown":_vm.onMouseDown}},[_c('div',{ref:"constructorArea",style:({
                width: _vm.settingConstructor.constructorArea.width + _vm.settingConstructor.constructorArea.paddings * 2 + 'px' ,
                height: _vm.settingConstructor.constructorArea.height + _vm.settingConstructor.constructorArea.paddings * 2 + 'px',
                top: _vm.settingConstructor.constructorArea.top + 'px',
                left: _vm.settingConstructor.constructorArea.left + 'px',
                transform: 'scale('+ _vm.settingConstructor.constructorArea.scale +')',
                padding: _vm.settingConstructor.constructorArea.paddings + 'px'
              }),attrs:{"id":"constructorArea"}},[_c('div',{class:_vm.classConstructorArea,attrs:{"id":"constructorAreaReal"}},[(_vm.settingConstructor.drags.activeElem !== null)?_c('ElemContainer',{attrs:{"settingConstructor":_vm.settingConstructor,"tagData":_vm.tagData,"elem_id":_vm.settingConstructor.drags.activeElem,"elemSingle":null}}):_vm._e(),(_vm.settingConstructor.activeMainElemId !== null)?_c('Elem',{attrs:{"settingConstructor":_vm.settingConstructor,"tagData":_vm.tagData,"tree":_vm.tagData.htmlElementsTree[_vm.settingConstructor.activeMainElemId],"index":_vm.settingConstructor.activeMainElemId}}):_vm._e()],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }