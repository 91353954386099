<template>
<!--  <Layout>-->
    <div class="main-container">

      <TopBar :settingConstructor="settingConstructor" :tagData="tagData" :closeReturn="closeReturn" />

      <ListWindow :settingConstructor="settingConstructor" :tagData="tagData" />

      <SideBarLeft :sideBar="settingConstructor.sideBar" :tagData="tagData" :settingConstructor="settingConstructor" />
      <constructorArea :settingConstructor="settingConstructor" :tagData="tagData" />
      <SideBar :sideBar="settingConstructor.sideBar" :tagData="tagData" :settingConstructor="settingConstructor" />

      <div class="contructor-futter"></div>

      <Gallery />
      <WidgetsStore :settingConstructor="settingConstructor" :tagData="tagData" />
<!--      <StartMaster v-if="startMasterShow" :settingConstructor="settingConstructor" :tagData="tagData" />-->

<!--     <pre style="margin-left: 300px">-->
<!--      {{tagData}}-->
<!--      {{settingConstructor}}-->

<!--      </pre>-->
    </div>
</template>
<script>
  // import Layout from "../../layouts/main";
  // import PageHeader from "@/components/page-header";
  import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
  import SideBar from "./sideBar/_sideBar"
  import SideBarLeft from "./sideBarLeft/_sideBarLeft"
  import TagDataServerHelper from '@/helpers/constructor/TagDataServerHelper'
  // import ControlBar from "./controlBar/_controlBar"
  import TagDataEditService from '@/helpers/constructor/TagDataEditService'
  import lodash from 'lodash'
  import constructorArea from './constructorArea/_constructorArea'
  import Gallery from './gallery/Gallery'
  import TopBar from "./controlBar/_topBar";
  import ListWindow from "./controlBar/_listWindow";
  import HistoryHelper from '@/helpers/constructor/HistoryHelper'
  // import StartMaster from './startMaster/_startMaster'
  import WidgetsStore from './widgetsStore/_widgetsStore.vue'

  document.body.setAttribute("data-keep-enlarged","true");
  document.body.classList.add("vertical-collapsed");

  // import 'swiper/swiper-bundle.min.css';
  // import 'swiper/swiper-element-bundle.min.css';

  // import { Navigation, Pagination, Autoplay } from 'swiper';
  // import { register } from 'swiper/element/swiper-element-bundle';
  // register();



  export default {
    page: {
      title: "Constructor",
      meta: [{ name: "Constructor description" }],
    },
    components: {
      SideBar, SideBarLeft, constructorArea, Gallery, TopBar, ListWindow, WidgetsStore // Elem //Elements Layout StartMaster ControlBar
    },
    props: ['closeReturn'],
    data: function () {
      return {
        startMasterShow: true,
        tag:{},
        constructorElemSettingActive: false,
        settingConstructor: {
          tagMain: null,
          activeMainElemId: null,
          hoverElem: null,
          desktopMode: 'default',
          // desktopModeEditing: 'default',
          js: { // edit setting
            jsBlockActive: {} // elemId: { jsBlockId: jsBlockId }
          },
          cssEditor: {
            editAnotherElem: null
          },
          cssPseudoClasses:{
            hover: false
          },
          desktops: {
            desktop: {
              width: 'default'
            },
            laptop: {
              width: 992
            },
            tablet: {
              width: 768
            },
            mobile: {
              width: 576
            },
            mobile2: {
              width: 375
            }
          },
          theme: false,
          showBordersElem: true,
          workspace: {
            width: 810,
            height: 820,
          },
          constructorArea: {
            paddings:100,
            width: 1920,
            height: 1000,
            scale: 1,
            top: -300,
            left: -400,
            right: null,
            bottom: null,
            ruler: {
              size: 4000,
              difAreaOneSide: {
                width: 0,
                height: 0
              },
              zeroShift: {
                x: 0,
                y: 0
              }
            }
          },
          drags: {
            drag: false,
            startCoordinates: {},
            shift: {},
            activeElem: null
          },
          activeParent: 0,
          sideBar: {
            prop: false,
            elem: true,
            withSideBarStock:'17%',
            withSideBarBig: '60%',
            withSideBar: '17%'
          },
          widgetsStore: {
            show: false
          },
          historySave: {
            history: [],
            cancelHistory: [],
            state: {
              writing: false,
              elemName: null,
              timer: null,
              mktime: 0
            }
          },
          startVersion: 0
        },
        tagData: {}
      }
    },
    computed: {
      // constructorElemActive: {
      //   get: function() {
      //     return this.constructorElemSettingActive
      //   },
      //   set: function(newValue) {
      //     for (let index in this.tagData.htmlElements) {
      //       this.$set(this.tagData.htmlElements[index], 'active', newValue)
      //     }
      //     this.constructorElemSettingActive = newValue
      //   }
      // }
    },
    methods: {
      ...TagDataEditService,
      ...HistoryHelper,
      clickOutside() {
        const onClickOutside = e => {
          if (this.settingConstructor.drags.activeElem === null) {
            return
          }

          //@TODO: фикс, проблема с multiselect, не сравбатывает условие ниже
          let cancelClasses = ['multiselect__single', 'multiselect__placeholder', 'swal2-styled']
          for (let nameClass of cancelClasses) {
            if (e.target.classList.contains(nameClass)) {
              return;
            }
            if (e.target.parentNode && e.target.parentNode.classList.contains(nameClass)) {
              return;
            }
          }

          // console.log(e.target.matches('#sidebar-constructor'))
          let elements = document.querySelectorAll('#sidebar-constructor, #constructorArea .elemContainer, .elemContainer, ' +
              '#constructorSideBarProps, #sidebar-gallery, .he-tree, .tooltip, .elemContTrue, .ignoreReset, .swal2-loader, #changerSizeWorkArea')
          let flag = true
          for (let elem of elements) {
            if (elem.contains(e.target)) {
              flag = false
              break;
            }
          }

          if (flag) {
            this.TDESElemDeActive()
            for (let elemId in this.settingConstructor.js.jsBlockActive) {
              this.$delete(this.settingConstructor.js.jsBlockActive, elemId)
            }
            this.$root.$emit('globalCloseAll');
          }
        }
        document.addEventListener('mousedown', onClickOutside);
        this.$on('hook:beforeDestroy', () => document.removeEventListener('mousedown', onClickOutside));
      },
      // isDescendant(parent, child) {
      //   var node = child.parentNode;
      //   console.log(node)
      //   while (node != null) {
      //
      //     if (node == parent) {
      //       return true;
      //     }
      //     node = node.parentNode;
      //     console.log(node)
      //   }
      //   return false;
      // }
    },
    // watch: {
    //   tagData: function () {
    //     console.log(this.tagData.version)
    //     this.tagData.version++
    //     if (this.tagData.version % 10) {
    //       TagDataServerHelper.saveTag(this.tagData)
    //     }
    //   }
    // },
    mounted() {
      if (this.$route.params.id !== undefined) {
        this.tag = { id: parseInt(this.$route.params.id) }
      }

      // console.log(this.$route.params)
      // if (this.$route.params.template !== undefined) {
      //   console.log(this.$route.params.template)
      // }

      if (this.tag) {
        TagDataServerHelper.getTag(this.tag.id).then(data => {
          this.settingConstructor.tagMain = data.tag
          this.tagData = data.tagData
          this.settingConstructor.activeMainElemId = Object.keys(this.tagData.htmlElementsTree)[0]
          this.settingConstructor.startVersion = lodash.clone(data.tagData.version)


        }, error => {
          if (error.response.status === 404) {
            this.$router.push('/error-404')
          }
        })
      }
      // this.calculateCoordConstr()
      this.clickOutside()
      this.HistoryInit()
    },
    // updated() {
    //   console.log('update const')
    //   this.clickOutside()
    //
    // }
  }

</script>
