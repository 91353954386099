<template>
  <div>
    <b-form-group
        :label-cols-sm="4"
        :label = "$t('Constructor.PropWidgets.BoxShadow.ShadowShift')"
        class="pt-0 pb-0 mb-0"
    >
      <b-input-group size="sm" class="col-12 pl-0 pr-0 " append="px">
        <b-input-group-prepend is-text>{{$t('Constructor.PropWidgets.BoxShadow.X')}}</b-input-group-prepend>
        <b-form-input
            size="sm"
            :placeholder="$t('General.Base.Auto')"
            v-model="shadowElem.shiftX.val"
            type="number"
            class=" propField"
            @change="shadowElem.shiftX.val === '' ? shadowElem.shiftX.val = 0 : null"
        ></b-form-input>
        <b-input-group-prepend class="border-left-0" is-text>Y</b-input-group-prepend>
        <b-form-input
            :placeholder="$t('General.Base.Auto')"
            v-model="shadowElem.shiftY.val"
            type="number"
            @change="shadowElem.shiftY.val === '' ? shadowElem.shiftY.val = 0 : null"
        ></b-form-input>
      </b-input-group>
    </b-form-group>

    <b-form-group
        :label-cols-sm="4"
        :label = "$t('Constructor.PropWidgets.BoxShadow.Blur')"
        class="pt-0 pb-0 mb-0"
    >
      <b-input-group size="sm" class="col-12 pl-0 pr-0 " append="px">
        <b-form-input
            :placeholder="$t('General.Base.Auto')"
            v-model="shadowElem.blur.val"
            type="number"
            class=" propField"
            :id="'shadowblur' + index"
            @change="shadowElem.blur.val === '' ? shadowElem.blur.val = 0 : null"
        ></b-form-input>
      </b-input-group>
    </b-form-group>

    <b-form-group
        :label-cols-sm="4"
        :label="$t('Constructor.PropWidgets.BoxShadow.Stretching')"
        class="pt-0 pb-0"
    >
      <b-input-group size="sm" class="col-12 pl-0 pr-0" append="px">
        <b-form-input
            :placeholder="$t('General.Base.Auto')"
            v-model="shadowElem.stretching.val"
            type="number"
            class="propField"
            @change="shadowElem.stretching.val === '' ? shadowElem.stretching.val = 0 : null"
        ></b-form-input>
      </b-input-group>
    </b-form-group>

    <b-row class="ml-0 mr-0">
      <b-col cols="3" class="p-0 font-size-11">
        {{$t('Constructor.PropWidgets.BoxShadow.ShadeColor')}}
      </b-col>
      <b-col cols="1" class="pl-1">
        <ColorWidget v-model="shadowElem.color" />
      </b-col>
      <b-col cols="8" class="p-0 custom-control custom-checkbox custom-control-right text-right d-flex justify-content-end align-items-center">
        <input type="checkbox" class="custom-control-input " v-model="shadowElem.inset" :id="'inset' + index" />
        <label class="custom-control-label mr-4 font-size-11" :for="'inset' + index">
          {{$t('Constructor.PropWidgets.BoxShadow.Inset')}}
        </label>
      </b-col>
    </b-row>

    <div v-if="!newShadow" class="mt-3 text-right">
      <b-button size="sm" variant="outline-danger" v-on:click.stop="deleteShadow">{{$t('General.Base.Delete')}}</b-button>
    </div>
    <div v-if="newShadow" class="mt-3 text-right">
<!--      <span class="mr-4">Можно добавить несколько теней</span>-->
      <b-button size="sm" variant="outline-success" v-on:click.stop="addShadow">
        {{$t('Constructor.PropWidgets.BoxShadow.AddShadow')}}
      </b-button>
    </div>
    <hr>

  </div>
</template>

<script>
import lodash from "lodash";
import ColorWidget from "./../ColorWidget";

export default {
  name: "boxShadow",
  props: [ 'shadowElem', 'newShadow', 'index'],
  components: { ColorWidget },
  methods: {
    addShadow() {
      this.$emit('addShadow', lodash.cloneDeep(this.shadowElem))
      this.shadowElem.shiftX.val = 0
      this.shadowElem.shiftY.val = 0
      this.shadowElem.color = '#000'
      this.shadowElem.inset = false
      this.shadowElem.blur.val = 0
      this.shadowElem.stretching.val = 0
    },
    deleteShadow() {
      this.$emit('deleteShadow', this.index)
    },
    getTooltipLabel() {
      return this.$t('Constructor.Props.' + this.blockProps + '.' + this.prop.name + '.labelTooltip') ?
          this.$t('Constructor.Props.' + this.blockProps + '.' + this.prop.name + '.labelTooltip') : null
    }
  }
}
</script>

<style scoped>

</style>
