<template>
  <b-sidebar
      id="sidebar-constructor2"
      title=""
      width="15%"
      left
      bg-variant="white"
      visible
      no-close-on-backdrop
      no-close-on-esc
      no-header
      no-header-close
      body-class="pt-2"
  >
    <elemsTree :tagData="tagData" :settingConstructor="settingConstructor" />
  </b-sidebar>
</template>

<script>
import elemsTree from './_elemsTree'

export default {
  name: "sideBarLeft",
  props: ['tagData', 'settingConstructor'],
  components: { elemsTree }
}
</script>

<style scoped>

</style>
