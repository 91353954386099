<template>
  <div v-html="elem.main.svg"
       @click="$emit('click')"
       @mousedown="$emit('mousedown')"
       @mouseenter="$emit('mouseenter')"
  ></div>
</template>
<script>
export default {
  name: "svgElem",
  props: ['elem'],
  // functional: true,
}
</script>
