var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('popper',{attrs:{"trigger":"clickToOpen","options":{
          placement: 'left',
          modifiers: {
            offset: {
              offset: '0,10px'
            }
          }
      }}},[_c('div',{staticClass:"popperColor bg-white"},[_c('sketch-picker',{model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}}),_c('div',{staticClass:"colorsBlock"},_vm._l((_vm.colorsPattern),function(colorPattern,index){return _c('div',{key:index,class:_vm.checkColorOnTransparent(colorPattern),style:({'background-color': colorPattern}),on:{"click":function($event){$event.stopPropagation();_vm.color = colorPattern}}})}),0)],1),_c('div',{class:'colorPick ' + _vm.checkColorOnTransparent(_vm.color),style:({'background-color': _vm.color}),attrs:{"slot":"reference"},slot:"reference"})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }