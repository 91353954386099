<template>
  <div>
    <h4 class="card-title" v-b-tooltip.viewport.hover.ds500.dh0.html.left ="$t('Constructor.PropWidgets.TargetMainDivWidget.Desc')">
      {{$t('Constructor.PropWidgets.TargetMainDivWidget.Title')}}
      <i class="far fa-question-circle mr-2"></i>
    </h4>

<!--    <b-form-text class="mb-2" v-html="$t('Constructor.PropWidgets.TargetMainDivWidget.Desc')">-->
<!--    </b-form-text>-->
    <b-form-group
        label-cols-sm="4"
        :label = "$t('Constructor.PropWidgets.TargetMainDivWidget.CssSelector')"
        label-for="main-target-css"
    >

      <b-input-group >
        <b-form-textarea
            :placeholder="$t('Constructor.PropWidgets.TargetMainDivWidget.Placeholder') + prop.default"
            v-model="elemCurrentProp.target.cssSelector"
            id="main-target-css"
            v-b-tooltip.hover
            :title="prop.tooltip"
            class="propField"
            @change="elemCurrentProp.target.cssSelector === '' ? elemCurrentProp.target.cssSelector = prop.default : null"
            maxlength="250"
        ></b-form-textarea>
      </b-input-group>

<!--      <b-form-input-->
<!--          placeholder="No set"-->
<!--          type="text"-->
<!--          v-model="elemCurrentProp.target.cssSelector"-->
<!--          v-b-tooltip.hover-->
<!--          title="123"-->
<!--          class="col-4"-->
<!--          id="main-target-css"-->
<!--      ></b-form-input>-->

    </b-form-group>

    <div class="d-flex justify-content-end align-items-center mt-1 mb-2">
<!--      <b-form-text class="small">-->
<!--        {{$t('Constructor.PropWidgets.TargetMainDivWidget.DescButton')}}-->
<!--        <i class="fas  fa-arrow-right"></i>-->
<!--      </b-form-text>-->
      <b-button
          size="sm"
          variant="dark"
          v-on:click.stop="previewTag"
          v-b-tooltip.viewport.hover.ds500.dh0.html.left="$t('Constructor.PropWidgets.TargetMainDivWidget.DescButton')"
          class="font-size-11"
      >
        {{$t('Constructor.PropWidgets.TargetMainDivWidget.SelectSelector')}}
        <i class="fas fa-arrow-right ml-1"></i>
      </b-button>
    </div>

    <b-form-group
        label-for="main-target-css"
        label-cols-sm="4"
    >
      <template v-slot:label>
        <span
            v-b-tooltip.viewport.hover.ds500.dh0.html.left="$t('Constructor.PropWidgets.TargetMainDivWidget.SelectorFieldDesc')"
        >
          {{$t('Constructor.PropWidgets.TargetMainDivWidget.InsertType') }}
<!--          <i class="far fa-question-circle mr-2"></i>-->
        </span>
      </template>

      <b-form-select
          class="col-12"
          :options="targetOptions"
          v-model="elemCurrentProp.target.typeRender"
      ></b-form-select>

<!--      <b-form-text>-->
<!--        {{$t('Constructor.PropWidgets.TargetMainDivWidget.SelectorFieldDesc')}}-->
<!--      </b-form-text>-->
    </b-form-group>
  </div>
</template>

<script>
import ShowProp from "@/helpers/constructor/sideBar/props/MethodShowProp";
import PropsHelper from "@/helpers/constructor/sideBar/props/PropsComputed";
// import TagDataServerHelper from "@/helpers/constructor/TagDataServerHelper";
import TagDataPreviewHelper from "@/helpers/constructor/TagDataPreviewHelper";
import HistoryHelper from "@/helpers/constructor/HistoryHelper";

export default {
  name: "TargetMainDivWidget",
  props: [ 'elem', 'prop', 'tagData', 'indexElem', 'settingConstructor' ],
  data: function() {
    return {
      blockProps: 'main',
      targetOptions: [
        {
          value: 'beforeend',
          text: this.$t('Constructor.PropWidgets.TargetMainDivWidget.InsertTypes.beforeend')
        },
        {
          value: 'afterbegin',
          text: this.$t('Constructor.PropWidgets.TargetMainDivWidget.InsertTypes.afterbegin')
        },
        {
          value: 'beforebegin',
          text: this.$t('Constructor.PropWidgets.TargetMainDivWidget.InsertTypes.beforebegin')
        },
        {
          value: 'afterend',
          text: this.$t('Constructor.PropWidgets.TargetMainDivWidget.InsertTypes.afterend')
        },
        {
          value: 'replaceTag',
          text: this.$t('Constructor.PropWidgets.TargetMainDivWidget.InsertTypes.replaceTag')
        },
        {
          value: 'replaceContentTag',
          text: this.$t('Constructor.PropWidgets.TargetMainDivWidget.InsertTypes.replaceContentTag')
        }
      ]
    }
  },
  computed: {
    ...ShowProp,
    ...PropsHelper
  },
  methods: {
    ...HistoryHelper,
    previewTag() {
      // TagDataServerHelper.saveTag(this.tagData, this.settingConstructor)
      TagDataPreviewHelper.getCssSelector(this.tagData, this.settingConstructor, (data) => {
        this.HistorySet(this.elemCurrentProp.target, 'cssSelector', data.cssSelector)
        // this.elemCurrentProp.target.cssSelector = data.cssSelector
      })
    }
  }
}
</script>

<style scoped>

</style>
