import { render, staticRenderFns } from "./_elemContainer.vue?vue&type=template&id=bbb2362c&scoped=true&"
import script from "./_elemContainer.vue?vue&type=script&lang=js&"
export * from "./_elemContainer.vue?vue&type=script&lang=js&"
import style0 from "./_elemContainer.vue?vue&type=style&index=0&id=bbb2362c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbb2362c",
  null
  
)

export default component.exports