<template>
  <div>
    <h4 class="card-title">SVG иконка</h4>
    <div class="row mb-2">
      <div v-if="elem.main.svg !== null" class="col-3">
        <div v-html="elem.main.svg"></div>
      </div>
      <div class="col-8">
        <b-button v-b-toggle.sidebar-gallery variant="outline-primary" size="sm" v-on:click.stop="selectImage">Выбрать
          иконку
        </b-button>
        <b-button v-if="elem.main.svg !== null" variant="outline-secondary" class="ml-2" size="sm"
                  v-on:click.stop="deleteImage">Сбросить
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import HistoryHelper from '@/helpers/constructor/HistoryHelper'

export default {
  name: "SvgIconWidget",
  props: [ 'elem', 'prop', 'tagData', 'indexElem', 'settingConstructor', 'nameProp' ],
  data: function () {
    return {
      requestImage: false
    }
  },
  computed: {
    storeRequestImage: function () {
      return this.$store.state.userGallery.requestImage
    },
    responseImage: function () {
      if (!this.requestImage) {
        return null
      }

      return this.$store.state.userGallery.responseImage
    }
  },
  watch: {
    storeRequestImage: function (requestImage) {
      if (!requestImage && this.requestImage) {
        this.requestImage = false
      }
    },
    responseImage: function (val) {
      if (!this.requestImage) {
        return
      }

      this.HistorySet(this.elem.main, 'svg', val.svg)


      this.$store.commit('userGallery/setRequestImage', false)
      this.$store.commit('userGallery/setResponseImage', null)
    }
  },
  methods: {
    ...HistoryHelper,
    selectImage() {
      this.$store.commit('userGallery/setRequestImage', 'svg')
      this.requestImage = true
    },
    deleteImage() {
      this.$emit('change', null)
      this.$emit('input', null)
    }
  }
}
</script>

<style scoped>

</style>
