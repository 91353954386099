<template>
  <div class="mb-2">

<b-row class="mr-0 ml-0">
  <b-col cols="4" class="pr-0 d-flex pl-0">
    <div class="border border-dark border-radius-default position-relative demoBlock m-auto">
      <i ref="borderRadiusDemoAngle2" class="mdi mdi-vector-radius text-secondary topRight " ></i>
      <i ref="borderRadiusDemoAngle1" class="mdi mdi-vector-radius text-secondary topLeft fa-rotate-270"></i>
      <i ref="borderRadiusDemoAngle3" class="mdi mdi-vector-radius text-secondary bottomLeft fa-rotate-180"></i>
      <i ref="borderRadiusDemoAngle4" class="mdi mdi-vector-radius text-secondary bottomRight fa-rotate-90"></i>
    </div>
  </b-col>
  <b-col cols="8" class="pr-0">
    <div>
      <b-form-group class="mb-2">
        <b-input-group
            :class = "valueForAngleAll === null ? 'selfAngle' : ''"
            size="sm"
            @mouseenter="selectAngle(5)"
            @mouseleave="selectAngle(0)"
            prepend-html="<div class='iconBorderRadius'></div>"
        >

          <b-form-input
              type="number"
              class="propField mousetrap"
              maxlength = "11"
              v-model="valueForAngleAll"
          >
          </b-form-input>

<!--          <b-form-spinbutton-->
<!--              v-model="valueForAngleAll"-->
<!--              min="0"-->
<!--              max="1000"-->
<!--          ></b-form-spinbutton>-->

<!--          <b-form-input-->
<!--              variant="dark"-->
<!--              type="range"-->
<!--              min="0"-->
<!--              max="20"-->
<!--              v-model="valueForAngleAll"-->
<!--          ></b-form-input>-->
          <multiselect
              track-by="code"
              label="name"
              v-model = "valueForAngleAllUnit"
              :options="prop.unit"
              class="mousetrap multiselect-sm form-control"
              :show-labels="false"
              :searchable="false"
              placeholder = "--"
          >
          </multiselect>
        </b-input-group>
      </b-form-group>
    </div>
    <div class="d-flex" >
      <b-form-group :class="'mb-1 mr-1 ' + getClass">
        <b-input-group size="sm" @mouseenter="selectAngle(1)" @mouseleave="selectAngle(0)" prepend-html="<i class='mdi mdi-vector-radius fa-rotate-270'></i>">

          <b-form-input
              type="number"
              class="propField mousetrap"
              maxlength = "11"
              v-model="valueForAngle1"
          >
          </b-form-input>

          <multiselect
              track-by="code"
              label="name"
              v-model = "valueForAngleUnit1"
              :options="prop.unit"
              class="mousetrap multiselect-sm form-control"
              :show-labels="false"
              :searchable="false"
          >
          </multiselect>
        </b-input-group>
      </b-form-group>

      <b-form-group :class="'mb-1 ' + getClass">
        <b-input-group size="sm" @mouseenter="selectAngle(2)" @mouseleave="selectAngle(0)" prepend-html="<i class='mdi mdi-vector-radius '></i>">

          <b-form-input
              type="number"
              class="propField mousetrap"
              maxlength = "11"
              v-model="valueForAngle2"
          >
          </b-form-input>

          <multiselect
              track-by="code"
              label="name"
              v-model = "valueForAngleUnit2"
              :options="prop.unit"
              class="mousetrap multiselect-sm form-control"
              :show-labels="false"
              :searchable="false"
          >
          </multiselect>
        </b-input-group>
      </b-form-group>
    </div>
    <div class="d-flex" >
      <b-form-group  :class="'mb-0 mr-1 ' +  getClass  ">
        <b-input-group size="sm" @mouseenter="selectAngle(3)" @mouseleave="selectAngle(0)" prepend-html="<i class='mdi mdi-vector-radius fa-rotate-180'></i>">

          <b-form-input
              type="number"
              class="propField mousetrap"
              maxlength = "11"
              v-model="valueForAngle3"
          >
          </b-form-input>

          <multiselect
              track-by="code"
              label="name"
              v-model = "valueForAngleUnit3"
              :options="prop.unit"
              class="mousetrap multiselect-sm form-control"
              :show-labels="false"
              :searchable="false"
          >
          </multiselect>
        </b-input-group>
      </b-form-group>

      <b-form-group :class="'mb-0 ' + getClass">
        <b-input-group size="sm" @mouseenter="selectAngle(4)" @mouseleave="selectAngle(0)" prepend-html="<i class='mdi mdi-vector-radius fa-rotate-90'></i>">

          <b-form-input
              type="number"
              class="propField mousetrap"
              maxlength = "11"
              v-model="valueForAngle4"
          >
          </b-form-input>

          <multiselect
              track-by="code"
              label="name"
              v-model = "valueForAngleUnit4"
              :options="prop.unit"
              class="mousetrap multiselect-sm form-control"
              :show-labels="false"
              :searchable="false"
          >
          </multiselect>
        </b-input-group>
      </b-form-group>
    </div>
  </b-col>
</b-row>


  </div>
</template>

<script>
import PropsHelper from "@/helpers/constructor/sideBar/props/PropsComputed";
import HistoryHelper from '@/helpers/constructor/HistoryHelper'
import Multiselect from "vue-multiselect";
import MethodsForChangePropsElem from "@/helpers/constructor/MethodsForChangePropsElem";

export default {
  name: "CssTransformWidget",
  components: { Multiselect },
  props: ['nameProp', 'prop', 'elem', 'tagData', 'indexElem', 'settingConstructor'],
  data: function(){
    return {
      blockProps: 'css'
    }
  },
  computed: {
    ...PropsHelper,
    // classForDemoBlock: function () {
    //   return {
    //     'borderTopLeftRadius':      this.valueForAngle1 + this.valueForAngleUnit1.code,
    //     'borderTopRightRadius':     this.valueForAngle2 + this.valueForAngleUnit2.code,
    //     'borderBottomLeftRadius':   this.valueForAngle3 + this.valueForAngleUnit3.code,
    //     'borderBottomRightRadius':  this.valueForAngle4 + this.valueForAngleUnit4.code
    //   }
    //
    // },
    getClass: function () {
      return this.valueForAngleAll !== null ? 'selfAngle' : ''
    },
    valueForAngleAll: {
      get: function () {
        if (
            ( this.valueForAngle1 !== null ) &&
            ( this.valueForAngle1 === this.valueForAngle2 ) &&
            ( this.valueForAngle2 === this.valueForAngle3 ) &&
            ( this.valueForAngle3 === this.valueForAngle4 )
        ) {
          return this.valueForAngle1
        }
        return null
      },
      set: function (value) {
        if (value === null) {
          return
        }
        this.valueForAngle1 = value
        this.valueForAngle2 = value
        this.valueForAngle3 = value
        this.valueForAngle4 = value
      }
    },
    valueForAngleAllUnit: {
      get: function () {
        if (
            ( this.valueForAngleUnit1 !== null ) &&
            ( this.valueForAngleUnit1.code === this.valueForAngleUnit2.code ) &&
            ( this.valueForAngleUnit2.code === this.valueForAngleUnit3.code ) &&
            ( this.valueForAngleUnit3.code === this.valueForAngleUnit4.code )
        ) {
          return this.prepareDataForMultiselect(this.getValPropNumbUnit('borderTopLeftRadius'))
        }
        return null
      },
      set: function (value) {
        this.valueForAngleUnit1 = value
        this.valueForAngleUnit2 = value
        this.valueForAngleUnit3 = value
        this.valueForAngleUnit4 = value
      }
    },

    valueForAngle1: {
      get: function () {
        return this.getValPropNumb('borderTopLeftRadius')
      },
      set: function (value) {
        this.setValPropNumb(value, 'borderTopLeftRadius')
      }
    },
    valueForAngleUnit1: {
      get: function () {
        return this.prepareDataForMultiselect(this.getValPropNumbUnit('borderTopLeftRadius'))
      },
      set: function (value) {
        this.setValPropNumbUnit(value.code, 'borderTopLeftRadius')
      }
    },

    valueForAngle2: {
      get: function () {
        return this.getValPropNumb('borderTopRightRadius')
      },
      set: function (value) {
        this.setValPropNumb(value, 'borderTopRightRadius')
      }
    },
    valueForAngleUnit2: {
      get: function () {
        return this.prepareDataForMultiselect(this.getValPropNumbUnit('borderTopRightRadius'))
      },
      set: function (value) {
        this.setValPropNumbUnit(value.code, 'borderTopRightRadius')
      }
    },

    valueForAngle3: {
      get: function () {
        return this.getValPropNumb('borderBottomLeftRadius')
      },
      set: function (value) {
        this.setValPropNumb(value, 'borderBottomLeftRadius')
      }
    },
    valueForAngleUnit3: {
      get: function () {
        return this.prepareDataForMultiselect(this.getValPropNumbUnit('borderBottomLeftRadius'))
      },
      set: function (value) {
        this.setValPropNumbUnit(value.code, 'borderBottomLeftRadius')
      }
    },

    valueForAngle4: {
      get: function () {
        return this.getValPropNumb('borderBottomRightRadius')
      },
      set: function (value) {
        this.setValPropNumb(value, 'borderBottomRightRadius')
      }
    },
    valueForAngleUnit4: {
      get: function () {
        return this.prepareDataForMultiselect(this.getValPropNumbUnit('borderBottomRightRadius'))
      },
      set: function (value) {
        this.setValPropNumbUnit(value.code, 'borderBottomRightRadius')
      }
    }
  },
  methods: {
    ...HistoryHelper,
    ...MethodsForChangePropsElem,
    selectAngle: function (numb) {
      if (numb === 0) {
        this.$refs.borderRadiusDemoAngle1.classList.remove('angleActive')
        this.$refs.borderRadiusDemoAngle2.classList.remove('angleActive')
        this.$refs.borderRadiusDemoAngle3.classList.remove('angleActive')
        this.$refs.borderRadiusDemoAngle4.classList.remove('angleActive')
        return
      }
      if (numb < 5) {
        this.$refs['borderRadiusDemoAngle' + numb ].classList.add('angleActive')
      } else if (numb === 5) {
        this.$refs.borderRadiusDemoAngle1.classList.add('angleActive')
        this.$refs.borderRadiusDemoAngle2.classList.add('angleActive')
        this.$refs.borderRadiusDemoAngle3.classList.add('angleActive')
        this.$refs.borderRadiusDemoAngle4.classList.add('angleActive')
      }
    }
  }
}
</script>

<style scoped>
  .containerBorderRadius input, .borderRadiusBlock input{
    width: 50px;
  }
  .demoBlock {
    width: 60px;
    height: 60px;
  }
  .topRight {
    position: absolute;
    right: 5px;
    top: -1px;
  }
  .topLeft {
    position: absolute;
    left: 5px;
    top: -1px;
  }
  .bottomLeft {
    position: absolute;
    bottom: -1px;
    left: 5px;
  }
  .bottomRight {
    position: absolute;
    bottom: -1px;
    right: 5px;
  }

</style>
