<script>
import mainDiv from './mainDiv'
import {computedElemHelper} from "@/helpers/constructor/constructor/ElemHelper";
import {computedStyleElem, methodsStyleElem} from "@/helpers/constructor/constructor/StyleElemHelper";
import TagDataEditService from "@/helpers/constructor/TagDataEditService";
import RenderElemStyleContainerHelper from "@/helpers/constructor/constructor/RenderElemStyleContainerHelper";
import selectCustom from "./selectCustom"
import svgElem from "./svgElem"
import lottiePlayer from "./lottiePlayer"
// import swiperContainer from './swiperContainer'
// import swiperSlide from './swiperSlide'
import {PatternElemsSettings} from "@/helpers/constructor/patterns/PatternElemsSettings";
// const { h, resolveComponent } = Vue

export default {
  name: "elem",
  props: ['tagData', 'tree', 'index', 'elemSingle', 'settingConstructor'],
  components: { mainDiv, selectCustom, svgElem, lottiePlayer }, //swiperSlide swiperContainer
  data: function () {
    return {
      // resizeService: new ResizeElemHelper(this),
      RenderElemStyleContainerHelper: null,
      drag: false,
      dragSettings: {
        startCoordinates: {}
      },
      active: false,
      factParam: {
        width: null,
        height: null,
        x: null,
        y: null
      },
      elemClass: [],
      durationTimeClick: 0
      // computed: {}
    }
  },
  computed: {
    ...computedElemHelper,
    ...computedStyleElem,
    // elemMainContent: function() {
    //   if (this.settingConstructor.js.jsBlockActive[this.index] === undefined ||
    //       this.elem.jsBlocks[this.settingConstructor.js.jsBlockActive[this.index].jsBlockId].main.content === undefined
    //   ) {
    //     return this.elem.main.content
    //   }
    //   return this.elem.jsBlocks[this.settingConstructor.js.jsBlockActive[this.index].jsBlockId].main.content
    // },
    classElemTrue: function() {
      let elemClass =  [
          'elemContTrue',
          'cl' + this.elem.type,
          ...this.elem.devBlock.classBlockTrue
      ]
      if (this.settingConstructor.hoverElem === this.elem.id ) {
        elemClass.push('elemContTrueHover')
      }
      return elemClass
    },
    elem: function() {
      if (this.elemSingle) {
        return this.elemSingle
      }
      return this.tagData.htmlElements[this.index]
    },
  },
  methods: {
    ...TagDataEditService,
    // ...ConstElemInitScripts,
    ...methodsStyleElem,
    onElemMouseDown() {
      this.durationTimeClick = parseInt(new Date().getTime())
    },
    // onElemMouseUp() {
    //   if (parseInt(new Date().getTime()) - this.durationTimeClick < 1000) {
    //     this.onSelectElem()
    //   }
    // },
    onSelectElem() {
      if (this.elem.active) {
        return
      }
      if (this.elemSingle) {
        return
      }

      if (this.elem.type === 'selfText') {
        return
      }
// console.log(this.durationTimeClick)
      if (this.durationTimeClick !==0 && parseInt(new Date().getTime()) - this.durationTimeClick > 200) {
        return
      }

      //@TODO: добавить логику для возможности активации элементов с ссылкой на родителя

      this.TDESElemActive(this.index)
      // let elemId;

      // if (this.elem.selectParent === true) {
      //   elemId = this.searchParentAccessSelect(this.index)
      //   this.tagData.htmlElements[elemId].active = true
      // } else {
      //   elemId = this.index
      //   this.elem.active = true
      // }

      // this.$set(this.settingConstructor.drags, 'activeElem', elemId)
      // this.settingConstructor.drags.activeElem = elemId
      // this.TDESSideBarModeElemActive()
      event.stopPropagation();
    },
    // searchParentAccessSelect(elemId) {
    //   if (!this.tagData.htmlElements[elemId].selectParent) {
    //     return elemId
    //   }
    //   return this.searchParentAccessSelect(this.tagData.htmlElements[elemId].parent)
    // },
    onDragStart(event, elem) {
      this.$emit('onDragStart', event, elem)
    },
    onDragEnd(event, elem, index, $backlightBlock) {
      this.$emit('onDragEnd', event, elem, index, $backlightBlock)
    },
    onMouseEnter() {
      this.setHoverElem(this.elem.id)
    },
    onMouseLeave() {
      let elemBelow = document.elementFromPoint(event.clientX, event.clientY)
      if (elemBelow && elemBelow.classList.contains('elemContTrue')) {
        this.setHoverElem(elemBelow.dataset.id)
      } else {
        this.setHoverElem(null)
      }
    },
    setHoverElem(elemId) {
      if (this.settingConstructor.drags.activeElem === elemId) {
        this.settingConstructor.hoverElem = null
        return
      }

      this.settingConstructor.hoverElem = elemId
    },
    // onMouseMove() {
    //   if (this.settingConstructor.hoverElem === this.elem.id) {
    //     return
    //   }
    //   this.settingConstructor.hoverElem = this.elem.id
    //   event.stopPropagation();
    // },
    // onMouseEnterElemSingle() {
    //   if (this.elemSingle) {
    //     // this.$root.$emit('updateElemConstructor', this.index);
    //   }
    // },
    getAttrElem() {
      let obj = {}
      for (let attr in this.elemAddMain) {
        if (!attr.startsWith("attr_")) {
          continue
        }
        if (this.elemAddMain[attr] === null) {
            continue
        }

        if (attr.substr(5) === 'src') {
          if (this.elemAddMain[attr] === 'none' && this.elem.tag === 'img') {
            obj[attr.substr(5)] = require('@/assets/images/constructor/elemImgDefault.png')
          } else {
            obj[attr.substr(5)] = this.elemAddMain[attr].url
          }
        } else {
            if (this.elemAddMain[attr].val === undefined) { /// повторяющийся код, надо вынести куда то
                obj[attr.substr(5)] = this.elemAddMain[attr]
            } else {
                obj[attr.substr(5)] = this.elemAddMain[attr].val

                if (this.elemAddMain[attr].unit) {
                    obj[attr.substr(5)] += this.elemAddMain[attr].unit
                }
            }
        }
      }

      if (this.elem.tag === 'input' || this.elem.tag === 'textarea') {
        obj.autocomplete = 'off'
      }

      return obj
    },
    // calculateFactSizeElem() {
    //   if (this.elemSingle) {
    //     return;
    //   }
    //   const elemContTrue = document.getElementById('elemContTrue_' + this.index)
    //   if (elemContTrue === null) {
    //     return
    //   }
    //   const data = elemContTrue.getBoundingClientRect()
    //   this.factParam.width = data.width
    //   this.factParam.height = data.height
    //   console.log(this.factParam)
    // }
  },
  created() {
    this.RenderElemStyleContainerHelper = new RenderElemStyleContainerHelper(this)
  },
  updated() {
    this.$root.$emit('updateElemConstructor');
  },
  mounted() {
    if (this.$refs.elemContTrue) {
      this.$refs.elemContTrue.onclick = function() {
        return false
      }
    }

    // this.calculateFactSizeElem()
  },
  render: function (createElement) {
    let child = [this.elemAddMain.content]

    for (let elemId in this.tree) {
      if (!this.elemSingle && this.tagData.htmlElements[elemId].tag === null) {
        child.push(this.tagData.htmlElements[elemId].main.content)
      } else {
        child.push(
            createElement(
                'elem',
                {
                  props: {
                    tree: this.elemSingle ? this.tree[elemId].elemChild : this.tree[elemId],
                    tagData: this.tagData,
                    index: elemId,
                    settingConstructor: this.settingConstructor,
                    elemSingle: this.elemSingle ? this.tree[elemId] : null
                  }
                }
            )
        )
      }
    }

    let possibleChild = 1
    if (PatternElemsSettings[this.elem.name] !== undefined && !PatternElemsSettings[this.elem.name].possibleChild) {
      possibleChild = 0
    }

    let nativ = {}

    if (this.elem.tag === 'svgElem' || this.elem.tag === 'lottiePlayer') {
      nativ = {
        click: this.onSelectElem,
        mousedown: this.onElemMouseDown,
        // mouseenter: this.onMouseEnterElemSingle
      }
    }

    return createElement(
        this.elem.tag,
        {
          elem: this.elem,
          style: this.elemCss,
          class: ['tagContainer', ...this.classElemTrue],
          ref: 'elemContTrue',
          props: {
            elem: this.elem
          },
          attrs: {
            'data-id': this.index,
            'data-poschild': possibleChild,
            id: 'elemContTrue_' + this.index,
            draggable: false,
            ...this.getAttrElem()
          },
          on: {
            mousedown: this.onElemMouseDown,
            // mouseup: this.onElemMouseUp,
            click: this.onSelectElem,
            mouseenter: this.onMouseEnter,
            mouseleave: this.onMouseLeave,
            // mousemove: this.onMouseMove,
            dragstart: () => { return false }
          },
          ...nativ

        },
        child
    )
  }
}
</script>

<style scoped>
  .clcontainer, .clcol {
    min-height: 10px;
  }
</style>
