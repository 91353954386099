<template>
  <b-modal
      id="widgetsStore"
      centered
      title="Store"
      title-class="font-18"
      hide-footer
      v-model="showStore"
      size="xl"
  >
    <b-row>
        <b-col cols="2">
            <b-list-group>
                <b-list-group-item
                        v-for="(filter, index) in filters"
                        :key = index
                        class="border-top-0 border-left-0 border-right-0 pl-2 d-flex justify-content-between align-items-center"
                        button
                        :active = "filter.id===activeFilter"
                        active-class="secondary"
                        @click="activeFilter=filter.id"
                >
              <span>
                <i :class="filter.ico + ' mr-2'"></i>
                {{filter.name}}
              </span>
                    <!--              <b-badge variant="primary" class="pt-1" pill>{{ filter.widgets.length }}</b-badge>-->
                </b-list-group-item>
            </b-list-group>
        </b-col>
        <b-col cols="10">
            <div class="row no-gutters">
                <div
                        v-for="(item, i) in items"
                        :key="i"
                        class="col-3"
                >
                    <div
                            class="product-box text-center"
                            v-on:mouseover = "hoverWidget = item.id"
                            v-on:mouseleave = "hoverWidget = null"
                    >
                        <div v-if="hoverWidget === item.id" class="hoverBlock" @click="selectTemplate(item.id)">
                            <h4 class="text-white">
                                <i class="mdi  mdi-widgets-outline" style="font-size: 36px"></i> <br>
                                {{ $t('General.Base.Select') }}
                            </h4>
                        </div>
                        <div class="containerImg">

                            <b-carousel v-if="item.img && item.img.length > 1" >
                                <b-carousel-slide
                                        v-for="(img, n) in item.img"
                                        :key="n"
                                        :img-src="'https://storage.eefim.com/' + img">
                                </b-carousel-slide>
                            </b-carousel>
                            <div v-else class="productImg" :style="{ backgroundImage: 'url(' + getUrlImg(item) + ')' }"></div>
                            <!--            <img v-else draggable="false" :src="item.localImg === undefined ? 'https://storage.eefim.com/' + item.img : item.localImg" />-->
                        </div>

                        <div class="text-center mt-3">
                            <h5 class="font-size-15">{{item.name}}</h5>
                            <p class="font-size-12 mb-1 descriptionText">{{item.desc}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </b-col>
    </b-row>

  </b-modal>
</template>

<script>
import {widgetService} from "@/helpers/fakebackend/widgets.service";
import {isArray, isObject, isString} from "lodash";
// import TagDataEditService from "@/helpers/constructor/TagDataEditService";

export default {
  name: "widgetsStore",
  props:['tagData', 'settingConstructor'],
  data: function() {
    return {
      loadingWidgets: true,
      items: [],
      hoverWidget: null,
      newHtmlElementIndex: 0,
      filters: [{id: 4, name: 'Заготовки', ico: 'mdi mdi-cog-outline'}],
      activeFilter: null
    }
  },
  computed: {
    showStore: {
        get: function () {
            return this.settingConstructor.widgetsStore.show
        },
        set: function (val) {
            this.settingConstructor.widgetsStore.show = val
        }
    }
  },
  watch: {
    showStore: function (val) {
      if (val && !this.items.length) {
        this.getTemplates()
      }
    },
    activeFilter: function() {
        if (this.activeFilter === 'existingWidgets') {
            return
        }
        this.getTemplates()
    },
    currentPage: function () {
        this.getTemplates()
    }
  },
  methods: {
    ...widgetService,
    // ...TagDataEditService,
    getTemplates() {
      this.items = []

      this.loadingWidgets = true
      this.getTemplateWidgets({tag: this.activeFilter}).then(result => {
        this.items = result.data
        this.loadingWidgets = false
      })
    },
    getUrlImg: function (item) {
      return item.localImg === undefined ? 'https://storage.eefim.com/' + item.img : item.localImg
    },
    selectTemplate: function(id) {
      this.getTemplateWidget({id: id}).then(result => {
        this.addWidgetToTagData(JSON.parse(result.data))
      })
    },
    addWidgetToTagData: function(widget_data) {
      this.tagData.htmlElementIndex++
      let newMainElemId = 'e' + this.tagData.htmlElementIndex
      let elems = this.searchAndModify(widget_data.htmlElements, this.tagData.htmlElementIndex)
      elems = this.deleteTestFromIndex(elems)

      this.tagData.htmlElements = {...this.tagData.htmlElements, ...elems}
      let tree = this.searchAndModify(widget_data.htmlElementsTree, this.tagData.htmlElementIndex)
      tree = this.deleteTestFromIndex(tree, true)

      this.tagData.htmlElementsTree = {...this.tagData.htmlElementsTree, ...tree}
      this.tagData.htmlElementIndex =  this.newHtmlElementIndex
      this.settingConstructor.widgetsStore.show = false

      this.tagData.htmlElements[newMainElemId].main.name += ' ' + Object.keys(this.tagData.htmlElementsTree).length
      this.settingConstructor.activeMainElemId = newMainElemId
    },
    searchAndModify: function(obj, addValue) {
      let pattern = /^e\d+$/;
      for (let key in obj) {
        if (key === 'content') {
          continue
        }
        if (pattern.test(key)) {
          let n = Number(key.slice(1)) + addValue
          this.updateMaxHtmlIndex(n)
          let newKey = "e" + n + "_temp"
          obj[newKey] = obj[key]
          delete obj[key]
          key = newKey
        }
        if (isObject(obj[key]) || isArray(obj[key])) {
          this.searchAndModify(obj[key], addValue)
        } else if (isString(obj[key]) && pattern.test(obj[key])) { //obj[key][0] === "e"
          let n = Number(obj[key].slice(1)) + addValue
          this.updateMaxHtmlIndex(n)
          obj[key] = "e" + n
        }
      }
      return obj;
    },
    updateMaxHtmlIndex: function(numb) {
      if (numb > this.newHtmlElementIndex) {
        this.newHtmlElementIndex = numb
      }
    },
    deleteTestFromIndex: function (tree, deep = false) {
      for (let elemId in tree) {
        let id = elemId.slice(-0, -5)
        tree[id] = tree[elemId]
        delete tree[elemId]

        if (deep && isObject(tree[id]) || isArray(tree[id])) {
          this.deleteTestFromIndex(tree[id], deep)
        }
      }
      return tree
    }
  },
  mounted() {
      this.getTemplateWidgetsTags().then(result => {
          this.filters = [...this.filters, ...result]
      })
  }
}
</script>

<style scoped>

</style>