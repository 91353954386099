/**
 * Solve tasks preview and get css selector
 */
import VueCookies from 'vue-cookies'
import { constructorService } from '@/helpers/fakebackend/constructor.service';
import TagDataServerHelper from "@/helpers/constructor/TagDataServerHelper";

export default class TagDataPreviewHelper {


    static async previewTag(tag, settingConstructor) {
        if (tag.saveVersion !== tag.version) {
            await TagDataServerHelper.saveTag(tag, settingConstructor, 'auto')
        }

        return new Promise(function(resolve) { //
            let tagData = {
                tagId: tag.tag_id,
            }
            constructorService.previewTag(tagData,  'previewTag').then(data => {
                if (data.url) {
                    // VueCookies.set('prevDev', data.dev_key, '5min', '/', 'eefim.com')
                    window.open(data.url);
                }
                TagDataPreviewHelper.checkReadyTask(data.id).then(dataTask => {
                    resolve(dataTask)
                })
            })
        })

        // let tagData = {
        //     tagId: tag.tag_id,
        // }
        // return constructorService.previewTag(data, mode)
    }

    static async previewTagStop() {
        return constructorService.previewTagStop({}).then(() => {
            // VueCookies.remove('prevDev')
        })
    }

    /**
     * callback call every request
     * @param tag
     * @param settingConstructor
     * @param callback
     * @returns {Promise<unknown>}
     */
    static async getCssSelector(tag, settingConstructor, callback) { // tagData
        if (tag.saveVersion !== tag.version) {
            await TagDataServerHelper.saveTag(tag, settingConstructor, 'auto')
        }

        return new Promise(function(resolve) { //
            let tagData = {
                tagId: tag.tag_id,
            }
            constructorService.previewTag(tagData,  'cssSelector').then(data => {
                if (data.url) {
                    // this.previewMode = true
                    VueCookies.set('prevDev', data.dev_key, '1min', '/', 'eefim.com')
                    window.open(data.url);
                }
                TagDataPreviewHelper.checkReadyTask(data.id, callback).then(dataTask => {
                    resolve(dataTask)
                })
            })
        })
    }

    static async checkReadyTask(task_id, callback = null) {
        return TagDataPreviewHelper.delay(2000).then(() => {
            return constructorService.checkReadyPrevTask(task_id).then(data => {
                // if (data.status_task === 'cancel') {
                //     return data
                // }
                if (callback) {
                    callback(data)
                }
                if (data.status_task === 0) {
                    VueCookies.remove('prevDev')
                    return data
                }
                return TagDataPreviewHelper.checkReadyTask(task_id, callback)
            })
        })
    }

    static delay(ms) {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, ms)
        });
    }
}
