<template>
  <div class="listWindows">
    <div class="d-flex align-items-center flex-nowrap">
      <span class="mr-2 ignoreReset flex-shrink-0 font-size-11" v-b-tooltip.hover
            :title="$t('Constructor.General.ListWindows.Title')">
        <i class="fas fa-question-circle mr-1"></i>
        {{$t('Constructor.General.ListWindows.Windows')}}:
      </span>

      <ScrollContainer>
        <b-button-group class="mt-4 mt-md-0 btn-group-sm ignoreReset ">
          <b-button
              size="sm"
              v-for="(child, index) in tagData.htmlElementsTree"
              :key="index"
              variant="outline-dark"
              :class=" ['straight-angle-left-side-first-child', 'straight-angle-right-side-last-child' ,settingConstructor.activeMainElemId === index ? 'active' : '']"
              data-toggle="buttons"
              @click="clickWindow(index)"
          >
            {{getNameElem(index)}}
          </b-button>
        </b-button-group>
      </ScrollContainer>
      <b-button variant="outline-dark" data-toggle="buttons" size="sm" v-on:click="addMainElem()" class="straight-angle-left-side addNewWidget">
        <i class="fas fa-plus"></i>
      </b-button>
    </div>


  </div>
</template>

<script>
// import ElemStyleHelper from "@/helpers/constructor/ElemStyleHelper";
// import lodash from "lodash";
// import {mainDiv} from "@/helpers/constructor/patterns/DefaultTagData";
import TagDataEditService from "@/helpers/constructor/TagDataEditService";
import ScrollContainer from "@/components/ScrollContainer.vue";

export default {
  name: "ListWindow",
  components: { ScrollContainer },
  props: ["tagData", "settingConstructor"],
  data: function (){
    return {
    }
  },
  methods: {
    ...TagDataEditService,
    addMainElem() {
      this.settingConstructor.widgetsStore.show = true
      // let main = (new ElemStyleHelper()).getStyleElem(lodash.cloneDeep(mainDiv))
      // main.main.name = Object.keys(this.tagData.htmlElementsTree).length + 1
      // this.settingConstructor.activeMainElemId = String(this.TDESaddElem(lodash.cloneDeep(main), null))
      //
      // this.TDESElemDeActive()
    },
    getNameElem(elemId) {
      return this.tagData.htmlElements[elemId].main.name === '' ? elemId : this.tagData.htmlElements[elemId].main.name
    },
    clickWindow(index) {
      if (this.settingConstructor.activeMainElemId !== index) {
        this.settingConstructor.activeMainElemId = index

        this.TDESElemDeActive()
        return false
      }
      this.TDESElemActive(index)
    },
  }
}
</script>

<style scoped>

</style>