var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"containerRuler"},[_c('div',{ref:"rulerX",staticClass:"rulerXTop",attrs:{"id":"rulerX"}},[_c('div',{ref:"rulerContainer",staticClass:"rulerContainer",style:({
              width: _vm.settingConstructor.constructorArea.ruler.size + 'px',
              transform: 'scale('+ _vm.settingConstructor.constructorArea.scale +')',
            }),attrs:{"id":"rulerContainerrulerX"},on:{"scroll":_vm.scroll}},_vm._l((_vm.getCountSerif()),function(index){return _c('div',{key:index,staticClass:"rulerSerif",style:({
              width: _vm.step + 'px'
            })},[_vm._v(" "+_vm._s(_vm.getTextSerif(index))+" ")])}),0)]),_c('div',{ref:"rulerY",staticClass:"rulerYTop",style:({height: _vm.settingConstructor.workspace.height - 3 + 'px'}),attrs:{"id":"rulerY"}},[_c('div',{ref:"rulerContainer",staticClass:"rulerContainer",style:({
              height: _vm.settingConstructor.constructorArea.ruler.size + 'px',
              transform: 'scale('+ _vm.settingConstructor.constructorArea.scale +')'
            }),attrs:{"id":"rulerContainerrulerY"}},_vm._l((_vm.getCountSerif()),function(index){return _c('div',{key:index,staticClass:"rulerSerif",style:({
              height: _vm.step + 'px',
              // paddingTop: step/3 + 'px'
            })},[_vm._v(" "+_vm._s(_vm.getTextSerif(index))+" ")])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }