const colorBroderInput = '#E9EEF8'
const borderRadiusInput = { val: 4, unit: 'px' }
const colorBackgroundInput = '#F7FCFF'
const colorButton = '#6544BA'
const colorButtonHover = '#5633AE'
const colorIcon = '#212e34'

const allBorder = {
    borderTopColor: 'rgba(15,15,15,1)',
    borderTopStyle: 'solid',
    borderTopWidth: {
        val: 1,
        unit: 'px'
    },
    borderRightColor: 'rgba(15,15,15,1)',
    borderRightStyle: 'solid',
    borderRightWidth: {
        val: 1,
        unit: 'px'
    },
    borderBottomColor: 'rgba(15,15,15,1)',
    borderBottomStyle: 'solid',
    borderBottomWidth: {
        val: 1,
        unit: 'px'
    },
    borderLeftColor: 'rgba(15,15,15,1)',
    borderLeftStyle: 'solid',
    borderLeftWidth: {
        val: 1,
        unit: 'px'
    }
}
const cssForInput = {
    borderTopColor: colorBroderInput,
    borderRightColor: colorBroderInput,
    borderBottomColor: colorBroderInput,
    borderLeftColor: colorBroderInput,
    borderTopLeftRadius: borderRadiusInput,
    borderBottomLeftRadius : borderRadiusInput,
    borderBottomRightRadius : borderRadiusInput,
    borderTopRightRadius : borderRadiusInput,
    backgroundColor: colorBackgroundInput
}

const cssForInputField = {
    width: {
        val: 100,
        unit: '%'
    },
    height: {
        val: 40,
        unit: 'px'
    },
    paddingLeft: {
        val: 10,
        unit: 'px'
    },
    marginBottom: {
        val: 20,
        unit: 'px'
    },
    cursor: "text"
}

let buttonIconCss = { //icon{
            fill: '#fff',
            width: {
                val: 14,
                unit: 'px'
            },
            height: {
                val: 14,
                unit: 'px'
            }
        }
// export const allStyleDefault = {
//     fontFamily: "inherit",
//     fontSize: {
//         val: 14,
//         unit: 'px'
//     },
//     color: '#0f0f0f'
// }
export const styleDefaultTag = {
    h2: {
        css: {
            marginBottom: {
                val: 10,
                unit: 'px'
            },
            fontSize: {
                val: 24,
                unit: 'px'
            },
            fontWeight: '900'
        }
    },
    label: {
        css: {
            lineHeight: {
                val: 30,
                unit: 'px'
            },
            fontWeight: '900'
        }
    },
    button: {
        cssPseudoClasses: {
            hover: {
                backgroundColor: colorButtonHover
            }
        },
        css: {
            // ...allBorder,
            ...cssForInput,
            paddingTop: {
                val: 10,
                unit: 'px'
            },
            paddingBottom: {
                val: 10,
                unit: 'px'
            },
            paddingLeft: {
                val: 20,
                unit: 'px'
            },
            paddingRight: {
                val: 20,
                unit: 'px'
            },
            fontWeight: '900',
            fontSize: {
                val: 14,
                unit: 'px'
            },
            color: '#ffffff',
            backgroundColor: colorButton,
            cursor: "pointer",
            display: "flex",
            alignItems: "center"
        }
    },
    a: {
        css: {
            cursor: "pointer"
        }
    }
}
export const styleDefault = {
    mainDiv: {
        css: {
            // ...allBorder,
            background: 'rgba(255,255,255,1)',
            paddingLeft: {
                val: 10,
                unit: 'px'
            },
            paddingRight: {
                val: 10,
                unit: 'px'
            },
            paddingTop: {
                val: 10,
                unit: 'px'
            },
            paddingBottom: {
                val: 10,
                unit: 'px'
            },
            fontFamily: "Trebuchet MS",
            fontSize: {
                val: 14,
                unit: 'px'
            },
            lineHeight: {
                val: 14,
                unit: 'px'
            },
            color: '#0f0f0f'
        }
    },
    div: {
        css: {
            paddingLeft: {
                val: 4,
                unit: 'px'
            },
            paddingRight: {
                val: 4,
                unit: 'px'
            },
            paddingTop: {
                val: 4,
                unit: 'px'
            },
            paddingBottom: {
                val: 4,
                unit: 'px'
            }
        }
    },
    text: {
        css: {
            textAlign: 'left'
            // marginBottom: {
            //     val: 10,
            //     unit: 'px'
            // }
        }
    },
    textFieldError: {
        css: {
            color: '#bd0202',
            textAlign: 'center',
            lineHeight: {
                val: 12,
                unit: 'px'
            },
            fontSize: {
                val: 12,
                unit: 'px'
            }
        }
    },
    a: {
        css: {
            color: colorButton,
            textDecoration: ['underline']
        },
        cssPseudoClasses: {
            hover: {
                backgroundColor: colorButtonHover,
                textDecoration: 'none'
            }
        },
    },
    selfA: {
        css: {
            color: colorButton,
            textDecoration: ['underline']
        },
        cssPseudoClasses: {
            hover: {
                backgroundColor: colorButtonHover,
                textDecoration: 'none'
            }
        },
    },
    icon: {
        css: {
            fill: colorIcon
        }
    },
    /// GEOMETRIA
    arrowLeft: {
        css: {
            borderRightColor: '#0f0f0f',
        }
    },
    arrowRight: {
        css: {
            borderLeftColor: '#0f0f0f',
        }
    },
    // arrowDown: {
    //     css: {
    //         borderTopColor: '#0f0f0f',
    //     }
    // },
    circle: {
        css: {
            borderColor: '#0f0f0f',
        }
    },

    //// FORMS
    inputText: {
        css: {
            ...allBorder,
            ...cssForInput,
            ...cssForInputField
        }
    },
    inputNumber: {
        css: {
            ...allBorder,
            ...cssForInput,
            ...cssForInputField
        }
    },
    inputEmail: {
        css: {
            ...allBorder,
            ...cssForInput,
            ...cssForInputField
        }
    },
    inputPhone: {
        css: {
            ...allBorder,
            ...cssForInput,
            ...cssForInputField
        }
    },
    radioGroup: {
        css: {
            marginBottom: {
                val: 20,
                unit: 'px'
            },
            cursor: "pointer"
        }
    },
    checkboxGroup: {
        css: {
            marginBottom: {
                val: 20,
                unit: 'px'
            },
            cursor: "pointer"
        }
    },
    selectCustom: {
        css: {
            ...allBorder,
            ...cssForInput,
            marginBottom: {
                val: 20,
                unit: 'px'
            },
            width: {
                val: 100,
                unit: '%'
            },
            height: {
                val: 40,
                unit: 'px'
            },
            paddingLeft: {
                val: 10,
                unit: 'px'
            }
        }
    },
    textarea: {
        css: {
            ...allBorder,
            ...cssForInput,
            cursor: "text",
            marginBottom: {
                val: 20,
                unit: 'px'
            },
            width: {
                val: 100,
                unit: '%'
            },
            height: {
                val: 80,
                unit: 'px'
            },
            paddingLeft: {
                val: 10,
                unit: 'px'
            },
            paddingTop: {
                val: 10,
                unit: 'px'
            }
        }
    },
    buttonIcon: {
        children: [
            { //icon
                css: {
                    ...buttonIconCss
                }
            },
        ]
    },
    buttonIconRight: {
        children: [
            { //span
                css: {
                    color: '#fff'
                }
            },
            { //icon
                css: {
                    ...buttonIconCss,
                    marginLeft: {
                        val: 6,
                        unit: 'px'
                    }
                }
            }
        ]
    },
    buttonIconLeft: {
        children: [
            { //icon
                css: {
                    ...buttonIconCss,
                    marginRight: {
                        val: 6,
                        unit: 'px'
                    }
                }
            },
            { //span
                css: {
                    color: '#fff'
                }
            }
        ]
    },
    textGroup: {
        css: {
            width: {
                val: 100,
                unit: '%'
            },
            height: {
                val: 56,
                unit: 'px'
            },
            marginBottom: {
                val: 10,
                unit: 'px'
            }
        },
        children: [
            { //div row
                css: {
                    display: 'flex',
                },
                children:[
                    { //label
                        css: {
                            minWidth: {
                                val: 40,
                                unit: 'px'
                            },
                            marginRight: {
                                val: 10,
                                unit: 'px'
                            },
                            alignSelf: "center"
                        }
                    } ,
                    { // input
                        css: {
                            ...allBorder,
                            ...cssForInput,
                            cursor: "text",
                            height: {
                                val: 40,
                                unit: 'px'
                            },
                            width: {
                                val: 1,
                                unit: '%'
                            },
                            flexGrow: {
                                val: 1,
                                unit: null
                            },
                            flexShrink: {
                                val: 1,
                                unit: null
                            },
                            paddingTop: {
                                val: 4,
                                unit: 'px'
                            },
                            paddingBottom: {
                                val: 4,
                                unit: 'px'
                            },
                            paddingLeft: {
                                val: 4,
                                unit: 'px'
                            },
                            paddingRight: {
                                val: 4,
                                unit: 'px'
                            }
                        }
                    }
                ]
            },
            { // div error text
                css: {
                    textAlign: "center"
                },
                children:[
                    { // span
                        css: {
                            color: '#bd0202',
                            textAlign: 'center',
                            lineHeight: {
                                val: 12,
                                unit: 'px'
                            },
                            fontSize: {
                                val: 12,
                                unit: 'px'
                            },
                            fontWeight: '900',
                            marginTop: {
                                val: 2,
                                unit: 'px'
                            }
                        }
                    }
                ]
            }
        ]
    },
    arrowDown: {
        css: {
            width: {
                val: 14,
                unit: 'px'
            },
            height: {
                val: 7,
                unit: 'px'
            },
            overflowX: "hidden",
            overflowY: "hidden",
            position: "absolute",
            bottom: {
                val: -8,
                unit: 'px'
            },
            left: {
                val: 10,
                unit: 'px'
            }
        },
        children: [
            {
                css: {
                    width: {
                        val: 65,
                        unit: '%'
                    },
                    height: {
                        val: 135,
                        unit: '%'
                    },
                    position: "absolute",
                    top: {
                        val: -63,
                        unit: '%'
                    },
                    left: {
                        val: 15,
                        unit: '%'
                    },
                    // borderTopLeftRadius: {val: 2, unit: 'px'},
                    // borderBottomLeftRadius : {val: 2, unit: 'px'},
                    // borderBottomRightRadius : {val: 2, unit: 'px'},
                    // borderTopRightRadius : {val: 2, unit: 'px'},
                    backgroundColor: "#000",
                    transform: {
                        rotate: {
                            val: 45,
                            unit: "deg"
                        }
                    }
                }
            }
        ]
    }
}
