<template>
  <b-button-toolbar key-nav size="sm" class="mr-3"  >
    <div class=" mr-2">
      <i class="mdi mdi-magnify-minus font-size-16 mr-1" @click="changeScale('-')"></i>
      <i class="mdi mdi-magnify-close font-size-16 mr-1" @click="changeScale('1')"></i>
      <i class="mdi mdi-magnify-plus font-size-16 mr-1" @click="changeScale('+') "></i>
      {{printTextScale}}%
    </div>

<!--    <b-form-checkbox-->
<!--        switch-->
<!--        v-model="settingConstructor.theme"-->
<!--        size="sm"-->
<!--        class="mr-2 pt-2">-->
<!--      <label>{{$t('Constructor.General.DarkMode')}}</label>-->
<!--    </b-form-checkbox>-->

    <b-form-checkbox
        switch
        v-model="settingConstructor.showBordersElem"
        size="sm"
        class="mr-2 pt-1"
    >
      <label class="mb-0">{{$t('Constructor.General.Border')}}</label>
    </b-form-checkbox>

<!--    <b-dropdown-->
<!--        variant="outline-dark"-->
<!--        size="sm"-->
<!--        class=""-->
<!--        right-->
<!--    >-->
<!--      <template slot="button-content">-->
<!--        {{activeDeviceName}}-->
<!--        <i class="mdi mdi-chevron-down"></i>-->
<!--      </template>-->
<!--      <b-dropdown-item-->
<!--          v-for="(element, index) in settingConstructor.desktops"-->
<!--          :key="index"-->
<!--          :class="element.width === settingConstructor.desktopMode ? 'active' : ''"-->
<!--          v-on:click.stop="desktopMode(element.width)"-->
<!--          size="sm"-->
<!--      >{{index}}-->
<!--        <span v-if="element.width !== 'default'" class="font-size-10">({{element.width}})</span>-->
<!--      </b-dropdown-item>-->
<!--    </b-dropdown>-->

    <b-button-group size="sm" id="changerSizeWorkArea">
          <b-button
                  size="sm"
                  variant="outline-dark"
                  :pressed = "settingConstructor.constructorArea.width === 1920"
                  @click = "desktopMode(settingConstructor.desktops.desktop.width)"
                  class="topBarButton"
                  v-b-tooltip.hover
                  :title="$t('Constructor.General.DesktopsTooltips.Default')"
          >
              <i class="mdi mdi-desktop-mac"></i>
          </b-button>
          <b-button
                  size="sm"
                  variant="outline-dark"
                  :pressed = "settingConstructor.desktops.laptop.width === settingConstructor.constructorArea.width"
                  @click = "desktopMode(settingConstructor.desktops.laptop.width)"
                  v-b-tooltip.hover
                  :title="$t('Constructor.General.DesktopsTooltips.Laptop')"
          >
              <i class="mdi mdi-laptop"></i>
          </b-button>
          <b-button
                  size="sm"
                  variant="outline-dark"
                  :pressed = "settingConstructor.desktops.tablet.width === settingConstructor.constructorArea.width"
                  @click = "desktopMode(settingConstructor.desktops.tablet.width)"
                  v-b-tooltip.hover
                  :title="$t('Constructor.General.DesktopsTooltips.Tablet')"
          >
              <i class="mdi mdi-tablet-ipad"></i>
          </b-button>
          <b-button
                  size="sm"
                  variant="outline-dark"
                  :pressed = "settingConstructor.desktops.mobile2.width === settingConstructor.constructorArea.width"
                  @click = "desktopMode(settingConstructor.desktops.mobile2.width)"
                  class="topBarButton"
                  v-b-tooltip.hover
                  :title="$t('Constructor.General.DesktopsTooltips.Mobile')"
          >
              <i class="mdi mdi-cellphone"></i>
          </b-button>
      </b-button-group>






<!--    <b-button variant="success" class="mr-1" @click="saveTag()">cохрнить</b-button>-->
<!--    <b-button v-if="!previewMode" variant="info" class="mr-1" @click="previewTag()">предпросмотр</b-button>-->
<!--    <b-button v-if="previewMode" variant="danger" class="mr-1" @click="previewTagStop()">остановить предпросмотр</b-button>-->
<!--    <b-button v-b-toggle.sidebar-gallery variant="primary">gallery</b-button>-->

  </b-button-toolbar>

<!--  <div class="d-flex flex-row mb-4">-->
<!--            <div class=" mb-2">-->
<!--              <b-form-checkbox switch v-model="settingConstructor.theme" class="mb-1">-->
<!--                <label>Dark mode</label>-->
<!--              </b-form-checkbox>-->
<!--            </div>-->

<!--    <div class=" md-6 mr-3">-->
<!--      <b-button-group class="mt-4 mt-md-0 btn-group-sm">-->
<!--        <b-button-->
<!--            v-for="(element, index) in settingConstructor.desktops"-->
<!--            :key="index"-->
<!--            variant="dark"-->
<!--            :class="element.width === settingConstructor.desktopMode ? 'active' : ''"-->
<!--            data-toggle="buttons"-->
<!--            v-on:click.stop="desktopMode(element.width)">-->
<!--          {{index}} <span v-if="element.width !== 'default'" class="font-size-10">({{element.width}})</span>-->
<!--        </b-button>-->
<!--      </b-button-group>-->
<!--    </div>-->

<!--    <div class=" md-6">-->
<!--      <i class="mdi mdi-magnify-minus mdi-18px mr-1" @click="changeScale('-')"></i>-->
<!--      <i class="mdi mdi-magnify-close mdi-18px mr-1" @click="changeScale('1')"></i>-->
<!--      <i class="mdi mdi-magnify-plus mdi-18px mr-1" @click="changeScale('+') "></i>-->
<!--      масштаб: {{printTextScale}}%-->
<!--    </div>-->

<!--    <b-button variant="success" class="mr-1" @click="saveTag()">cохрнить</b-button>-->
<!--    <b-button v-if="!previewMode" variant="info" class="mr-1" @click="previewTag()">предпросмотр</b-button>-->
<!--    <b-button v-if="previewMode" variant="danger" class="mr-1" @click="previewTagStop()">остановить предпросмотр</b-button>-->
<!--    <b-button v-b-toggle.sidebar-gallery variant="primary">gallery</b-button>-->

<!--    &lt;!&ndash;      </div>&ndash;&gt;-->

<!--  </div>-->
</template>

<script>


export default {
  name: "controlBar",
  data: function () {
    return {
      previewMode: false
    }
  },
  computed: {
    printTextScale: function() {
      return 100 * this.settingConstructor.constructorArea.scale
    },
    activeDeviceName: function() {
      for (let index in this.settingConstructor.desktops) {
        if (this.settingConstructor.desktops[index].width === this.settingConstructor.desktopMode) {
          return index
        }
      }
      return 'desktop'
    }
  },
  props: ["settingConstructor", "tagData"],
  methods: {
    desktopMode(width) {
      // this.settingConstructor.desktopMode = width
      if (width === 'default') {
        this.settingConstructor.constructorArea.width = 1920
      } else {
        this.settingConstructor.constructorArea.width = width
      }
      this.calculateConstructorArea()
    },
    changeScale(scale) {
      if (scale === '-' && this.settingConstructor.constructorArea.scale >= 0.5) {
        this.settingConstructor.constructorArea.scale -= 0.1
      }
      if (scale === '+' && this.settingConstructor.constructorArea.scale <= 1.5) {
        this.settingConstructor.constructorArea.scale += 0.1
      }
      if (scale === '1' ) {
        this.settingConstructor.constructorArea.scale = 1
      }

      // this.calculateConstructorArea()
    },
    calculateConstructorArea() {
      // let constructorArea = document.getElementById('constructorArea')
      // const coord = constructorArea.getBoundingClientRect()


      // let leftShift = (this.settingConstructor.constructorArea.width -
      //     this.settingConstructor.constructorArea.width * this.settingConstructor.constructorArea.scale
      // )/2
      //
      // let topShift = (this.settingConstructor.constructorArea.height -
      //     this.settingConstructor.constructorArea.height * this.settingConstructor.constructorArea.scale
      // )/2


      // let rulerX = document.getElementById("rulerContainerrulerX")
      // let rulerY = document.getElementById("rulerContainerrulerY")

      // let difLeft = coord.left + this.settingConstructor.constructorArea.width - leftShift * 2
      // if (difLeft < this.settingConstructor.workspace.width) {
      //   // this.settingConstructor.constructorArea.left = -(this.settingConstructor.constructorArea.width - this.settingConstructor.workspace.width - leftShift)
      //   // rulerX.style.left = this.settingConstructor.constructorArea.left + 'px'
      // }

      // difLeft = coord.left + leftShift
      // if (difLeft > 0) {
      //   // rulerX.style.left = - leftShift + 'px'
      //   // this.settingConstructor.constructorArea.left = - leftShift
      // }

      // const difTop = coord.top + topShift
      // if (difTop > 0) {
      //   // rulerY.style.top = - topShift + 'px'
      //   // this.settingConstructor.constructorArea.top = - topShift
      // }
    }
  }
}
</script>

<style scoped>

</style>
