export const allStyleReset = {
    position: 'relative',
    boxSizing: 'border-box',
    cursor: "default",
    top: 'unset',
    left: 'unset',
    bottom: 'unset',
    right: 'unset',
    width: 'auto',
    height: 'auto',
    background: "none",
    fontFamily: "inherit",
    fontSize: "inherit",
    fontWeight: "100",
    color: "#0f0f0f",
    textDecoration: 'none',
    overflow: "visible",
    display: "block",
    zIndex: {
        val: 50000
    },
    opacity: {
        val: 1
    },
    borderTopLeftRadius: {
        "val": 0,
        "unit": "px"
    },
    borderBottomLeftRadius: {
        "val": 0,
        "unit": "px"
    },
    borderBottomRightRadius: {
        "val": 0,
        "unit": "px"
    },
    borderTopRightRadius: {
        "val": 0,
        "unit": "px"
    },
    marginTop: { val: 0, unit: "px" },
    marginBottom: { val: 0, unit: "px" },
    marginLeft: { val: 0, unit: "px" },
    marginRight: { val: 0, unit: "px" },
    paddingTop: { val: 0, unit: "px" },
    paddingBottom: { val: 0, unit: "px" },
    paddingLeft: { val: 0, unit: "px" },
    paddingRight: { val: 0, unit: "px" },
    borderStyle: "none",
    borderWidth: { val: 0, unit: "px" },
    borderColor: "#000",
    borderTopColor: '#000',
    borderTopStyle: 'none',
    borderTopWidth: { val: 1, unit: 'px' },
    borderRightColor: '#000',
    borderRightStyle: 'none',
    borderRightWidth: { val: 1, unit: 'px' },
    borderBottomColor: '#000',
    borderBottomStyle: 'none',
    borderBottomWidth: { val: 1, unit: 'px' },
    borderLeftColor: '#000',
    borderLeftStyle: 'none',
    borderLeftWidth: { val: 1, unit: 'px' }
}

export const styleResetElemTag = {
    span: {
      display: "inline"
    },
    a: {
        display: "inline"
    },
    input: {
        appearance: "none",
        cursor: 'text'
    },
    button: {
        appearance: "none",
    },
    select: {
        appearance: "none",
    },
    textarea: {
        appearance: "none",
    }
}

export const styleResetElemType = {
    radiobutton: {
        appearance: "auto",
        borderTopLeftRadius: {
            "val": 50,
            "unit": "%"
        },
        borderBottomLeftRadius: {
            "val": 50,
            "unit": "%"
        },
        borderBottomRightRadius: {
            "val": 50,
            "unit": "%"
        },
        borderTopRightRadius: {
            "val": 50,
            "unit": "%"
        },
    },
    checkbox: {
        appearance: "auto",
    }
}