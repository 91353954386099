<template>
  <div>

    <h4 v-if="elem.js.events.length" class="card-title mb-2 mt-0">Текущие события</h4>
    <div
        v-for="(element, index) in elem.js.events"
        :key="index"
        class="mb-5 pl-2 codeLineDashedLeft"
    >
      <RenderEvent
          v-if="BasePropElems !== null"
          :eventData="element"
          :indexEvent="index"
          :indexElem="settingConstructor.drags.activeElem"
          :tagData="tagData"
          :basePropElems="BasePropElems"
          :elem="elem"
          :newEvent="false"
          :settingConstructor = settingConstructor
      />
    </div>
    <hr v-if="elem.js.events.length" class="mb-4"/>

    <h4 class="card-title mb-3 mt-0">Добавить событие</h4>
    <RenderEvent
        v-if="BasePropElems !== null"
        :eventData="newElement.eventData"
        indexEvent="new"
        :indexElem="settingConstructor.drags.activeElem"
        :tagData="tagData"
        :basePropElems="BasePropElems"
        :elem="elem"
        :newEvent="true"
        :settingConstructor = settingConstructor
    />

    <b-card-text class="mt-4" v-if="!elem.js.events.length">
      <hr>
      <p><b>Скрипты</b> - это интерактивная часть элемента, если вы хотите чтобы с элементом можно было взаимодействовать, это скрипты.</p>

      <p>Работа со скриптами не требует особых знаний, достаточно понять несколько моментов.</p>

      <p>Есть <b>события</b> (напримир клик по элементу) и у событий есть <b>действия</b> (например "скрыть элемент").
        При наступлении выбранного события происходят указанные действия</p>

      <p>Подробней как с этим работать смотри нашу статью про <a href="/">скрипты</a></p>
    </b-card-text>
  </div>
</template>

<script>
import RenderEvent from './_renderEvent'

export default {
  name: "mainJs",
  props: ['settingConstructor', 'tagData'],
  data() {
    return {
      newElement: {
        eventData: {
          event: null
        }
      },
      BasePropElems: null,
      elem: this.tagData.htmlElements[this.settingConstructor.drags.activeElem]
    }
  },
  components: { RenderEvent },
  created() {
    if (this.settingConstructor.drags.activeElem === null) {
      return
    }


    this.$store.dispatch('tagEditorPropElem/getPatterns', this.elem).then(data => {
      this.BasePropElems = data
    })
  }
}
</script>

<style scoped>

</style>
