export default {
    getElemCssWithoutPseudo(elem) {
        if (this.settingConstructor.desktopMode === 'default') {
            return elem.css
        }

        if (elem.addCss[this.settingConstructor.desktopMode] === undefined) {
            this.$set(elem.addCss, this.settingConstructor.desktopMode, {})
        }

        return elem.addCss[this.settingConstructor.desktopMode]
    },

//     getElemExtendedCss(elem, settingConstructor) {
//         if (settingConstructor.desktopMode === 'default') {
//             return elem.css
//         }
//
//         if (elem.addCss[settingConstructor.desktopMode] === undefined) {
//             this.$set(elem.addCss, this.settingConstructor.desktopMode, {})
//         }
//
//         return elem.addCss.[settingConstructor.desktopMode]
//     },
//     getResultProp: function(elem, indexElem, blockProps, settingConstructor) {
//         let blockAdd = 'addMain'
//
//         let pseudoClass = null
//         if (blockProps === 'js') {
//             blockAdd = 'addJs'
//         }
//         if (blockProps === 'css') {
//             blockAdd = 'addCss'
//
//             if (settingConstructor.cssPseudoClasses.hover) {
//                 blockAdd = 'cssPseudoClasses'
//                 pseudoClass = 'hover'
//                 if (elem.cssPseudoClasses[pseudoClass] === undefined) {
//                     this.$set(elem.cssPseudoClasses, pseudoClass, {})
//                 }
//             }
//         }
//
//
//         if (settingConstructor.js.jsBlockActive[indexElem] !== undefined) {
//             return elem.jsBlocks[this.settingConstructor.js.jsBlockActive[indexElem].jsBlockId][blockProps]
//         }
//
//         if (blockProps === 'main') {
//             return elem[blockProps]
//         }
//
//         if (this.settingConstructor.desktopMode === 'default') {
//             if (!pseudoClass) {
//                 return elem[blockProps]
//             }
//             return elem.cssPseudoClasses[pseudoClass]
//         }
//
//         if (elem.addCss[this.settingConstructor.desktopMode] === undefined) {
//             this.$set(elem[blockAdd], this.settingConstructor.desktopMode, {})
//         }
//         if (!pseudoClass) {
//             return elem[blockAdd][this.settingConstructor.desktopMode]
//         }
//         return elem.cssPseudoClasses[pseudoClass]
//     }
}