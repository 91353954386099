/*
    Содержит настройки элементов, настройки которые не нужно сохранять в tagData потому что они могут измениться по мере
    разработки. Если например нужно проверить может ли элемент иметь детей то проверяем в этом обьекте а не в tagData
 */

const param = {
    possibleChild: true, // может содержать дочернии элемент
    containContent: false, // может содержать текст,
    selfRedactor: false // будет ли доступен редактор текста для дочернего текста

    // draggable: true,
    // resizable: true,
}
export const PatternElemsSettings = {
    div: {
        ...param,
    },
    bimg: {
        ...param,
        possibleChild: true,
    },
    img: {
        ...param,
        possibleChild: false,
    },
    h2: {
        ...param,
        possibleChild: ['span'],
        containContent: true
    },
    span: {
        ...param,
        possibleChild: ['span'],
        containContent: true
    },
    text: {
        ...param,
        possibleChild: ['span'],
        containContent: true,
        selfRedactor: true
    },
    selfText: {
        possibleChild: false,
        containContent: false
    },
    selfA: {
        possibleChild: false,
        containContent: false
    },
    textFieldError: {
        ...param,
    },
    a: {
        ...param,
    },
    // GEOMETRY
    arrowLeft: {
        ...param
    },
    arrowRight: {
        ...param
    },
    arrowDown: {
        ...param
    },
    circle: {
        ...param
    },
    // FORM
    inputText: {
        ...param,
        possibleChild: false
    },
    inputNumber: {
        ...param,
        possibleChild: false
    },
    inputEmail: {
        ...param,
        possibleChild: false
    },
    inputPhone: {
        ...param,
        possibleChild: false
    },
    button: {
        ...param,
        possibleChild: ['span'],
        containContent: true,
        selfRedactor: false
    },
    checkbox: {
        ...param,
        possibleChild: false,
    },
    radiobutton: {
        ...param,
        possibleChild: false,
    },
    textarea: {
        ...param,
        possibleChild: false,
    },
    label: {
        ...param,
        possibleChild: ['span'],
        containContent: false
    },
    selectCustom: {
        ...param,
        possibleChild: false
    },
    radioGroup: {
        ...param,
        selectParent: false,
    },
    checkboxGroup: {
        ...param,
        selectParent: false,
    },
    textGroup: {
        ...param,
    },
    widgetAttach: {
        ...param,
    },
    container: {
        ...param,
    },
    col: {
        ...param,
    },
    templateTwo: {
        ...param
    },
    templateThree: {
        ...param
    }
}
