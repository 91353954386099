<template>
  <Tree :value="treeData" :ondragend="ondragend" :ondragstart="ondragstart" :indent="15">
    <div
        slot-scope="{node, tree}"
        @click="click(node.id)"
        class="pl-0 pointerCursor tree-item font-size-10"
        :class="node.id === settingConstructor.drags.activeElem ? 'selectedElem' : ''"

    >
      <i
          v-if="tagData.htmlElements[node.id].hidden"
          @click.stop="$set(tagData.htmlElements[node.id], 'hidden', false)"
          class="mdi mdi-eye-outline ml-1 opacity-0 treeIconHidden"
      ></i>

      <i
          v-if="!tagData.htmlElements[node.id].hidden"
         @click.stop="$set(tagData.htmlElements[node.id], 'hidden', true)"
         class="mdi mdi-eye-off-outline ml-1"
      ></i>
<!--tree.toggleFold(node, path);-->
      <b v-on:click.stop="toggleFolder(tree, node)">
        {{node.$folded ? '+' : '-'}}
      </b>



      <small><span class="treeItemText">{{node.text}}</span> <span class="text-muted">({{node.id}})</span></small>

      <small v-if="node.text !== node.tag" class="text-muted ml-1 font-size-10">{{node.tag}}</small>



      <i
          v-if="tagData.htmlElements[node.id].main.hiddenElem"
          class="ri-eye-2-line ml-1 color-stock"
      ></i>

    </div>
  </Tree>
</template>

<script>
import 'he-tree-vue/dist/he-tree-vue.css'
import {Tree, Draggable, Fold} from 'he-tree-vue'
import TagDataGetElemsHelper from '@/helpers/constructor/TagDataGetElemsHelper'
import TagDataEditService from "@/helpers/constructor/TagDataEditService";
import {PatternElemsSettings} from "@/helpers/constructor/patterns/PatternElemsSettings";

export default {
  name: "elemsTree",
  components: {Tree: Tree.mixPlugins([Draggable, Fold])},
  props: ['tagData', 'settingConstructor'],
  data() {
    return {
      // treeData: [{text: 'node 1'}, {text: 'node 2', children: [{text: 'node 2-1'}]}]
    }
  },
  computed: {
    treeData: function() {
      if (this.settingConstructor.activeMainElemId === null) {
        return []
      }

      let data = [{
        id: this.settingConstructor.activeMainElemId,
        text: this.getElemName(this.settingConstructor.activeMainElemId, false),
        tag: this.tagData.htmlElements[this.settingConstructor.activeMainElemId].tag,
        children: this.getObj(this.tagData.htmlElementsTree[this.settingConstructor.activeMainElemId])
      }]
      return data
      // return this.tagData.htmlElementsTree
    }
  },
  methods: {
    ...TagDataGetElemsHelper,
    ...TagDataEditService,
    ondragstart: function(vue,data) {
      let elem = this.getElemTreeByPath(data.startPath)
      if (elem.id === '0') {
        return false
      }
      return true
    },
    getElemTreeByPath(path){
      let elem = this.treeData[0]
      path.forEach((value, index) => {
        if (index === 0) {
          return true
        }
        elem = elem.children[value]
      })
      return elem
    },
    ondragend: function(vue,data) {
      let elem = this.getElemTreeByPath(data.startPath)
      //     this.treeData[0]
      // // data.startPath.shift()
      // data.startPath.forEach((value, index) => {
      //   if (index === 0) {
      //     return true
      //   }
      //   elem = elem.children[value]
      // })

      let targetElem = this.treeData[0]
      let elemAfterInsert

      for (let index in data.targetPath) {
        if (parseInt(index) === 0) {
          continue
        }

        if (index <= data.targetPath.length - 2) {
          targetElem = targetElem.children[data.targetPath[index]]
        } else {
          if (data.targetPath[index] === 0 ) {
            elemAfterInsert = 'first'
          } else {
            elemAfterInsert = targetElem.children[data.targetPath[index] - 1].id
          }
        }
      }

      let elem_name = this.tagData.htmlElements[targetElem.id].name
      if (PatternElemsSettings[elem_name] !== undefined && !PatternElemsSettings[elem_name].possibleChild) {
        return false
      }

      this.TDESchangeElemTree(elem.id, targetElem.id, elemAfterInsert)
    },
    click: function(id) {
      this.TDESElemActive(id)
    },
    getObj(tree) {
      let list = []
      for (let i in tree) {
        if (
            this.tagData.htmlElements[i].tag === null ||
            this.tagData.htmlElements[i].type === 'selfText' ||
            this.tagData.htmlElements[i].type === 'selfA'
        ) {
          continue
        }
        list.push({
          id: i,
          text: this.getElemName(i, false),
          tag: this.tagData.htmlElements[i].tag,
          children: Object.keys(tree[i]).length ? this.getObj(tree[i]) : null,
          $folded: (this.tagData.htmlElements[i].tree_folder === undefined || !this.tagData.htmlElements[i].tree_folder) ? false : true
        })
      }
      return list
    },
    toggleFolder(tree, node) {
      tree.toggleFold(node);
      this.tagData.htmlElements[node.id].tree_folder = !this.tagData.htmlElements[node.id].tree_folder
    }
  }
}
</script>

<style scoped>
  .selectedElem {
    background: #5664d2;
    color: #fff;
  }
  .selectedElem .text-muted {
    color: #d2d2d2 !important;
  }
  /*.tree-item:hover {*/
  /*  !*font-size: 13px !important;*!*/
  /*}*/
  .tree-item:hover .treeIconHidden {
    opacity: 1;
  }
  .tree-node-back  {
    margin-left: -20px !important;
  }
  .treeItemText {
    text-overflow: ellipsis;
    word-wrap: normal;
  }




</style>
