<template>
  <div>
    <b-input-group class="justify-content-end">
      <b-form-select
          class="col-7"
          :options="getAllElements()"
          v-model="dataObj.data.elemId"
      ></b-form-select>
      <div class="input-group-append">
        <b-button
            variant="success"
            :disabled = "dataObj.data.elemId === null ? true : false"
            v-on:click.stop="initCssJs()"
        >{{$t('Constructor.PropWidgets.CssActionWidget.SpecifyStyles')}}</b-button>
      </div>
    </b-input-group>

    <div v-if="showWidget">
      <div class="localeModalBackdrop"></div>
      <b-sidebar
          id="cssActionWidgetSideBar"
          :title="$t('Constructor.PropWidgets.CssActionWidget.ElementStyles')"
          width="94%"
          right
          shadow
          bg-variant="white"
          visible
          body-class="p-3 pt-0"
          v-model="showWidget"
      >
        <sideBarProps
            blockProps="css"
            activeTab="position"
            :tagData="tagData"
            :settingConstructor="settingConstructor"
        />
      </b-sidebar>
    </div>
<!--    <transition name="slide-fade" >-->




<!--      <div v-if="dataObj.data.elemId !== null && showWidget">-->
<!--        <div class="localeModalBackdrop"></div>-->
<!--        <div class="localeModal">-->
<!--          <div class="modal-header">-->
<!--            <h5 class="modal-title font-18">Стили</h5>-->
<!--            <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click.stop="closeModalWin()">-->
<!--              <span aria-hidden="true">&times;</span>-->
<!--            </button>-->
<!--          </div>-->
<!--          <div class="modal-body" style="overflow: scroll" >-->
<!--            <sideBarProps-->
<!--                blockProps="css"-->
<!--                activeTab="position"-->
<!--                :tagData="tagData"-->
<!--                :settingConstructor="settingConstructor" />-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->


<!--    </transition>-->
  </div>
</template>

<script>
import TagDataGetElemsHelper from "@/helpers/constructor/TagDataGetElemsHelper";
import sideBarProps from "../../_sideBarProps"
import JsBlockHelper from "@/helpers/constructor/sideBar/js/JsBlockHelper";
import HistoryHelper from '@/helpers/constructor/HistoryHelper'

export default {
  name: "CssActionWidget",
  props: ["tagData", "settingConstructor", "newAction", "dataObj", "actionIndex"], //"indexEvent",
  components: { sideBarProps },
  data() {
    return {
      showWidget: false,
      dataPattern: {
        elemId: null,
        jsBlockId: null
      }
    }
  },
  watch: {
    showWidget: function(val) {
      if (!val) {
        this.closeModalWin()
      }
    }
  },
  created() {
    if (!this.dataObj.data) {
      this.dataObj.data = this.dataPattern
    }
  },
  mounted() {
    // this.$root.$on('globalCloseAll',  () => { console.log(this.showWidget); this.showWidget = false })
  },
  destroyed() {
    this.closeModalWin()
  },
  methods: {
    ...TagDataGetElemsHelper,
    ...JsBlockHelper,
    ...HistoryHelper,
    getAllElements() {
      if (this.dataObj.data.elemId !== null) {
        return this.getAllElementsToOptions()
      }
      return [...[{value: null, text: this.$t('General.Base.SelectItem')}], ...this.getAllElementsToOptions()]
    },
    initCssJs() {
      if (this.dataObj.data.elemId === null) {
        return
      }
      // this.settingConstructor.cssEditor.editAnotherElem = this.dataObj.data.elemId
      this.$set(this.settingConstructor.cssEditor, 'editAnotherElem', this.dataObj.data.elemId)
      if (!this.dataObj.data.jsBlockId) {
        this.dataObj.data.jsBlockId = this.createNewBlock(this.dataObj.data.elemId)
      }

      this.$set(this.settingConstructor.js.jsBlockActive, this.dataObj.data.elemId, { jsBlockId: this.dataObj.data.jsBlockId })
      this.showWidget = true
    },
    closeModalWin() {
      this.showWidget = false
      this.settingConstructor.cssEditor.editAnotherElem = null
          this.$delete(this.settingConstructor.js.jsBlockActive, this.dataObj.data.elemId)
    },
    addStyle() {

    }
  }
}
</script>
<style>
  .localeModal {
    position: absolute;
    top: 50px;
    left: 14px;
    width: 100%;
    background: #fff;
    z-index: 9;
    border: 1px solid #f1f5f7;
    box-shadow: 0px 0px 5px 0 #333;
  }
  .localeModalBackdrop {
    position: absolute;
    z-index: 8;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background: #000;
  }
  .slide-fade-enter-active {
    transition: all .3s ease;
    top: 0px;
    left: 0px;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .slide-fade-leave-active {
    transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    top: 0px;
    left: 0px;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .slide-fade-enter, .slide-fade-leave-to  {
    transform: translateX(10px);
    opacity: 0;
  }
</style>
