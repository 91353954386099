<template>
  <div>
    <h4 class="card-title">
      {{$t('Constructor.PropWidgets.RowWidget.NumberColumns')}}
    </h4>

    <div class="d-flex border colContainer ">
      <div
          v-for="(col, index) in getCols"
          :key="index"
          class="border p-1 m-1 font-size-9 text-center"
          :style="{flexGrow: getFlexGrownCol(col), fontSize: '7px'}"
          :id="'colPattern_' + index"
      >
        {{getFlexGrownCol(col)}}

        <b-popover :target="'colPattern_' + index" triggers="hover" placement="top" variant="primary" boundary-padding="30">
          <span class="mr-2">{{getFlexGrownCol(col)}}</span>
          <b-button size="sm" variant="danger" :title="$t('General.Base.Delete')" @click.stop="deleteCol(col.id)">
            <i class="mdi mdi-window-close"></i>
          </b-button>
        </b-popover>
      </div>

    </div>

    <b-button variant="primary" size="sm" class="mt-1" @click="addCol">
      {{$t('Constructor.PropWidgets.RowWidget.AddColumn')}}
    </b-button>
  </div>
</template>

<script>
import lodash from 'lodash'
// import ShowProp from "@/helpers/constructor/sideBar/props/MethodShowProp";
// import PropsHelper from "@/helpers/constructor/sideBar/props/PropsHelper";
import TagDataGetElemsHelper from "@/helpers/constructor/TagDataGetElemsHelper";
import TagDataEditService from "@/helpers/constructor/TagDataEditService";
import PropsHelperMethods from "@/helpers/constructor/sideBar/props/PropsHelperMethods";
import { PatternElems } from '@/helpers/constructor/patterns/PatternElems'
import ElemStyleHelper from '@/helpers/constructor/ElemStyleHelper'
import Swal from "sweetalert2";

export default {
  name: "RowWidget",
  props: [ 'elem', 'prop', 'tagData', 'indexElem', 'settingConstructor', 'nameProp' ],
  data: function() {
    return {
      blockProps: 'main',

    }
  },
  computed: {
    getCols: function() {
      let cols = this.getAllChildElem(this.indexElem, {name: 'col'})
      let data = []
      cols.forEach(elemId => {
        data.push(this.tagData.htmlElements[elemId])
      })
      return data
    }
  },
  methods: {
    ...TagDataEditService,
    ...TagDataGetElemsHelper,
    ...PropsHelperMethods,
    getFlexGrownCol: function (col) {
      let css = this.getElemCssWithoutPseudo(col)
      if (css.flexGrow === undefined) {
        return 0
      }
      if (css.flexGrow.val === undefined) {
        return css.flexGrow
      }
      return css.flexGrow.val;

    },
    // getWidthCol: function (col) {
    //   let css = this.getElemCssWithoutPseudo(col)
    //   if (css.width === undefined) {
    //     return 'auto'
    //   }
    //   if (css.width.val === undefined) {
    //     return css.width
    //   }
    //   return css.width.val + css.width.unit;
    //
    // },
    calcWidthCol: function() {
      let cols = this.getAllChildElem(this.indexElem, {name: 'col'})
      let width = Math.floor(100 / (cols.length) * 100) / 100

      cols.forEach(elemId => {
        if (this.tagData.htmlElements[elemId].css.width.unit === '%') {
          this.tagData.htmlElements[elemId].css.width.val = width
        }
      })
    },
    addCol: function() {
      let elemCol = lodash.cloneDeep(PatternElems.col)
      elemCol = new ElemStyleHelper().getStyleElem(elemCol)
      this.TDESaddElem(elemCol, this.indexElem)
      this.calcWidthCol()
    },
    deleteCol: function (index) {
      Swal.fire({
        title: this.$t('Constructor.Dialog.DeleteElem.Title'),
        text: this.$t('Constructor.Dialog.DeleteElem.Text'),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t('General.Base.Delete'),
        cancelButtonText: this.$t('General.Base.Cancel')
      }).then((result) => {
        if (result.isConfirmed) {
          this.TDESDelElemWithChild(index)
          this.calcWidthCol()
        }
      })
    }
  }
}
</script>

<style scoped>
  .colContainer {
    width: 100%;
    overflow-x: scroll;
  }
</style>
