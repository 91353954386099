<template>
  <div
      ref="scrollContainerThumb"
      class="d-flex wm-0"
      @mousemove = "checkScroll"
      @mouseleave = "stopCheck"
  >
    <div class="indicatorScrollRight border border-dark bg-dark text-white border-radius-default straight-angle-right-side">
      <i class="ri-arrow-left-s-fill"></i>
    </div>
    <div
        ref="scrollContainer"
        class = "scroll-container"
        :style = "style"
    >
      <slot></slot>
    </div>
    <div class="indicatorScrollRight border border-dark bg-dark text-white">
      <i class="ri-arrow-right-s-fill"></i>
    </div>
  </div>

</template>

<script>
export default {
  name: "ScrollContainer",
  data: function (){
    return {
      activeScroll: false,
      interval: null,
      cordinatesContainer: null,
      style: {}
    }
  },
  computed: {
    rightScroll: function () {
      if (!this.cordinatesContainer) {
        return false
      }
      if (this.cordinatesContainer.width < this.$refs.scrollContainer.clientWidth - this.$refs.scrollContainer.scrollLeft) {
        return true
      }
      return false
    }
  },
  methods: {
    startScrolling: function (side) {
      if (this.interval) {
        this.stopScrolling()
      }
      if (side === 'left') {
        this.style.pointer = "e-resize"
      } else {
        this.style.pointer = "w-resize"
      }

      this.interval = setInterval( () => {
        if (side === 'left') {
          this.$refs.scrollContainer.scrollLeft -= 7;
        } else {
          this.$refs.scrollContainer.scrollLeft += 7;
        }

      }, 15)
    },
    stopScrolling: function () {
      clearInterval(this.interval)
      this.style.pointer = 'default'
    },
    checkScroll: function (e) {
      const xPos = e.clientX - this.cordinatesContainer.x; // получаем позицию курсора относительно контейнера
      // const scrollSpeed = 10; // скорость прокрутки
      if (xPos < 40) { // если курсор находится близко к левому краю
        this.startScrolling('left')
        // container.scrollLeft -= scrollSpeed; // прокручиваем влево

      } else if (xPos > this.cordinatesContainer.width - 40) { // если курсор находится близко к правому краю
        this.startScrolling('right')
        // container.scrollLeft += scrollSpeed; // прокручиваем вправо
      } else {
        this.stopScrolling()
      }
    },
    stopCheck() {
      this.stopScrolling()
    },
    srollContainer: function () {

    },
    init: function () {
      this.cordinatesContainer = this.$refs.scrollContainerThumb.getBoundingClientRect()
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style scoped>
.scroll-container {
  white-space: nowrap;
  overflow-x: scroll;
  width: auto;
  -ms-overflow-style: none;  /* IE и Edge */
  scrollbar-width: none;  /* Firefox */
}
.scroll-container::-webkit-scrollbar {
  display: none;
}
.indicatorScrollRight {
  width: 6px;
  position: relative;
}
.indicatorScrollRight i {
  width: 4px;
  padding: 0;
  position: absolute;
  left: -5px;
  top: 2px;
}
</style>