<template>
  <div class="mb-2">

    <b-row class="mr-0 ml-0" align-h="between">
  <b-col cols="4" class="pr-0 d-flex pl-0 ">
    <div class="border border-dark border-radius-default position-relative demoBlock m-auto d-flex flex-column justify-content-between">
      <div class="d-flex justify-content-center pt-1">
        <i ref="borderRadiusDemoAngle1" class="mdi mdi-border-top-variant text-secondary line-height-12"></i>
      </div>
      <div class="d-flex justify-content-between">
        <i ref="borderRadiusDemoAngle2" class="mdi mdi-border-left-variant text-secondary line-height-12" ></i>
        <i ref="borderRadiusDemoAngle3" class="mdi mdi-border-right-variant text-secondary line-height-12"></i>
      </div>
      <div class="d-flex justify-content-center">
        <i ref="borderRadiusDemoAngle4" class="mdi mdi-border-bottom-variant text-secondary line-height-12"></i>
      </div>
    </div>
  </b-col>
  <b-col cols="8" class="pr-0">
    <b-form-group :class="'mb-0 ' + getClassOpacity" label-cols="4" label="width">
          <b-input-group
              size="sm"
              @mouseenter="selectAngle(5)"
              @mouseleave="selectAngle(0)"
              prepend-html="<i class='mdi mdi-border-all-variant'></i>"
          >

            <b-form-input
                type="number"
                class="propField mousetrap "
                maxlength = "11"
                v-model="valueForBorderWeightAll"
            >
            </b-form-input>

            <multiselect
                track-by="code"
                label="name"
                v-model = "valueForBorderWeightUnitAll"
                :options="prop.unit"
                class="mousetrap multiselect-sm form-control"
                :show-labels="false"
                :searchable="false"
                placeholder = "--"
            >
            </multiselect>
          </b-input-group>
        </b-form-group>

    <b-form-group class="mb-0" label-cols="4" label="color">
        <ColorWidget
            :class="(valueForBorderColorAll === null ? 'selfAngle' : '')"
            v-model="valueForBorderColorAll"
        />
    </b-form-group>

    <b-form-group :class="'mb-0 ' + (valueForBorderStyleAll === null ? 'selfAngle' : '')" label-cols="4" label="style">
      <b-form-radio-group
          v-model="valueForBorderStyleAll"
          :options="prop.options"
          buttons
          size="sm"
          button-variant="outline-secondary-2"
          value-field="code"
          class="bg-white zi-1 "
      ></b-form-radio-group>
    </b-form-group>
  </b-col>
</b-row>

<!--    TOP -->
    <div class="d-flex mt-2 mb-1 pl-0">
      <b-form-group class="mb-0" >
        <b-input-group
            :class = "valueForBorderWeightAll !== null ? 'selfAngle' : ''"
            size="sm"
            @mouseenter="selectAngle(5)"
            @mouseleave="selectAngle(0)"
            prepend-html="<i class='mdi mdi-border-top-variant'></i>"
        >

          <b-form-input
              type="number"
              class="propField mousetrap"
              maxlength = "11"
              v-model="valueForBorderWeightTop"
          >
          </b-form-input>

          <multiselect
              track-by="code"
              label="name"
              v-model = "valueForBorderWeightUnitTop"
              :options="prop.unit"
              class="mousetrap multiselect-sm form-control"
              :show-labels="false"
              :searchable="false"
              placeholder = "--"
          >
          </multiselect>
        </b-input-group>
      </b-form-group>

      <b-form-radio-group
        v-model="valueForBorderStyleTop"
        :options="prop.options"
        buttons
        size="sm"
        button-variant="outline-secondary-2"
        value-field="code"
        :class="'bg-white zi-1 ml-2 ' + (valueForBorderStyleAll !== null ? 'selfAngle' : '')"
      ></b-form-radio-group>

      <ColorWidget
          :class="'ml-2 ' + (valueForBorderColorAll !== null ? 'selfAngle' : '')"
          v-model="valueForBorderColorTop"
      />
    </div>
<!-- BOTTOM -->
    <div class="d-flex mt-0 mb-1 pl-0">
      <b-form-group class="mb-0" >
        <b-input-group
            :class = "valueForBorderWeightAll !== null ? 'selfAngle' : ''"
            size="sm"
            @mouseenter="selectAngle(5)"
            @mouseleave="selectAngle(0)"
            prepend-html="<i class='mdi mdi-border-bottom-variant'></i>"
        >

          <b-form-input
              type="number"
              class="propField mousetrap"
              maxlength = "11"
              v-model="valueForBorderWeightBottom"
          >
          </b-form-input>

          <multiselect
              track-by="code"
              label="name"
              v-model = "valueForBorderWeightUnitBottom"
              :options="prop.unit"
              class="mousetrap multiselect-sm form-control"
              :show-labels="false"
              :searchable="false"
              placeholder = "--"
          >
          </multiselect>
        </b-input-group>
      </b-form-group>

      <b-form-radio-group
          v-model="valueForBorderStyleBottom"
          :options="prop.options"
          buttons
          size="sm"
          button-variant="outline-secondary-2"
          value-field="code"
          :class="'bg-white zi-1 ml-2 ' + (valueForBorderStyleAll !== null ? 'selfAngle' : '')"
      ></b-form-radio-group>

      <ColorWidget
          :class="'ml-2 ' + (valueForBorderColorAll !== null ? 'selfAngle' : '')"
          v-model="valueForBorderColorBottom"
      />
    </div>
<!-- LEFT -->
    <div class="d-flex mt-0 mb-1 pl-0">
      <b-form-group class="mb-0" >
        <b-input-group
            :class = "valueForBorderWeightAll !== null ? 'selfAngle' : ''"
            size="sm"
            @mouseenter="selectAngle(5)"
            @mouseleave="selectAngle(0)"
            prepend-html="<i class='mdi mdi-border-left-variant'></i>"
        >

          <b-form-input
              type="number"
              class="propField mousetrap"
              maxlength = "11"
              v-model="valueForBorderWeightLeft"
          >
          </b-form-input>

          <multiselect
              track-by="code"
              label="name"
              v-model = "valueForBorderWeightUnitLeft"
              :options="prop.unit"
              class="mousetrap multiselect-sm form-control"
              :show-labels="false"
              :searchable="false"
              placeholder = "--"
          >
          </multiselect>
        </b-input-group>
      </b-form-group>

      <b-form-radio-group
          v-model="valueForBorderStyleLeft"
          :options="prop.options"
          buttons
          size="sm"
          button-variant="outline-secondary-2"
          value-field="code"
          :class="'bg-white zi-1 ml-2 ' + (valueForBorderStyleAll !== null ? 'selfAngle' : '')"
      ></b-form-radio-group>

      <ColorWidget
          :class="'ml-2 ' + (valueForBorderColorAll !== null ? 'selfAngle' : '')"
          v-model="valueForBorderColorLeft" />
    </div>
<!-- RIGHT -->
    <div class="d-flex mt-0 mb-1 pl-0">
      <b-form-group class="mb-0" >
        <b-input-group
            :class = "valueForBorderWeightAll !== null ? 'selfAngle' : ''"
            size="sm"
            @mouseenter="selectAngle(5)"
            @mouseleave="selectAngle(0)"
            prepend-html="<i class='mdi mdi-border-right-variant'></i>"
        >

          <b-form-input
              type="number"
              class="propField mousetrap"
              maxlength = "11"
              v-model="valueForBorderWeightRight"
          >
          </b-form-input>

          <multiselect
              track-by="code"
              label="name"
              v-model = "valueForBorderWeightUnitRight"
              :options="prop.unit"
              class="mousetrap multiselect-sm form-control"
              :show-labels="false"
              :searchable="false"
              placeholder = "--"
          >
          </multiselect>
        </b-input-group>
      </b-form-group>

      <b-form-radio-group
          v-model="valueForBorderStyleRight"
          :options="prop.options"
          buttons
          size="sm"
          button-variant="outline-secondary-2"
          value-field="code"
          :class="'bg-white zi-1 ml-2 ' + (valueForBorderStyleAll !== null ? 'selfAngle' : '')"
      ></b-form-radio-group>

      <ColorWidget
          :class="'ml-2 ' + (valueForBorderColorAll !== null ? 'selfAngle' : '')"
          v-model="valueForBorderColorRight"
      />
    </div>
  </div>
</template>

<script>
import PropsHelper from "@/helpers/constructor/sideBar/props/PropsComputed";
import HistoryHelper from '@/helpers/constructor/HistoryHelper'
import Multiselect from "vue-multiselect";
import MethodsForChangePropsElem from "@/helpers/constructor/MethodsForChangePropsElem";
import ColorWidget from "./ColorWidget";

export default {
  name: "CssTransformWidget",
  components: { Multiselect, ColorWidget },
  props: ['nameProp', 'prop', 'elem', 'tagData', 'indexElem', 'settingConstructor'],
  data: function(){
    return {
      blockProps: 'css'
    }
  },
  computed: {
    ...PropsHelper,
    getClassOpacity: function () {
      return this.valueForBorderWeightAll === null ? 'selfAngle' : ''
    },
    // ALL
    valueForBorderWeightAll: {
      get: function () {
        if (
            ( this.valueForBorderWeightTop !== null ) &&
            ( this.valueForBorderWeightTop === this.valueForBorderWeightBottom ) &&
            ( this.valueForBorderWeightBottom === this.valueForBorderWeightLeft ) &&
            ( this.valueForBorderWeightLeft === this.valueForBorderWeightRight )
        ) {
          return this.valueForBorderWeightTop
        }
        return null
      },
      set: function (value) {
        this.valueForBorderWeightTop = value
        this.valueForBorderWeightBottom = value
        this.valueForBorderWeightLeft = value
        this.valueForBorderWeightRight = value
      }
    },
    valueForBorderWeightUnitAll: {
      get: function () {
        if (
            ( this.valueForBorderWeightUnitTop !== null ) &&
            ( this.valueForBorderWeightUnitTop.code === this.valueForBorderWeightUnitBottom.code ) &&
            ( this.valueForBorderWeightUnitBottom.code === this.valueForBorderWeightUnitLeft.code ) &&
            ( this.valueForBorderWeightUnitLeft.code === this.valueForBorderWeightUnitRight.code )
        ) {
          return this.prepareDataForMultiselect(this.getValPropNumbUnit('borderTopWidth'))
        }
        return null
      },
      set: function (value) {
        this.valueForBorderWeightUnitTop = value
        this.valueForBorderWeightUnitBottom = value
        this.valueForBorderWeightUnitLeft = value
        this.valueForBorderWeightUnitRight = value
      }
    },
    valueForBorderStyleAll: {
      get: function () {
        if (
            ( this.valueForBorderStyleTop !== null ) &&
            ( this.valueForBorderStyleTop === this.valueForBorderStyleBottom ) &&
            ( this.valueForBorderStyleBottom === this.valueForBorderStyleLeft ) &&
            ( this.valueForBorderStyleLeft === this.valueForBorderStyleRight )
        ) {
          return this.valueForBorderStyleTop
        }
        return null
      },
      set: function (value) {
        if (value === null) {
          return
        }
        this.valueForBorderStyleTop = value
        this.valueForBorderStyleBottom = value
        this.valueForBorderStyleLeft = value
        this.valueForBorderStyleRight = value
      }
    },
    valueForBorderColorAll: {
      get: function () {
        if (
            ( this.valueForBorderColorTop !== null ) &&
            ( this.valueForBorderColorTop === this.valueForBorderColorBottom ) &&
            ( this.valueForBorderColorBottom === this.valueForBorderColorLeft ) &&
            ( this.valueForBorderColorLeft === this.valueForBorderColorRight )
        ) {
          return this.getValProp('borderTopColor')
        }
        return null
      },
      set: function (value) {
        this.valueForBorderColorTop = value
        this.valueForBorderColorBottom = value
        this.valueForBorderColorLeft = value
        this.valueForBorderColorRight = value
      }
    },
    // TOP
    valueForBorderWeightTop: {
      get: function () {
        return this.getValPropNumb('borderTopWidth')
      },
      set: function (value) {
        this.setValPropNumb(value, 'borderTopWidth')
      }
    },
    valueForBorderWeightUnitTop: {
      get: function () {
        return this.prepareDataForMultiselect(this.getValPropNumbUnit('borderTopWidth'))
      },
      set: function (value) {
        this.setValPropNumbUnit(value.code, 'borderTopWidth')
      }
    },
    valueForBorderStyleTop: {
      get: function () {
        return this.getValProp('borderTopStyle')
      },
      set: function (value) {
        this.setValProp(value, 'borderTopStyle')
      }
    },
    valueForBorderColorTop: {
      get: function () {
        return this.getValProp('borderTopColor')
      },
      set: function (value) {
        this.setValProp(value, 'borderTopColor')
      }
    },

    // Bottom
    valueForBorderWeightBottom: {
      get: function () {
        return this.getValPropNumb('borderBottomWidth')
      },
      set: function (value) {
        this.setValPropNumb(value, 'borderBottomWidth')
      }
    },
    valueForBorderWeightUnitBottom: {
      get: function () {
        return this.prepareDataForMultiselect(this.getValPropNumbUnit('borderBottomWidth'))
      },
      set: function (value) {
        this.setValPropNumbUnit(value.code, 'borderBottomWidth')
      }
    },
    valueForBorderStyleBottom: {
      get: function () {
        return this.getValProp('borderBottomStyle')
      },
      set: function (value) {
        this.setValProp(value, 'borderBottomStyle')
      }
    },
    valueForBorderColorBottom: {
      get: function () {
        return this.getValProp('borderBottomColor')
      },
      set: function (value) {
        this.setValProp(value, 'borderBottomColor')
      }
    },

    // LEFT
    valueForBorderWeightLeft: {
      get: function () {
        return this.getValPropNumb('borderLeftWidth')
      },
      set: function (value) {
        this.setValPropNumb(value, 'borderLeftWidth')
      }
    },
    valueForBorderWeightUnitLeft: {
      get: function () {
        return this.prepareDataForMultiselect(this.getValPropNumbUnit('borderLeftWidth'))
      },
      set: function (value) {
        this.setValPropNumbUnit(value.code, 'borderLeftWidth')
      }
    },
    valueForBorderStyleLeft: {
      get: function () {
        return this.getValProp('borderLeftStyle')
      },
      set: function (value) {
        this.setValProp(value, 'borderLeftStyle')
      }
    },
    valueForBorderColorLeft: {
      get: function () {
        return this.getValProp('borderLeftColor')
      },
      set: function (value) {
        this.setValProp(value, 'borderLeftColor')
      }
    },

    // RIGHT
    valueForBorderWeightRight: {
      get: function () {
        return this.getValPropNumb('borderRightWidth')
      },
      set: function (value) {
        this.setValPropNumb(value, 'borderRightWidth')
      }
    },
    valueForBorderWeightUnitRight: {
      get: function () {
        return this.prepareDataForMultiselect(this.getValPropNumbUnit('borderRightWidth'))
      },
      set: function (value) {
        this.setValPropNumbUnit(value.code, 'borderRightWidth')
      }
    },
    valueForBorderStyleRight: {
      get: function () {
        return this.getValProp('borderRightStyle')
      },
      set: function (value) {
        this.setValProp(value, 'borderRightStyle')
      }
    },
    valueForBorderColorRight: {
      get: function () {
        return this.getValProp('borderRightColor')
      },
      set: function (value) {
        this.setValProp(value, 'borderRightColor')
      }
    },
  },
  methods: {
    ...HistoryHelper,
    ...MethodsForChangePropsElem,
    selectAngle: function (numb) {
      if (numb === 0) {
        this.$refs.borderRadiusDemoAngle1.classList.remove('angleActive')
        this.$refs.borderRadiusDemoAngle2.classList.remove('angleActive')
        this.$refs.borderRadiusDemoAngle3.classList.remove('angleActive')
        this.$refs.borderRadiusDemoAngle4.classList.remove('angleActive')
        return
      }
      if (numb < 5) {
        this.$refs['borderRadiusDemoAngle' + numb ].classList.add('angleActive')
      } else if (numb === 5) {
        this.$refs.borderRadiusDemoAngle1.classList.add('angleActive')
        this.$refs.borderRadiusDemoAngle2.classList.add('angleActive')
        this.$refs.borderRadiusDemoAngle3.classList.add('angleActive')
        this.$refs.borderRadiusDemoAngle4.classList.add('angleActive')
      }
    }
  }
}
</script>

<style scoped>
  .containerBorderRadius input, .borderRadiusBlock input{
    width: 50px;
  }
  .demoBlock {
    width: 60px;
    height: 60px;
  }


  .selfAngle {
    opacity: 0.5;
  }
  .selfAngle:hover {
    opacity: 1;
  }
  .angleActive {
    color: #5664d2 !important;
  }
</style>
