<template>
  <b-sidebar
      id="sidebar-gallery"
      v-model="show"
      title=""
      width="40%"
      left
      backdrop-variant="dark"
      shadow
      bg-variant="white"
      @shown="shown"
      @hidden="hidden"
  >
    <b-tabs v-model="tabIndex" content-class=" p-3 text-muted" nav-class="mr-3 ml-3" align="left">
      <b-tab active class="border-0">
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block">{{$t('Constructor.Gallery.Tabs.YoyGallery')}}</span>
        </template>

        <userGallery
            @setResponseImage="setResponseImage"
        />
      </b-tab>

      <b-tab lazy>
        <template v-slot:title>
<!--          <ico style='background: url("https://images.pexels.com/lib/api/pexels.png")'></ico>-->
          <span class="d-none d-sm-inline-block">
            {{$t('Constructor.Gallery.Tabs.Pixels')}}
          </span>
        </template>
        <pixelsGallery @setResponseImage="setResponseImage" />
      </b-tab>

      <b-tab lazy>
        <template v-slot:title>
          <span class="d-none d-sm-inline-block">
            {{$t('Constructor.Gallery.Tabs.Icons')}}
          </span>
        </template>
        <iconsGallery @setResponseImage="setResponseImage" />
      </b-tab>

      <b-tab lazy>
        <template v-slot:title>
          <span class="d-none d-sm-inline-block">
            {{$t('Constructor.Gallery.Tabs.Images')}}
          </span>
        </template>
        <imageGallery @setResponseImage="setResponseImage" />
      </b-tab>

    </b-tabs>
  </b-sidebar>
</template>

<script>
import userGallery from "./_userGallery";
import pixelsGallery from "./_pixelsGallery";
import iconsGallery from "./_iconsGallery";
import imageGallery from "./_imageGallery";

export default {
  name: "gallery",
  components: { userGallery, pixelsGallery, iconsGallery, imageGallery },
  data: function () {
    return {
      tabIndex: 0,
      show: false
    }
  },
  // computed: {
  //   requestImage: function() {
  //     if (!this.$store.state.userGallery.requestImage) {
  //       return false
  //     }
  //     return true
  //   }
  // },
  mounted() {

  },
  methods: {
    shown() {
      if (this.$store.state.userGallery.requestImage === 'svg') {
        this.tabIndex = 2
      }

      this.$store.dispatch('userGallery/getGallery') //preload user gallery
    },
    hidden() {
      this.$store.commit('userGallery/setRequestImage', false)
    },
    setResponseImage ( img ) {
      this.$store.commit('userGallery/setResponseImage', img)
      this.show = false
    }
  }
}
</script>

<style scoped>

</style>
