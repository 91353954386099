<template>

    <b-form-input
        :placeholder="$t('General.Form.NoSet')"
        v-model="text"
        type="text"
        :required="false"
        :class=classInput
    >
    </b-form-input>

</template>

<script>
import TagDataGetElemsHelper from "@/helpers/constructor/TagDataGetElemsHelper";
import HistoryHelper from "@/helpers/constructor/HistoryHelper";
import TagDataEditService from "@/helpers/constructor/TagDataEditService";

export default {
  name: "TextEditor",
  props: [ "tagData", "textElemId", "classInput", "settingConstructor" ],
  data: function() {
    return {
      // textId: null, // временное решение, пока нет нормального виджета
    }
  },
  computed: {
    text: {
      get: function () {
        if (!this.tagData.htmlElements[this.textId]) {
          return ''
        }
        return this.tagData.htmlElements[this.textId].main.content;
      },
      set: function (text) {
        this.HistorySet(this.tagData.htmlElements[this.textId].main, 'content', text)
      }
    },
    textId: function() {
      let textElems = this.getChildElem(this.textElemId)
      for (let id in textElems) {
       return id
      }
      return null
    }
  },
  methods: {
    ...TagDataGetElemsHelper,
    ...TagDataEditService,
    ...HistoryHelper,
  }
}
</script>

<style scoped>

</style>