<template>
  <div>
    <b-row align-h="between">
      <b-col>
        <a target="_blank" class="font-size-12 text-dark link-outline-solid"  href="https://www.pexels.com">
          <img src="https://images.pexels.com/lib/api/pexels.png" height="16px" class="mr-1" />
          {{$t('Constructor.Gallery.PixelsGallery.Cooperate')}}
        </a>
      </b-col>
      <b-col>
        <b-input-group size="sm" :prepend="$t('General.Base.Search')">
          <b-form-input
              v-model="query"
              :placeholder="$t('Constructor.Gallery.PixelsGallery.Placeholder')"
              :title="$t('Constructor.Gallery.PixelsGallery.Title')"
              v-b-tooltip.hover.ds1000.dh0
              debounce="600"
          ></b-form-input>
          <b-spinner v-if="queryLoading" class="spinnerInInput" small variant="primary" role="status"></b-spinner>
        </b-input-group>
      </b-col>
    </b-row>

    <loading v-if="pixelMainImg === null"  />

    <div v-if="pixelMainImg !== null && !pixelMainImg.length">
      <h5 class="text-center mt-4">{{$t('General.Base.NothingFound')}}</h5>
    </div>

    <div v-if="pixelMainImg !== null && pixelMainImg.length" class="row mt-2">
      <div class="col-4 p-2"
           v-for="(img, index) in pixelMainImg"
           :key="index"
      >
        <b-img
            fluid-grow
            :src="img.src.tiny"
            alt="Image 1">
        </b-img>

<!--        <b-row align-h="end" class="pr-2">-->
<!--            <b-button squared variant="light" @click="copyImg(img)" size="sm">-->
<!--              <i class="far fa-save"></i>-->
<!--            </b-button>-->
<!--        </b-row>-->

        <div class="blockSelectImg text-right">
<!--          <b-button-->
<!--              squared-->
<!--              variant="light"-->
<!--              @click="copyImg(img)"-->
<!--              size="sm"-->
<!--              v-b-tooltip.hover.ds1000.dh0.manual-->
<!--              :title="$t('Constructor.Gallery.PixelsGallery.SaveToGallery')"-->
<!--          >-->
<!--            <i class="far fa-save"></i>-->
<!--          </b-button>-->
        </div>

        <div  v-if="requestImage" class="blockSelectImg w-100">
          <b-button squared variant="light" size="sm" class="w-100" v-on:click.stop="setResponseImage(img)" >{{$t('General.Base.Select')}}</b-button>
        </div>
        <div class="text-right">
          <a :href="img.photographer_url" target="_blank" class="font-size-11 text-dark link-outline-solid">{{img.photographer}}</a>
        </div>
      </div>

      <b-pagination
          class="mt-2 ml-auto mr-auto"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          aria-controls="my-table"
          limit="10"
      ></b-pagination>

    </div>
  </div>
</template>

<script>
import PixelsApi from "@/helpers/fakebackend/PixelsApi";
import loading from "./_loading";

export default {
  name: "pixelsGallery",
  components: { loading },
  // prop: [ 'requestImage' ],
  data() {
    return {
      PixelsApi: new PixelsApi(),
      pixelMainImg: null,
      query: '',
      queryLoading: false,
      curated: [],
      currentPage: 1,
      totalRows: null,
      perPage: 9
    };
  },
  computed: {
    requestImage: function() {
      if (!this.$store.state.userGallery.requestImage) {
        return false
      }
      return true
    }
  },
  mounted() {
    this.getCurated()
  },
  watch: {
    currentPage: function() {
      if (this.query === '') {
        this.getCurated()
      } else {
        this.searchImg()
      }
    },
    query: function(val) {
      this.currentPage = 1
      if (val === '') {
        this.pixelMainImg = this.curated
      } else {
        this.searchImg()
      }
    }
  },
  methods: {
    copyImg(img) {
      let resurse = {
        type: 'pixels',
        id: img.id
      }
      this.$store.dispatch('userGallery/copyImg', resurse)
    },
    getCurated: function() {
      let data = {
        per_page: this.perPage,
        page: this.currentPage
      }
      this.PixelsApi.getCurated(data).then(data => {
        this.pixelMainImg = data.photos
        this.curated = data.photos
        this.totalRows = data.total_results
      })
    },
    searchImg: function() {
      this.queryLoading = true
      let data = {
        per_page: this.perPage,
        query: this.query,
        page: this.currentPage
      }
      this.PixelsApi.search(data).then(data => {
        this.pixelMainImg = data.photos
        this.queryLoading = false
        this.totalRows = data.total_results
      })
    },
    setResponseImage ( img ) {
      this.$emit('setResponseImage', {
        provider: 'pixels',
        imageId: img.id,
        url: img.src.large2x,
        urlProvider: img.src.large2x,
        urlOriginal: img.src.original,
        urlThumb: img.src.original + '?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=200'
      })
    },
  }
}
</script>

<style scoped>

</style>
