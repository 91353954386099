<template>
  <div
      v-if="elem.active"
      :class=classContainer
      :style="styleContainer"
      ref="elemContainer"
      onselectstart="return false"
      ondragstart="() => { return false }"
      @mousedown.stop="onMouseDown"
      @mousemove="containerMove()"
      draggable="false"
  >
<!--    @mouseup="containerClick()"-->
<!--    //id="elemContainer"-->
    <!--    <i v-if="elem.resizable && elem.active" @mousedown="resizeService.dragonStart(1)" class="mdi mdi-arrow-bottom-left arrowBottomLeft"></i>-->
    <!--    <i v-if="elem.resizable && elem.active" @mousedown="resizeService.dragonStart(3)" class="mdi mdi-arrow-bottom-right arrowBottomRight"></i>-->
    <!--    <i v-if="elem.resizable && elem.active" @mousedown="resizeService.dragonStart(7)" class="mdi mdi-arrow-top-left arrowTopLeft"></i>-->
    <!--    <i v-if="elem.resizable && elem.active" @mousedown="resizeService.dragonStart(9)" class="mdi mdi-arrow-top-right arrowTopRight"></i>-->

<!--    <div class="elemContainerInfoParent">123</div>-->

    <div v-if="elem.active && !elemSingle" class="elemLineVertical"></div>
    <div v-if="elem.active && !elemSingle" class="elemLineHorizontal"></div>
    <div v-if="elem.active && !elemSingle" class="elemLineVertical2"></div>
    <div v-if="elem.active && !elemSingle" class="elemLineHorizontal2"></div>



    <i
        v-if="elem.active && !elemSingle && elem.draggable && checkPossibilityDraggable"
        class="mdi mdi-arrow-all arrowAll"
        @mouseenter = "hoverControlButton = true"
        @mouseleave = "hoverControlButton = false"
    ></i>
<!--    <i-->
<!--        v-if="elem.active && !elemSingle && (!elem.draggable || !checkPossibilityDraggable)"-->
<!--        class="mdi mdi-axis-arrow-lock arrowAll"-->
<!--        @mouseenter = "hoverControlButton = true"-->
<!--        @mouseleave = "hoverControlButton = false"-->
<!--    ></i>-->

<!-- elemSingle   draggable_ico-->
  </div>
</template>

<script>
import {ContainerHelperMethods} from "@/helpers/constructor/constructor/ContainerHelper";
import { computedElemHelper } from '@/helpers/constructor/constructor/ElemHelper'
import TagDataEditService from "@/helpers/constructor/TagDataEditService";
import HistoryHelper from '@/helpers/constructor/HistoryHelper'
import {isArray} from "lodash";
import { PatternElemsSettings } from '@/helpers/constructor/patterns/PatternElemsSettings'

export default {
  name: "elemContainer",
  props: ["tagData", "settingConstructor", "elemSingle", "elem_id"],
  data: function () {
    return {
      // resizeService: new ResizeElemHelper(this),
      clickChangeActiveElem: true,
      hoverControlButton: false,
      indent: 0,
      drag: false,
      dragSettings: {
        startCoordinates: {},
        elemContTrue: null,
        startCoordinatesParentElemTrue: {},
        // diffCoordElemTrue: {
        //   x: 0,
        //   y: 0
        // }
      },
      backlightBlock: null,
      factParam: {
        width: 70,
        height: 70,
        x: null,
        y: null
      },
      oldValueCord: {
        top: null,
        left: null
      },
      dragonStopCause: null
    }
  },
  watch: {
    elem_id: function() {
      this.dragSettings.elemContTrue = null
      this.calculateFactSizeElem()
    }
  },
  computed: {
    ...computedElemHelper,
    elem: function() {
      if (this.elemSingle) {
        return this.elemSingle
      }
      return this.tagData.htmlElements[this.elem_id]
    },
    classContainer: function () {
      let data =  ['elemContainer', ...this.elem.devBlock.class]

      if (this.elem.active && !this.elemSingle) {
        data.push('active')
      }
      if (this.elemSingle) {
        data.push('elemSingle')
      }
      if (this.elem.active && this.elem.draggable) {
        data.push('grabCursor')
      }
      if (this.settingConstructor.drags.drag) {
        data.push('grabbingCursor')
      }
      return data
    },
    styleContainer: function() {
      let addCss = {}
      if (this.elemSingle) {
        addCss =  {
          // 'background-color': "#fff",
          'background-image': 'url(' + this.elemSingle.thumb + ')',
          'background-repeat': 'no-repeat',
          'background-position': 'center',
          'background-size': 'contain'
        }
      }

      return {
        ...addCss,
        'height': this.factParam.height + this.indent * 2 + 'px',
        'width': this.factParam.width + this.indent * 2 + 'px',
        'top': this.factParam.y - this.indent + 'px',
        'left': this.factParam.x - this.indent + 'px',
      }
    },
    checkPossibilityDraggable: function() {
      //@TODO: ДОбавить поддержку right и bottom
      if (this.elemAddCss.left === undefined) {
        this.setDragonStopCause('leftNoSet')
        return false
      }
      if (this.elemAddCss.left.unit !== 'px' && this.elemAddCss.left.unit !== '%' ) {
        this.setDragonStopCause('noPxLeft')
        return false
      }
      if (this.elemAddCss.top === undefined) {
        this.setDragonStopCause('topNoSet')
        return false
      }
      if (this.elemAddCss.top.unit !== 'px' && this.elemAddCss.left.unit !== '%') {
        this.setDragonStopCause('noPxTop')
        return false
      }

      if (!this.elemSingle && this.elemAddCss.position === 'relative') {
        this.setDragonStopCause('positionRelative')
        return false
      }
      return true
    }
  },
  methods: {
    ...TagDataEditService,
    ...ContainerHelperMethods,
    ...HistoryHelper,
    setDragonStopCause(cause) {
      this.dragonStopCause = cause
    },
    onMouseDown(event) {
      if (event.which !== 1) {
        return
      }
      this.clickChangeActiveElem = true

      if (
          this.settingConstructor.drags.drag ||
          !this.elem.draggable ||
          !this.elem.active ||
          !this.checkPossibilityDraggable
      ) {
        return false
      }

      this.settingConstructor.drags.drag = true
      this.drag = true

      this.dragSettings.elemContTrue = document.getElementById('elemContTrue_' + this.elem_id)

      let targetCoord
      if (this.elemSingle) {
        this.elem.previewCss.position = 'absolute'
        this.dragSettings.elemContTrue.style.zIndex = 2000

        targetCoord = this.dragSettings.elemContTrue.getBoundingClientRect()
        // this.settingConstructor.drags.startCoordinates = this.getCoordsParent(event.target) // получаем кординты родителя, #constructorArea получается

        let workspaceParent = document.getElementById('workspaceParent')
        workspaceParent.appendChild(this.dragSettings.elemContTrue)
        workspaceParent.appendChild(this.$refs.elemContainer)

        this.settingConstructor.drags.startCoordinates = this.getCoordsParent(event.target) // получаем кординты родителя, #constructorArea получается
        this.settingConstructor.drags.startCoordinates.x--
        this.settingConstructor.drags.startCoordinates.y--
      } else {
        targetCoord = this.dragSettings.elemContTrue.getBoundingClientRect() // кординаты настоящего элемента, считаем сдвиг
        this.updateConstructorAreaCoord()
      }




      this.settingConstructor.drags.shift.x = event.pageX - targetCoord.left
      this.settingConstructor.drags.shift.y = event.pageY - targetCoord.top

      this.dragSettings.startCoordinatesParentElemTrue = this.getCoordsParent(this.dragSettings.elemContTrue) // кординаты родителя настоящего элемента, считаем сдвиг

      if (!this.elemSingle) {
        // this.dragSettings.diffCoordElemTrue.x = -(event.pageX - this.dragSettings.startCoordinatesParentElemTrue.x - this.settingConstructor.drags.shift.x - this.elemAddCss.left.val)
        // this.dragSettings.diffCoordElemTrue.y = -(event.pageY - this.dragSettings.startCoordinatesParentElemTrue.y - this.settingConstructor.drags.shift.y - this.elemAddCss.top.val)
      } else {
        // this.dragSettings.diffCoordElemTrue.x =  1
        // this.dragSettings.diffCoordElemTrue.y =  1
        this.changeCoordElem(event)
      }

      this.oldValueCord.left = this.elemAddCss.left.val
      this.oldValueCord.top = this.elemAddCss.top.val

      document.addEventListener('mousemove', this.onMouseMove)
      document.addEventListener('mouseup', this.onMouseUp)

      this.$emit('onDragStart', event, this.elem)
      return false
    },
    onMouseMove(event) {
      if (!this.settingConstructor.drags.drag) {
        return false
      }

      event.stopPropagation()

      this.clickChangeActiveElem = false
      this.changeCoordElem(event)

      // if (this.settingConstructor.activeMainElemId === this.elem_id ) {
      //   return
      // }

      if (this.elem.changeParent === false) {
        return
      }

      if (this.settingConstructor.workspace.left > event.pageX || this.settingConstructor.workspace.right < event.pageX ||
          this.settingConstructor.workspace.top > event.pageY || this.settingConstructor.workspace.bottom < event.pageY) {
        if (this.backlightBlock !== null) this.disableBacklight()
        return
      }

      // если будет меняться вложенность, возможно должно быть настройкой
      this.$refs.elemContainer.hidden = true
      this.dragSettings.elemContTrue.hidden = true
      let elemBelow = document.elementFromPoint(event.clientX, event.clientY)

      this.$refs.elemContainer.hidden = false
      this.dragSettings.elemContTrue.hidden = false

      let idParent = this.settingConstructor.activeMainElemId

      if (elemBelow.dataset.id === undefined) {
        return
      }
      if (elemBelow.dataset.poschild === '0') { // poschild не может иметь детей
        return
      }
      idParent = elemBelow.dataset.id

      if (PatternElemsSettings[this.tagData.htmlElements[idParent].name] !== undefined &&
          isArray(PatternElemsSettings[this.tagData.htmlElements[idParent].name].possibleChild) &&
          !PatternElemsSettings[this.tagData.htmlElements[idParent].name].possibleChild.includes(this.elem.tag)) {
        return
      }

      if (this.backlightBlock === idParent) {
        return
      }

      if (this.backlightBlock !== null) {
        this.$delete(
            this.tagData.htmlElements[this.backlightBlock].devBlock.classBlockTrue,
            this.tagData.htmlElements[this.backlightBlock].devBlock.classBlockTrue.indexOf('backlightBlock')
        )
      }
      this.tagData.htmlElements[idParent].devBlock.classBlockTrue.push('backlightBlock')
      this.backlightBlock = idParent
    },
    onMouseUp(event) {
      if (!this.settingConstructor.drags.drag) { return false }

      this.settingConstructor.drags.drag = false
      this.drag = false

      document.removeEventListener('mousemove', this.onMouseMove);
      document.removeEventListener('mouseup',  this.onMouseUp);

      if (this.backlightBlock !== null) {
        if (this.elem.parent !== this.backlightBlock && !this.elemSingle) {
          this.TDESchangeElemTree(this.elem_id, this.backlightBlock)

          const cordNew = document.getElementById('elemContTrue_' + this.backlightBlock).getBoundingClientRect()
          this.dragSettings.startCoordinatesParentElemTrue.x = cordNew.left
          this.dragSettings.startCoordinatesParentElemTrue.y = cordNew.top

          // this.dragSettings.diffCoordElemTrue.x = 0
          // this.dragSettings.diffCoordElemTrue.y = 0
        }

        this.changeCoordElem(event)
      }

      if (this.oldValueCord.left !== parseFloat(this.dragSettings.elemContTrue.style.left) || this.oldValueCord.top !== parseFloat(this.dragSettings.elemContTrue.top.val)) {

        this.HistorySetPackage([
          {obj: this.elemAddCss.left, nameProp: 'val', val: parseFloat(this.dragSettings.elemContTrue.style.left), type: 'set', oldValue: this.oldValueCord.left},
          {obj: this.elemAddCss.top, nameProp: 'val', val: parseFloat(this.dragSettings.elemContTrue.style.top), type: 'set', oldValue: this.oldValueCord.top}
        ])
      }


      this.$emit('onDragEnd', event, this.elem, this.elem_id, this.backlightBlock)
      if (this.backlightBlock !== null) {
        this.disableBacklight()
      }
    },
    disableBacklight() {
      this.$delete(
          this.tagData.htmlElements[this.backlightBlock].devBlock.classBlockTrue,
          this.tagData.htmlElements[this.backlightBlock].devBlock.classBlockTrue.indexOf('backlightBlock')
      )
      this.backlightBlock = null
    },
    changeCoordElem(event) {
      if (this.settingConstructor.desktopMode !== 'default') {
        if (this.elemAddCss.left!== undefined && this.elemAddCssReal.left === undefined) {
          this.$set(this.elemAddCssReal, 'left', this.elemAddCss.left)
        }
        if (this.elemAddCss.top!== undefined && this.elemAddCssReal.top === undefined) {
          this.$set(this.elemAddCssReal, 'top', this.elemAddCss.top)
        }
      }

//@TODO: поддержка bottom и right, или обработка ошибки

      let coordLeft = event.pageX - this.dragSettings.startCoordinatesParentElemTrue.x - this.settingConstructor.drags.shift.x
      let coordTop = event.pageY - this.dragSettings.startCoordinatesParentElemTrue.y - this.settingConstructor.drags.shift.y
      if (this.elemAddCss.left.unit === '%') {
        coordLeft = coordLeft * 100 / this.dragSettings.startCoordinatesParentElemTrue.width
      }
      if (this.elemAddCss.top.unit === '%') {
        coordTop = coordTop * 100 / this.dragSettings.startCoordinatesParentElemTrue.height
      }

      this.dragSettings.elemContTrue.style.left = coordLeft.toFixed(2) + this.elemAddCss.top.unit
      this.dragSettings.elemContTrue.style.top = coordTop.toFixed(2) + this.elemAddCss.top.unit

      let contLeft = event.pageX - this.settingConstructor.drags.startCoordinates.x - this.settingConstructor.drags.shift.x - 1
      let contTop = event.pageY - this.settingConstructor.drags.startCoordinates.y - this.settingConstructor.drags.shift.y
      if (!this.elemSingle) {
        contLeft -= this.settingConstructor.constructorArea.paddings
        contTop -= this.settingConstructor.constructorArea.paddings
      }


      this.$refs.elemContainer.style.left = contLeft.toFixed(2) + 'px'
      this.$refs.elemContainer.style.top = contTop.toFixed(2) + 'px'

      return {
        left: contLeft.toFixed(2),
        top: contTop.toFixed(2)
      }
    },
    getCoordsParent(elem) {
      let box = elem.parentElement.getBoundingClientRect();

      return {
        y: box.top,
        x: box.left,
        width: box.width,
        height: box.height
      };
    },
    updateConstructorAreaCoord() {
      let box =document.getElementById('constructorArea').getBoundingClientRect()
      this.settingConstructor.drags.startCoordinates.x = box.x
      this.settingConstructor.drags.startCoordinates.y = box.y
      this.settingConstructor.drags.startCoordinates.width = box.width
      this.settingConstructor.drags.startCoordinates.height = box.height
    },
    calculateFactSizeElem(elem_id) {
      if (this.elemSingle) {
        return
      }

      if (this.settingConstructor.drags.drag) {
        return
      }
      if (elem_id !== undefined && elem_id !== this.elem_id ) {
        return
      }

      const elemContTrue = document.getElementById('elemContTrue_' + this.elem_id)
      if (elemContTrue === null) {
        return
      }
      //@TODO: при перемещении элемента много раз вызывается
      const data = elemContTrue.getBoundingClientRect()

      this.factParam.width = data.width
      this.factParam.height = data.height

      const workspace = document.getElementById('workspace')

// console.log(data.x, window.scrollX, this.settingConstructor.workspace, workspace.scrollLeft)
      if (!this.elemSingle) {
        // console.log(elemContTrue.getBoundingClientRect(), data.x, window.scrollX, workspace.scrollLeft, this.settingConstructor.constructorArea.left, constructorAreaPadding)
        // console.log(data.y, window.scrollY, workspace.scrollTop, this.settingConstructor.workspace.top, constructorAreaPadding,  workspace.scrollTop)

        this.factParam.x = (data.x + window.scrollX + workspace.scrollLeft - this.settingConstructor.workspace.left - this.settingConstructor.constructorArea.paddings - 2).toFixed(2)  //+ Math.abs(this.settingConstructor.constructorArea.left)
        this.factParam.y = (data.y + window.scrollY + workspace.scrollTop - this.settingConstructor.workspace.top - this.settingConstructor.constructorArea.paddings - 1).toFixed(2) //+ Math.abs(this.settingConstructor.constructorArea.top)
      } else {
        this.factParam.x = 0
        this.factParam.y = 0
      }
    }
  },
  // updated() {
  //   console.log(5)
  //   // this.calculateFactSizeElem()
  // },
  created() {
    this.$emit('init')
  },
  mounted() {
    this.$root.$on('updateElemConstructor',  this.calculateFactSizeElem)
    this.calculateFactSizeElem()
  }
}
</script>

<style scoped>
  /*.elemContainerInfo {*/
  /*  position: absolute;*/
  /*  top: 0px;*/
  /*  right: 0px;*/

  /*}*/
</style>
