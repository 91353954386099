<template>
  <div>

    <b-alert v-if="requestImage" variant="info" class="p-2 font-size-12" show>
      {{$t('Constructor.Gallery.IconsGallery.AlertIcon')}}
    </b-alert>

    <b-row align-h="between">
      <b-col>
        <b-input-group size="sm" :prepend="$t('General.Base.Search')">
          <b-form-input
              v-model="query"
              :placeholder="$t('Constructor.Gallery.IconsGallery.Placeholder')"
              :title="$t('Constructor.Gallery.IconsGallery.Title')"
              v-b-tooltip.hover.ds1000.dh0
              debounce="600"
          ></b-form-input>
          <b-spinner v-if="queryLoading" class="spinnerInInput" small variant="primary" role="status"></b-spinner>
        </b-input-group>
      </b-col>
    </b-row>

    <loading v-if="pixelMainImg === null"  />

    <div >

      <b-row>
        <b-col cols="3">
          <b-list-group class="mt-2">
            <b-list-group-item
                v-for="(tag, index) in tags"
                :key = index
                class="border-top-0 border-left-0 border-right-0 p-1 pl-1 d-flex justify-content-between align-items-center font-size-11"
                button
                :active = "tag.tag === activeTag"
                active-class="secondary"
                @click="selectTag(tag)"
            >
              <span>
                {{tag.tag}}
              </span>
              <b-badge variant="primary" class="pt-1" pill>{{ tag.count_icon }}</b-badge>
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col cols="9">

          <div v-if="pixelMainImg !== null && !pixelMainImg.length">
            <h5 class="text-center mt-4">{{$t('General.Base.NothingFound')}}</h5>
          </div>

          <div v-if="pixelMainImg !== null && pixelMainImg.length" class="row mt-2">
            <div
                 v-for="(img, index) in pixelMainImg"
                 :key="index"
                 :class="classIcon"
                 v-html="img.svg"
                 @click = "selectIcon(img)"
            >
            </div>
          </div>

          <b-pagination
              class="mt-2 ml-auto mr-auto"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
              limit="8"
          ></b-pagination>
        </b-col>
      </b-row>
    </div>

  </div>
</template>

<script>
import loading from "./_loading";
import axios from "axios";

export default {
  name: "pixelsGallery",
  components: { loading },
  // prop: [ 'requestImage' ],
  data() {
    return {
      pixelMainImg: null,
      query: '',
      queryLoading: false,
      curated: [],
      currentPage: 1,
      totalRows: null,
      perPage: 104,
      tags: [],
      activeTag: null
    };
  },
  computed: {
    requestImage: function() {
      if (this.$store.state.userGallery.requestImage === 'svg') {
        return true
      }
      return false
    },
    classIcon: function() {
      if (this.requestImage) {
        return 'iconSvg p-1 m-2 selectedIcon'
      }
      return 'iconSvg p-1 m-2'
    }
  },
  mounted() {
    this.getIcons(true)
    this.getTags()
  },
  watch: {
    currentPage: function() {
      this.getIcons()
    },
    query: function() {
      this.currentPage = 0
      this.getIcons()
    },
    activeTag: function() {
      this.currentPage = 0
      this.getIcons()
    }
  },
  methods: {
    getIcons: function(curated = false) {
      let request = {
        method: 'get',
        params: {
          query: this.query,
          tag: this.activeTag,
          svg: 1,
          limit: this.perPage,
          offset: this.currentPage - 1
        },
        url: 'https://icons.eefim.com/api/icons'
      }

      return axios(request).then(data => {
        this.pixelMainImg = data.data.items
        if (curated === true) { this.curated = data.data.icons }
        this.totalRows = data.data.totalRows

        // this.getSvg()
      })
    },
    getTags: function() {
      let request = 'https://icons.eefim.com/api/icons/tags'
      return axios(request).then(data => {
        this.tags = data.data
      })
    },
    selectIcon: function (img) {
      if (!this.requestImage) {
        return
      }
      this.$emit('setResponseImage', {
        provider: 'icons',
        imageId: img.id,
        url: img.files.svg,
        urlThumb: img.files.svg,
        urlProvider: img.files.svg,
        svg: img.svg
      })
    },
    selectTag: function(tag) {
      if (this.activeTag === tag.tag) {
        this.activeTag = null
        return
      }
      this.activeTag = tag.tag
    }
    // getSvg: function () {
    //   for (let i in this.pixelMainImg) {
    //     axios(this.pixelMainImg[i].files.xml).then(data => {
    //       this.pixelMainImg[i].xml = data.data
    //     })
    //   }
    // }
  }
}
</script>

<style scoped>
  .iconSvg {
    height: 36px;
    width: 36px;
    border: 2px solid #f1f5f7;
    line-height: 16px;
  }
  .selectedIcon:hover {
    cursor: pointer;
    border-color: #000;
  }
</style>
