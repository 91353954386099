<template>
  <div>

    <b-form-group
        :label-cols-sm="4"
        :label = "$t('Constructor.PropWidgets.TextShadowWidget.ShadowShift')"
        class="mb-1 pt-0 pb-0"
        label-class="p-1"
    >
      <b-input-group size="sm" class="col-12 pl-0 pr-0 " append="px">
        <b-input-group-prepend is-text>X</b-input-group-prepend>
        <b-form-input
            size="sm"
            placeholder="auto"
            v-model="shadowElem.shiftX.val"
            type="number"
            class=" propField"
            :id="'textShadowshiftX' + index"
            @change="shadowElem.shiftX.val === '' ? shadowElem.shiftX.val = 0 : null"
        ></b-form-input>
        <b-input-group-prepend class="border-left-0" is-text>Y</b-input-group-prepend>
        <b-form-input
            placeholder="auto"
            v-model="shadowElem.shiftY.val"
            type="number"
            :id="'textShadowshiftY' + index"
            @change="shadowElem.shiftY.val === '' ? shadowElem.shiftY.val = 0 : null"
        ></b-form-input>
      </b-input-group>
    </b-form-group>

    <b-row class="ml-0 mr-0" align-h="between">
      <b-col cols="6" class="pt-1">
        <b-form-group
            :label="$t('Constructor.PropWidgets.TextShadowWidget.Radius')"
            class="mb-1 pt-0 pb-0"
            label-class="p-1"
            label-cols="5"
            :label-for="'textShadowradius' + index"
        >
          <b-input-group size="sm" class="ml-2" append="px">
            <b-form-input
                placeholder="auto"
                v-model="shadowElem.radius.val"
                type="number"
                class=" propField"
                :id="'textShadowradius' + index"
                @change="shadowElem.radius.val === '' ? shadowElem.radius.val = 0 : null"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="auto">
        <b-form-group
            :label = "$t('Constructor.PropWidgets.TextShadowWidget.ShadowColor')"
            class="mb-1 pt-0 pb-0"
            label-class="p-1"
            label-cols="auto"
        >
          <ColorWidget v-model="shadowElem.color" />
        </b-form-group>

        </b-col>
    </b-row>

    <div v-if="!newShadow" class="mt-3 text-right">
      <b-button size="sm" variant="outline-danger" v-on:click.stop="deleteShadow">
        {{$t('General.Base.Delete')}}
      </b-button>
    </div>
    <div v-if="newShadow" class="mt-3 text-right">
<!--      <span class="mr-4">Можно добавить несколько теней</span>-->
      <b-button size="sm" variant="outline-success" v-on:click.stop="addShadow">
        {{$t('Constructor.PropWidgets.TextShadowWidget.AddShadow')}}
      </b-button>
    </div>
    <hr>
  </div>
</template>

<script>
import lodash from "lodash";
import ColorWidget from "./../ColorWidget";

export default {
  name: "textShadow",
  props: [ 'shadowElem', 'newShadow', 'index'],
  components: { ColorWidget },
  methods: {
    addShadow() {
      this.$emit('addShadow', lodash.cloneDeep(this.shadowElem))
      this.shadowElem.shiftX.val = 0
      this.shadowElem.shiftY.val = 0
      this.shadowElem.color = '#000'
      this.shadowElem.radius.val = 0
    },
    deleteShadow() {
      this.$emit('deleteShadow', this.index)
    }
  }
}
</script>
