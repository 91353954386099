export default {
    getNewIdBlock: function (elemId) {
        return  parseInt(Object.keys(this.tagData.htmlElements[elemId].jsBlocks).length) + 1
    },
    createNewBlock: function(elemId) {
        let blockId = this.getNewIdBlock(elemId)
        this.$set(this.tagData.htmlElements[elemId].jsBlocks, blockId, {main: {}, css: {}})
        return blockId
    }
}
