<template>
  <div v-if="showProp" class="mt-2">
    <b-form-group
        :label-cols-sm="prop.labelCol ? prop.labelCol  : 4"
        :label="$t('Constructor.Props.' + blockProps + '.' + prop.name + 'X.label')"
        :label-for="blockProps + nameProp"
        class="mb-1 pt-0 pb-0"
        label-class="p-1 "
    >
<!--                  v-b-tooltip.viewport.hover.ds500.dh0.html.left="getTooltipLabel()"-->
      <template v-slot:label>
        <span for="maskRepeatX">
          {{$t('Constructor.Props.' + blockProps + '.' + prop.name + 'X.label') }}
        </span>
      </template>

      <b-form-select
          class="col-12"
          :options="localizedOptions(prop.options)"
          v-model="positionX"
          name="maskRepeatX"
          placeholder="auto"
          size="sm"
      ></b-form-select>
    </b-form-group>

    <b-form-group
        :label-cols-sm="prop.labelCol ? prop.labelCol  : 4"
        :label="$t('Constructor.Props.' + blockProps + '.' + prop.name + 'Y.label')"
        :label-for="blockProps + nameProp"
        class="mb-1 pt-0 pb-0"
        label-class="p-1 "
    >
      <!--                  v-b-tooltip.viewport.hover.ds500.dh0.html.left="getTooltipLabel()"-->
      <template v-slot:label>
        <span for="maskRepeatY">
          {{$t('Constructor.Props.' + blockProps + '.' + prop.name + 'Y.label') }}
        </span>
      </template>

      <b-form-select
          class="col-12"
          :options="localizedOptions(prop.options)"
          v-model="positionY"
          name="maskRepeatY"
          placeholder="auto"
          size="sm"
      ></b-form-select>
    </b-form-group>
  </div>
</template>

<script>
import PropsHelper from "@/helpers/constructor/sideBar/props/PropsComputed";
import ShowProp from '@/helpers/constructor/sideBar/props/MethodShowProp'
import HistoryHelper from '@/helpers/constructor/HistoryHelper'
import lodash from "lodash";

export default {
  name: "WidgetMaskRepeat",
  // components: {  },
  props: [ 'elem', 'prop', 'tagData', 'indexElem', 'settingConstructor', 'nameProp' ],
  data: function() {
    return {
      defaultUnit: 'px',
      blockProps: 'css'
    }
  },
  computed: {
    ...ShowProp,
    ...PropsHelper,
    positionX: {
      get: function() {
        if (this.elemCurrentProp.maskRepeat === undefined) {
          return this.prop.default
        }
        return this.elemCurrentProp.maskRepeat[0]
      },
      set: function(value) {
        if (value.val !== undefined && value.val === '' && this.elemCurrentProp.maskRepeat !== undefined) {
          this.HistorySet(this.elemCurrentProp.maskRepeat, 0, this.prop.default )
          return
        }
        if (this.elemCurrentProp.maskRepeat === undefined) {
          this.HistorySet(this.elemCurrentProp, 'maskRepeat', { 0: value, 1: this.prop.default })
        } else {
          this.HistorySet(this.elemCurrentProp.maskRepeat, 0,  value)
        }
      }
    },
    positionY: {
      get: function() {
        if (this.elemCurrentProp.maskRepeat === undefined) {
          return this.prop.default
        }
        return this.elemCurrentProp.maskRepeat[1]
      },
      set: function(value) {
        if (value.val !== undefined && value === '' && this.elemCurrentProp.maskRepeat !== undefined) {
          this.HistorySet(this.elemCurrentProp.maskRepeat, 1, this.prop.default )
          return
        }

        if (this.elemCurrentProp.maskRepeat === undefined) {
          this.HistorySet(this.elemCurrentProp, 'maskRepeat', { 0: this.prop.default, 1: value })
        } else {
          this.HistorySet(this.elemCurrentProp.maskRepeat, 1,  value)
        }
      }
    }
  },
  methods: {
    ...HistoryHelper,
    localizedOptions(options) {
      options = options.map((elem) => {
        let elem2 = lodash.clone(elem)
        elem2.text = this.$t('Constructor.Props.' + this.blockProps + '.' + this.prop.name + 'X.options.' + elem2.text)
        return elem2
      });
      return options
    }
  }
}
</script>

<style scoped>

</style>
