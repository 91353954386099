<template>
  <div>
    <h4 class="card-title">
      {{$t('Constructor.PropWidgets.CssTransformWidget.Transform')}}
    </h4>

    <b-form-group
        label-cols-sm="5"
        :label = "$t('Constructor.PropWidgets.CssTransformWidget.TranslateX')"
        label-for="translateX"
        class="mb-1"
        label-class="p-1"
    >
      <b-input-group>
        <b-form-input
            :placeholder = "$t('General.Form.NoSet')"
            v-model="vModelTranslateX"
            type="number"
            name="translateX"
            class="col-6 propField"
            size="sm"
            v-b-tooltip.hover
            :title = "$t('Constructor.PropWidgets.CssTransformWidget.TranslateXTooltip')"
        >
        </b-form-input>
        <b-form-select
            class="col-6"
            :options="units"
            v-model="vModelTranslateXUnit"
            size="sm"
            v-b-tooltip.hover
            :title = "$t('Constructor.PropWidgets.CssTransformWidget.TranslateXTooltipUnit')"
        ></b-form-select>
      </b-input-group>
    </b-form-group>

    <b-form-group
        label-cols-sm="5"
        :label = "$t('Constructor.PropWidgets.CssTransformWidget.TranslateY')"
        label-for="translateY"
        class="mb-1"
        label-class="p-1"
    >
      <b-input-group>
        <b-form-input
            :placeholder = "$t('General.Form.NoSet')"
            v-model="vModelTranslateY"
            type="number"
            name="translateY"
            class="col-6 propField"
            size="sm"
            v-b-tooltip.hover
            :title="$t('Constructor.PropWidgets.CssTransformWidget.TranslateYTooltip')"
        >
        </b-form-input>
        <b-form-select
            class="col-6"
            :options="units"
            v-model="vModelTranslateYUnit"
            size="sm"
            v-b-tooltip.hover
            :title="$t('Constructor.PropWidgets.CssTransformWidget.TranslateYTooltipUnit')"
        ></b-form-select>
      </b-input-group>
    </b-form-group>

    <b-form-group
        label-cols-sm="5"
        :label="$t('Constructor.PropWidgets.CssTransformWidget.Rotate')"
        label-for="rotate"
        class="mb-0"
        label-class="p-1"
    >
      <b-input-group>
        <b-form-input
            :placeholder = "$t('General.Form.NoSet')"
            v-model="vModelRotate"
            type="number"
            name="rotate"
            class="col-6 propField"
            size="sm"
            v-b-tooltip.hover
            :title="$t('Constructor.PropWidgets.CssTransformWidget.RotateTooltip')"
        >
        </b-form-input>
        <b-form-select
            class="col-6"
            :options="unitsRotate"
            v-model="vModelRotateUnit"
            size="sm"
            v-b-tooltip.hover
            :title="$t('Constructor.PropWidgets.CssTransformWidget.RotateTooltipUnit')"
        ></b-form-select>
      </b-input-group>
    </b-form-group>
  </div>
</template>

<script>
import PropsHelper from "@/helpers/constructor/sideBar/props/PropsComputed";
import HistoryHelper from '@/helpers/constructor/HistoryHelper'

export default {
  name: "CssTransformWidget",
  props: ['nameProp', 'prop', 'elem', 'tagData', 'indexElem', 'settingConstructor'],
  data: function(){
    return {
      units: ['px','%'],
      unitsRotate: ['deg'],
      blockProps: 'css'
    }
  },
  computed: {
    ...PropsHelper,
    vModelTranslateX: {
      get: function() {
        return this.getValue('translateX')
      },
      set: function(value) {
        this.checkTransform()
        if (value === '') {
          this.HistoryDelete(this.elemCurrentProp.transform, 'translateX')
          // this.$delete(this.elemCurrentProp.transform, 'translateX')
        } else {
          this.HistorySet(this.elemCurrentProp.transform, 'translateX', { val: value, unit: this.vModelTranslateXUnit})
          // this.$set(this.elemCurrentProp.transform, 'translateX', { val: value, unit: this.vModelTranslateXUnit})
        }
      }
    },
    vModelTranslateXUnit: {
      get: function() {
        return this.getUnit('translateX')
      },
      set: function(value) {
        return this.setUnit(value, 'translateX')
      }
    },
    vModelTranslateY: {
      get: function() {
        return this.getValue('translateY')
      },
      set: function(value) {
        this.checkTransform()
        if (value === '') {
          this.HistoryDelete(this.elemCurrentProp.transform, 'translateY')
          // this.$delete(this.elemCurrentProp.transform, 'translateY')
        } else {
          // this.$set(this.elemCurrentProp.transform, 'translateY', { val: value, unit: this.vModelTranslateYUnit})
          this.HistorySet(this.elemCurrentProp.transform, 'translateY', { val: value, unit: this.vModelTranslateYUnit})
        }
      }
    },
    vModelTranslateYUnit: {
      get: function() {
        return this.getUnit('translateY')
      },
      set: function(value) {
        return this.setUnit(value, 'translateY')
      }
    },
    vModelRotate: {
      get: function() {
        return this.getValue('rotate')
      },
      set: function(value) {
        this.checkTransform()
        if (value === '') {
          this.HistoryDelete(this.elemCurrentProp.transform, 'rotate')
          // this.$delete(this.elemCurrentProp.transform, 'rotate')
        } else {
          this.HistorySet(this.elemCurrentProp.transform, 'rotate', { val: value, unit: this.vModelRotateUnit})
          // this.$set(this.elemCurrentProp.transform, 'rotate', { val: value, unit: this.vModelRotateUnit})
        }
      }
    },
    vModelRotateUnit: {
      get: function() {
        return this.getUnit('rotate')
      },
      set: function(value) {
        return this.setUnit(value, 'rotate')
      }
    },
  },
  methods: {
    ...HistoryHelper,
    checkTransform() {
      if (this.elemCurrentProp['transform'] === undefined) {
        this.HistorySet(this.elemCurrentProp, 'transform', {})
        // this.$set(this.elemCurrentProp, 'transform', {})
      }
    },
    getValue(transform) {
      if (this.elemCurrentProp['transform'] === undefined || this.elemCurrentProp['transform'][transform] === undefined) {
        return ''
      }
      return this.elemCurrentProp['transform'][transform].val
    },
    getUnit(transform) {
      if (this.elemCurrentProp['transform'] === undefined || this.elemCurrentProp['transform'][transform] === undefined) {
        if (transform === 'rotate') {
          return this.unitsRotate[0]
        } else {
          return this.units[0]
        }
      }
      return this.elemCurrentProp['transform'][transform].unit
    },
    setUnit(value, transform) {
      if (this.elemCurrentProp['transform'] === undefined || this.elemCurrentProp['transform'][transform] === undefined) {
        return
      }
      this.HistorySet(this.elemCurrentProp.transform[transform], 'unit', value)
      // this.elemCurrentProp.transform[transform].unit = value
    }
  }
}
</script>

<style scoped>

</style>
