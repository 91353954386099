<template>
  <div
    class="containerRuler"
  >
    <div
        id="rulerX"
        class="rulerXTop"
        ref="rulerX"
    >
<!--      <vue-scroll-->
<!--          id="rulerContainerrulerX"-->
<!--          class="rulerContainer"-->
<!--          ref="rulerContainer"-->
<!--          :style="{-->
<!--              width: settingConstructor.constructorArea.ruler.size + 'px',-->
<!--              transform: 'scale('+ settingConstructor.constructorArea.scale +')',-->
<!--            }"-->
<!--      >-->
<!--        <div-->
<!--            class= "rulerSerif"-->
<!--            v-for="index in getCountSerif()"-->
<!--            :key="index"-->
<!--            v-bind:style="{-->
<!--              width: step + 'px'-->
<!--            }"-->
<!--        >-->
<!--          {{ getTextSerif(index) }}-->
<!--        </div>-->
<!--      </vue-scroll>-->
<!--      <vue-scroll-->
<!--          :ops="ops"-->
<!--      >-->
      <div id="rulerContainerrulerX" class="rulerContainer" ref="rulerContainer"
           :style="{
              width: settingConstructor.constructorArea.ruler.size + 'px',
              transform: 'scale('+ settingConstructor.constructorArea.scale +')',
            }"
           @scroll="scroll"
      >

        <div
            class= "rulerSerif"
            v-for="index in getCountSerif()"
            :key="index"
            v-bind:style="{
              width: step + 'px'
            }"
        >
          {{ getTextSerif(index) }}
        </div>

      </div>
<!--      </vue-scroll>-->
    </div>

    <div
        id="rulerY"
        class="rulerYTop"
        v-bind:style="{height: settingConstructor.workspace.height - 3 + 'px'}"
        ref="rulerY"
    >
      <div id="rulerContainerrulerY" class="rulerContainer"
           :style="{
              height: settingConstructor.constructorArea.ruler.size + 'px',
              transform: 'scale('+ settingConstructor.constructorArea.scale +')'
            }"
           ref="rulerContainer">
        <div
            class = "rulerSerif"
            v-for = "index in getCountSerif()"
            :key = "index"
            v-bind:style = "{
              height: step + 'px',
              // paddingTop: step/3 + 'px'
            }"

        >
          {{ getTextSerif(index) }}
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "ContainerRuler",
  data: function () {
    return {
      step: 50,
      // ops: {
      //   rail: {
      //     opacity: '0.2',
      //     background: undefined,
      //     border: '0px solid #cecece',
      //     size: '2px'
      //   },
      //   bar: {
      //     background: '#00ff00',
      //     keepShow: true,
      //     size: '2px',
      //     minSize: 0.2
      //   },
      // }
    }
  },
  props: ['settingConstructor', 'constAreaLeft', 'constAreaTop', 'workspaceHeight', 'workspaceWidth'],
  computed: {
    constructorAreaWidth: function () {
      return this.settingConstructor.constructorArea.width
    }
  },
  watch: {
    constructorAreaWidth: function(width) {
      this.settingConstructor.constructorArea.ruler.difAreaOneSide.width =
          Math.round((this.settingConstructor.constructorArea.ruler.size - width)/40)*20
    },
    constAreaLeft: function(val) {
      this.rulerX = document.getElementById("rulerContainerrulerX")
      this.rulerX.style.left = -(this.settingConstructor.constructorArea.ruler.difAreaOneSide.width
          - this.settingConstructor.constructorArea.ruler.zeroShift.x
      ) + val + 'px'
    },
    constAreaTop: function(val) {
      this.rulerY = document.getElementById("rulerContainerrulerY")
      this.rulerY.style.top = -(this.settingConstructor.constructorArea.ruler.difAreaOneSide.height
          - this.settingConstructor.constructorArea.ruler.zeroShift.y
      ) + val + 'px'
    }
  },
  methods: {
    getCountSerif() {
      return Math.floor(this.settingConstructor.constructorArea.ruler.size/this.step)
    },
    getTextSerif(index) {
      index--
      const l = index * this.step % 50
      if (l !== 0) {
        return ''
      }
      return -100 + index * this.step
    },
    scroll(e) {
      console.log(e)
    }
  },
  mounted() {
    let workspace = document.getElementById('workspace')
    // let rulerX = document.getElementById("rulerX")
    workspace.addEventListener('scroll', () => {
      this.$refs.rulerX.scrollLeft = workspace.scrollLeft
      this.$refs.rulerY.scrollTop = workspace.scrollTop
    })
  }
}
</script>

<style scoped>
  .rulerXTop {
    position: relative;
    height: 10px;
    overflow: hidden;
    /*margin-left: 11px;*/
    scrollbar-width: none;
    background: #f1f5f7;
    top: 1px;
    left: 2px;
    width: calc(100% - 3px);
  }
  .rulerYTop {
    position: absolute;
    left: 1px;
    width: 10px;
    text-align: center;
    top: 2px;
    overflow: hidden;
    scrollbar-width: none;
    background: #f1f5f7;
  }
  .rulerXTop::-webkit-scrollbar, rulerYTop::-webkit-scrollbar {
    width: 0;
    /*background: rgba(255, 255, 255, 0.0);*/
  }

  .rulerXTop .rulerSerif {
    font-size: 7px;
    border-right: 1px solid #d8dcde;
    text-align: left;
    padding-left: 2px;
  }
  .rulerYTop .rulerSerif {
    font-size: 7px;
    border-bottom: 1px solid #d8dcde;
    /*width: 15px;*/
    text-align: center;
    /*padding-right: 2px;*/
    /*padding-left: 3px;*/
    writing-mode: vertical-lr;
    text-orientation: upright;
  }
  .rulerYTop .rulerSerif:first-child, .rulerXTop .rulerSerif:first-child {
    font-size: 0;
  }
  .rulerYTop .rulerSerif:first-child {
    border-bottom: 1px solid #d8dcde;
    width: 10px;
  }
  .rulerSerif:hover {
    font-size: 12px;
    color: #000000;
    line-height: 10px;
    font-weight: bold;
    cursor: default;
  }
  .rulerYTop .rulerSerif:hover {
    font-size: 10px;
  }
  #rulerContainerrulerX {
    display: flex;
  }
  .rulerContainer {
    position: absolute;
    top: 0;
    left: 0;
  }
  .containerRuler {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
  }
</style>
