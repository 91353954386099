<template>
      <b-tabs content-class="pt-2">
        <b-tab :active="blockShow === 'main'" @click="blockShow = 'main'" class="border-0" title-link-class="font-size-11 p-1 pb-0">
          <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-home"></i>
                    </span>
            <span class="d-none d-sm-inline-block">{{$t('General.Base.Basic')}}</span>
          </template>
          <elemMain v-if="blockShow === 'main'" :tagData="tagData" :settingConstructor="settingConstructor" />
        </b-tab>

        <b-tab :active="blockShow === 'css'" @click="blockShow = 'css'" title-link-class="font-size-11 p-1 pb-0">
          <template  v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="far fa-user"></i>
                    </span>
            <span class="d-none d-sm-inline-block">{{$t('Constructor.General.Decoration')}}</span>
          </template>

          <b-row class="m-0 pl-0 mb-2" align-h="between">
            <b-col cols="auto" class="pl-0">
              <label class="mb-0">
                <b-checkbox
                    value="1"
                    v-model="settingConstructor.cssPseudoClasses.hover"
                    size="sm"
                    :class="settingConstructor.cssPseudoClasses.hover ? 'text-danger' : ''"
                    class="font-size-11 mb-0"
                    v-b-tooltip.viewport.hover.ds500.dh0.html.left = "$t('Constructor.General.HoverTooltip')"
                >
                  :{{$t('Constructor.General.Hover')}}
                  <i
                      class= "far fa-question-circle"
                  ></i>
                </b-checkbox>
<!--                <input-->
<!--                    type="checkbox"-->
<!--                    value="1"-->
<!--                    v-model="settingConstructor.cssPseudoClasses.hover"-->
<!--                    size="sm"-->
<!--                >-->

              </label>
            </b-col>
            <b-col cols="auto" class="pr-0">
                <small v-if="settingConstructor.desktopMode !== 'default'" class="text-right text-primary font-size-11">
                    {{settingConstructor.desktopMode}}px
                </small>
                <b-button-group size="sm">
                    <b-button
                      size="sm"
                      variant="outline-dark"
                      :pressed = "settingConstructor.desktops.desktop.width === settingConstructor.desktopMode"
                      @click = "changeDesktopMode(settingConstructor.desktops.desktop.width)"
                      v-b-tooltip.viewport.hover.ds1000.dh0.html.left
                      :title="$t('Constructor.General.DesktopBreakpointTooltips.Default')"
                    >
                        <i class="mdi mdi-desktop-mac"></i>
                    </b-button>
                    <b-button
                      size="sm"
                      variant="outline-dark"
                      :pressed = "settingConstructor.desktops.laptop.width === settingConstructor.desktopMode"
                      @click = "changeDesktopMode(settingConstructor.desktops.laptop.width)"
                      v-b-tooltip.viewport.hover.ds1000.dh0.html.left
                      :title="$t('Constructor.General.DesktopBreakpointTooltips.Laptop')"
                      active-class="bg-danger"
                      exact-active-class="bg-danger"
                      class
                    >
                        <i class="mdi mdi-laptop"></i>
                    </b-button>
                    <b-button
                      size="sm"
                      variant="outline-dark"
                      :pressed = "settingConstructor.desktops.tablet.width === settingConstructor.desktopMode"
                      @click = "changeDesktopMode(settingConstructor.desktops.tablet.width)"
                      v-b-tooltip.viewport.hover.ds1000.dh0.html.left
                      :title="$t('Constructor.General.DesktopBreakpointTooltips.Tablet')"
                    >
                        <i class="mdi mdi-tablet-ipad"></i>
                    </b-button>
                    <b-button
                      size="sm"
                      variant="outline-dark"
                      :pressed = "settingConstructor.desktops.mobile2.width === settingConstructor.desktopMode"
                      @click = "changeDesktopMode(settingConstructor.desktops.mobile2.width)"
                      v-b-tooltip.viewport.hover.ds1000.dh0.html.left
                      :title="$t('Constructor.General.DesktopBreakpointTooltips.Mobile')"
                    >
                        <i class="mdi mdi-cellphone"></i>
                    </b-button>
                </b-button-group>



            </b-col>
          </b-row>

          <sideBarProps v-if="blockShow === 'css'" blockProps="css" activeTab="position" :tagData="tagData" :settingConstructor="settingConstructor" />
        </b-tab>

        <b-tab :active="blockShow === 'js'" @click="blockShow = 'js'" title-link-class="font-size-11 p-1 pb-0">
          <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="far fa-user"></i>
                    </span>
            <span class="d-none d-sm-inline-block">{{$t('Constructor.General.Scripts')}}</span>
          </template>
          <mainJs v-if="blockShow === 'js'" :tagData="tagData" :settingConstructor="settingConstructor" />
        </b-tab>

        <template #tabs-start>
          <b-button
              v-if="settingConstructor.sideBar.withSideBar === settingConstructor.sideBar.withSideBarStock"
              variant="link"
              class="color-stock ml-0 mr-1 p-0 pl-1 pr-1"
              v-b-tooltip
              :title = "$t('Constructor.General.IncreaseWidthSideBar')"
          >
            <i class="mdi mdi-arrow-collapse-left second m-0 font-size-14" @click="settingConstructor.sideBar.withSideBar = settingConstructor.sideBar.withSideBarBig"></i>
          </b-button>
          <b-button
              v-if="settingConstructor.sideBar.withSideBar === settingConstructor.sideBar.withSideBarBig"
              variant="link"
              class="color-stock ml-0 mr-1 p-0 pl-1 pr-1"
              v-b-tooltip
              :title = "$t('Constructor.General.ReduceWidthSideBar')"
          >
            <i class="mdi mdi-arrow-collapse-right m-0 font-size-18" @click="settingConstructor.sideBar.withSideBar = settingConstructor.sideBar.withSideBarStock"></i>
          </b-button>

        </template>

      </b-tabs>
</template>

<script>
import sideBarProps from "./_sideBarProps"
import elemMain from "./_elemMain"
import mainJs from "./jsProp/_mainJs"

export default {
  name: "sideBarElem",
  data: function () {
    return {
      blockShow: 'main'
    }
  },
  props: ['tagData', 'settingConstructor'],
  components: { sideBarProps, elemMain, mainJs },
  methods: {
    changeDesktopMode(width) {
        this.settingConstructor.desktopMode = width
    }
  }
}
</script>
