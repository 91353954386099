<template>
  <div class="m-0 p-0 position-relative" id="workspaceParent">
    <ContainerRuler
        :settingConstructor="settingConstructor"
        :constAreaLeft = "settingConstructor.constructorArea.left"
        :constAreaTop = "settingConstructor.constructorArea.top"
        :workspaceHeight = "settingConstructor.workspace.height"
        :workspaceWidth = "settingConstructor.workspace.width"
    />

<!--    <ContainerRuler axis="y" id="rulerY" :settingConstructor="settingConstructor" />-->

    <div id="workspace"
         v-bind:style="{
                // width: settingConstructor.workspace.width + 'px' ,
                height: settingConstructor.workspace.height + 'px'
            }"
         :class="settingConstructor.drags.activeElem ? 'workspaceActiveContainer' : ''"
         ref="workspace"
         @mousedown="onMouseDown"
    >

      <div
          id="constructorArea"
          v-bind:style="{
                width: settingConstructor.constructorArea.width + settingConstructor.constructorArea.paddings * 2 + 'px' ,
                height: settingConstructor.constructorArea.height + settingConstructor.constructorArea.paddings * 2 + 'px',
                top: settingConstructor.constructorArea.top + 'px',
                left: settingConstructor.constructorArea.left + 'px',
                transform: 'scale('+ settingConstructor.constructorArea.scale +')',
                padding: settingConstructor.constructorArea.paddings + 'px'
              }"
          ref="constructorArea"
      >
        <div id="constructorAreaReal" :class="classConstructorArea">
          <ElemContainer
              v-if="settingConstructor.drags.activeElem !== null"
              :settingConstructor="settingConstructor"
              :tagData="tagData"
              :elem_id="settingConstructor.drags.activeElem"
              :elemSingle="null"
          />
  <!--        <Elem-->
  <!--            v-if = "settingConstructor.activeMainElemId !== null"-->
  <!--            :elemChild="tagData.htmlElementsTree[settingConstructor.activeMainElemId]"-->
  <!--            :settingConstructor="settingConstructor"-->
  <!--            :tagData="tagData"-->
  <!--            :index="settingConstructor.activeMainElemId"-->
  <!--            :draggable_ico="true"-->
  <!--        />-->

          <Elem
              v-if = "settingConstructor.activeMainElemId !== null"
              :settingConstructor="settingConstructor"
              :tagData="tagData"
              :tree="tagData.htmlElementsTree[settingConstructor.activeMainElemId]"
              :index = settingConstructor.activeMainElemId
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContainerRuler from "@/components/constructor/ContainerRuler"
// import Elem from "../_constructorElem"
import ElemContainer from "./_elemContainer"
import Elem from './elements/elem'

export default {
  name: "constructorArea",
  props: ["tagData", "settingConstructor"],
  components: { ContainerRuler, ElemContainer, Elem }, //Elem
  data: function () {
    return {
      dragArea: false,
      rulerX: null,
      rulerY: null,
      classArea: [],
      drags: {
        shift: {
          x: null,
          y: null
        },
        dif: {
          top: null,
          left: null
        },
        workspace: {
          left: null,
          top: null,
          topShift: null,
          leftShift: null
        },
        cursorStartX: null,
        cursorStartY: null,
        scrollStartX: null,
        scrollStartY: null,
      }
    }
  },
  watch: {
    scale: function() {
      this.checkExitBeyondLeft(true)
      this.checkExitBeyondTop(true)
    },
    constructorAreaWidth: function() {
      this.checkExitBeyondLeft(true)
    }
  },
  computed: {
    constructorAreaWidth: function () {
      return this.settingConstructor.constructorArea.width
    },
    scale: function() {
      return this.settingConstructor.constructorArea.scale;
    },
    classConstructorArea: function() {
      let cssClass=[]
      if (!this.settingConstructor.theme) {
        cssClass.push('constructorLightTheme')
      } else {
        cssClass.push('constructorDarkTheme')
      }
      cssClass = this.classArea.concat(cssClass);
      return cssClass
    }
  },
  mounted() {
    this.calculateCoordConstr()

    addEventListener('resize', () => { this.calculateCoordConstr() });

    // есть баг, по все видимости иногда функция calculateCoordConstr запускается до полного рендера страницы и значения не верны.
    // Попытка фикса
    // setTimeout(this.calculateCoordConstr, 1000)

  },
  methods: {
    onMouseDown: function(event) {
      if (this.dragArea || event.target.classList.contains('elemContainer') ) return // || event.target.id !== 'constructorArea'
      this.dragArea = true

      // this.classArea.push('constructorAreaDrag')
      event.target.classList.add('constructorAreaDrag')

      // this.drags.workspace.left = this.settingConstructor.workspace.left + this.drags.workspace.leftShift
      // this.drags.workspace.top = this.settingConstructor.workspace.top + this.drags.workspace.topShift

      // this.drags.shift.x = event.clientX - event.currentTarget.getBoundingClientRect().left
      // this.drags.shift.y = event.clientY - event.currentTarget.getBoundingClientRect().top

      // this.drags.dif.left = Math.abs(this.settingConstructor.constructorArea.left)//(this.settingConstructor.constructorArea.width - this.settingConstructor.workspace.width) / 2
      // this.drags.dif.top = Math.abs(this.settingConstructor.constructorArea.top) //(this.settingConstructor.constructorArea.height - this.settingConstructor.workspace.height) / 2

      document.addEventListener('mousemove', this.onMouseMove)
      document.addEventListener('mouseup', this.onMouseUp)

      document.ondragstart = function() { return false }
      document.body.onselectstart = function() { return false }

      this.drags.scrollStartX = this.$refs.workspace.scrollLeft
      this.drags.scrollStartY = this.$refs.workspace.scrollTop

      this.drags.cursorStartX  = event.pageX
      this.drags.cursorStartY = event.pageY
      // console.log()
    },
    onMouseMove: function(event) {
      if (!this.dragArea) return

      this.$refs.workspace.scrollLeft = this.drags.scrollStartX + this.drags.cursorStartX - event.pageX
      this.$refs.workspace.scrollTop = this.drags.scrollStartY + this.drags.cursorStartY - event.pageY
      // let left = Math.ceil(event.pageX - this.settingConstructor.workspace.left - this.drags.shift.x - this.drags.dif.left)
      // let top = Math.ceil(event.pageY - this.settingConstructor.workspace.top - this.drags.shift.y - this.drags.dif.top)

      // console.log(this.$refs.workspace.scrollLeft)
      // this.checkExitBeyondLeft(false, left)
      // this.checkExitBeyondTop(false, top)
    },
    onMouseUp: function (event) {
      if (!this.dragArea) return
      this.dragArea = false

      event.target.classList.remove('constructorAreaDrag')

      // this.classArea.pop() //constructorAreaDrag
      document.ondragstart = null
      document.body.onselectstart = null
    },
    calculateCoordConstr() {
      this.settingConstructor.constructorArea.left = 0
      this.settingConstructor.constructorArea.top = 0

      const coord = this.$refs.workspace.getBoundingClientRect() //@TODO: пересчитывать эти значение при изменении размера окна, либо раб. области
      this.settingConstructor.workspace.top = coord.top + window.scrollY
      this.settingConstructor.workspace.left = coord.left + window.scrollX
      this.settingConstructor.workspace.right = coord.right
      this.settingConstructor.workspace.bottom = coord.bottom

      this.settingConstructor.workspace.width = Math.ceil(coord.width)
      this.settingConstructor.workspace.height = window.innerHeight - 80 //@TODO: расчитывать высоту адекватней, но это скорей задача по редизайну

      //
      // this.settingConstructor.constructorArea.left = Math.ceil(-(this.settingConstructor.constructorArea.width - this.settingConstructor.workspace.width)/2)
      // this.settingConstructor.constructorArea.top = Math.ceil(-(this.settingConstructor.constructorArea.height - this.settingConstructor.workspace.height)/2)

    },
    calculateScaleShift() {
      this.drags.workspace.leftShift = Math.ceil((this.settingConstructor.constructorArea.width -
          this.settingConstructor.constructorArea.width * this.settingConstructor.constructorArea.scale
      )/2)
      this.drags.workspace.topShift = Math.ceil((this.settingConstructor.constructorArea.height -
          this.settingConstructor.constructorArea.height * this.settingConstructor.constructorArea.scale
      )/2)
    },
    checkExitBeyondLeft(updateSetting, left) {
      if (left === undefined) {
        this.calculateScaleShift()
        let coord = this.$refs.constructorArea.getBoundingClientRect()
        left = coord.left
      }
      const width = this.settingConstructor.workspace.width - 3 // 2px - бодеров + 1px неизвестной магии
      let difLeft = left + this.settingConstructor.constructorArea.width - this.drags.workspace.leftShift
      if (difLeft <  width) {
        left = -(this.settingConstructor.constructorArea.width - width - this.drags.workspace.leftShift)
      }

      difLeft = left + this.drags.workspace.leftShift
      if (difLeft > 0) {
        left = - this.drags.workspace.leftShift
      }

      if (updateSetting) {
        this.settingConstructor.constructorArea.left = left
      } else {
        this.$refs.constructorArea.style.left = left + 'px'
      }
    },
    checkExitBeyondTop(updateSetting, top) {
      if (top === undefined) {
        this.calculateScaleShift()
        let coord = this.$refs.constructorArea.getBoundingClientRect()
        top = coord.top
      }
      const height = this.settingConstructor.workspace.height - 2 // 2 - два бордера
      let difTop = top + this.settingConstructor.constructorArea.height - this.drags.workspace.topShift
      if (difTop < height ) {
        top = -(this.settingConstructor.constructorArea.height - height - this.drags.workspace.topShift)
      }
      difTop = top + this.drags.workspace.topShift
      if (difTop > 0) {
        top = - this.drags.workspace.topShift
      }

      if (updateSetting) {
        this.settingConstructor.constructorArea.top = top
      } else {
        this.$refs.constructorArea.style.top = top + 'px'
      }
    }
  }
}
</script>

<style>

</style>
