import { PatternServiceElems } from './patterns/PatternServiceElems'
import HistoryHelper from '@/helpers/constructor/HistoryHelper'
import TagDataGetElemsHelper from "@/helpers/constructor/TagDataGetElemsHelper";
import TagDataHandlersElem from "@/helpers/constructor/TagDataHandlersElem";
import lodash from "lodash";

export default {
    // TDESchangeElemTree,
    // TDESaddElem,
    // TDESrecursElemTree,
    // TDESsearchElem,
    // TDESaddServiceElem,
    // TDESDelElem,
    // TDESDelServiceElem,
    // TDESElemDeActive,
    // TDESElemActive,
    // TDESGetAllParentsElem
    ...HistoryHelper,
    ...TagDataGetElemsHelper,
    ...TagDataHandlersElem,

    TDESDelServiceElem: function (elemName, parentId) {
        for (let index in this.tagData.htmlElementsTree[parentId]) {
            if (this.tagData.htmlElements[index].name === elemName) {
                this.TDESDelElem(index)
                break
            }
        }
    },

    TDESaddServiceElem: function (elemName, targetParentId) {
        return this.TDESaddElem(PatternServiceElems[elemName], targetParentId)
    },
    TDESDelElemWithChild(elemId) {
        let parentElemList = this.TDESrecursElemTree(elemId, [])
        let elemTree =  this.TDESsearchElem(parentElemList, this.tagData.htmlElementsTree)
        let allElem = this.getAllElemWin(elemId, elemTree, []);

        let action =[
            {obj:elemTree, nameProp: elemId, val: null, type: 'delete'},
            {obj:this.tagData.htmlElements, nameProp: elemId, val: null, type: 'delete'}
        ]

        allElem.forEach(elemChildId => {
            action.push(
                {obj:this.tagData.htmlElements, nameProp: elemChildId, val: null, type: 'delete'}
            )
        })
        this.HistorySetPackage(action)
    },
    TDESDelElem: function(index) {
        // this.TDESchangeElemTree(index, null)
        if (this.settingConstructor.activeMainElemId === index) {
            for (let indexTree in this.tagData.htmlElementsTree) {
                if (indexTree === index) {
                    continue
                }
                this.settingConstructor.activeMainElemId = indexTree
                break
            }
        }
         let parentElemList = this.TDESrecursElemTree(index, [])
         let elemTree = this.TDESsearchElem(parentElemList, this.tagData.htmlElementsTree)

        this.HistorySetPackage([
            {obj:elemTree, nameProp: index, val: null, type: 'delete'},
            {obj:this.tagData.htmlElements, nameProp: index, val: null, type: 'delete'}
        ])
        // this.$delete(this.tagData.htmlElementsTree, index)
        // this.$delete(this.tagData.htmlElements, index)
    },
    TDESCopyElem: function (index, parent = null, childs = {}) {
        let newElem = lodash.cloneDeep(this.tagData.htmlElements[index])
        newElem.active = false
        if (parent === null) {
            parent = newElem.parent
        }
        let newParent = this.TDESaddElem(newElem, parent)

        for (let childId in childs) {
            this.TDESCopyElem(childId, newParent, childs[childId])
        }
        return newParent
    },
    TDESaddElem: function(elem, targetParentId, insertAfterElem = null) {
        this.tagData.htmlElementIndex++
        let index = 'e' + this.tagData.htmlElementIndex
        this.$set(elem, 'id', index)
        this.$set(this.tagData.htmlElements, index, elem)
        this.TDESchangeElemTree(index, targetParentId, insertAfterElem)
        return index
    },
    TDEScreateElem: function(elem, targetParentId, insertAfterElem = null) {
        let children = null
        this.$delete(elem, 'previewCss')
        if (elem.elemChild) {
            children = lodash.cloneDeep(elem.elemChild)
            this.$delete(elem, 'elemChild')
        }

        let elemId = this.TDESaddElem(elem, targetParentId, insertAfterElem)
        if (elem.callback !== undefined) {
            elem.callback(this, elemId)
            this.$delete(this.tagData.htmlElements[elemId], 'callback')
        }

        this.handlingLinksInElem(elem)
        for (let child in children) {
            this.TDEScreateElem(children[child], elemId)
        }
        return elemId
    },
    TDESchangeElemTree: function(elemId, targetParentId, insertAfterElem = null) {
        let parentElemList = this.TDESrecursElemTree(elemId, [])
        let parentTargetElemList = targetParentId !== null ? this.TDESrecursElemTree(targetParentId, [targetParentId]) : []

        let elemTree = this.TDESsearchElem(parentElemList, this.tagData.htmlElementsTree)
        let elemCont = this.TDESsearchElem(parentTargetElemList, this.tagData.htmlElementsTree)


        //@TODO: добавить хранение в истории, видимо нужен метод добавить в последнее действие
        let currentElem = elemTree[elemId] !== undefined ? elemTree[elemId] : {}
        if (elemTree[elemId] !== undefined) {
            this.$delete(elemTree, [elemId])
        }

        if (insertAfterElem === null) {
            this.$set(elemCont, elemId, currentElem)
        } else {
            let keys = Object.keys(elemCont).reverse()
            let skipElems = []
            let skipObjects = {}

            for (let i in keys) {
                if (keys[i] !== insertAfterElem) {
                    skipElems.push(keys[i])
                    skipObjects[keys[i]] = elemCont[keys[i]]
                    // keys.shift()
                    this.$delete(elemCont, keys[i])
                    continue
                }
                break
            }

            this.$set(elemCont, elemId, currentElem)

            skipElems.reverse().forEach(elem => {
                this.$set(elemCont, elem, skipObjects[elem])
            })
        }


        this.$set(this.tagData.htmlElements[elemId], 'parent', targetParentId)
    },

    TDESrecursElemTree: function (id, array) {
        if (this.tagData.htmlElements[id].parent === undefined || this.tagData.htmlElements[id].parent === null) {
            return array
        }
        array.unshift(this.tagData.htmlElements[id].parent)
        return this.TDESrecursElemTree(this.tagData.htmlElements[id].parent, array)
    },

    TDESsearchElem: function (array, elem) {
        array.forEach((id) => {
            elem = elem[id]
        })
        return elem
    },

    TDESElemActive: function (elemId) {
        if (this.tagData.htmlElements[elemId].selectParent === true) {
            elemId = this.searchParentAccessSelect(elemId)
        }

        if (this.settingConstructor.drags.activeElem) {
            this.tagData.htmlElements[this.settingConstructor.drags.activeElem].active = false
        }
        this.tagData.htmlElements[elemId].active = true
        this.settingConstructor.drags.activeElem = elemId
        this.settingConstructor.sideBar.prop = true
        this.settingConstructor.sideBar.elem = false
        this.settingConstructor.hoverElem = null
    },
    searchParentAccessSelect(elemId) {
        if (!this.tagData.htmlElements[elemId].selectParent) {
            return elemId
        }
        return this.searchParentAccessSelect(this.tagData.htmlElements[elemId].parent)
    },
    TDESElemDeActive: function() {
        if (this.settingConstructor.drags.activeElem === null) {
            return
        }
        this.tagData.htmlElements[this.settingConstructor.drags.activeElem].active = false
        this.settingConstructor.drags.activeElem = null
        this.settingConstructor.sideBar.prop = false
        this.settingConstructor.sideBar.elem = true
        this.settingConstructor.sideBar.withSideBar = this.settingConstructor.sideBar.withSideBarStock
        this.settingConstructor.cssPseudoClasses.hover = false
    },

    TDESGetAllParentsElem: function(elem, listParents) {
        if (elem.parent === null) {
            return listParents
        }
        listParents.push(elem.parent)
        return this.TDESGetAllParentsElem(this.tagData.htmlElements[elem.parent], listParents)
    }


};
