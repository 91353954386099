<template>
  <div>
    <!--            v-if="showProp"
    :label-for="nameProp"-->
    <b-form
        v-if="showProp"
        inline
        class="mb-1"
    >
      <label
          :class="'col-3 col-form-label justify-content-start ' + prop.classLabel"
          v-b-tooltip.viewport.hover.ds500.dh0.html.left="getTooltipLabel()"
      >
        {{$t('Constructor.Props.' + blockProps + '.' + prop.text + '.label') }}
      </label>



        <PropElem
            v-for="(element, index) in prop.elems"
            :key="index"
            :prop="element"
            :nameProp="index"
            :elem="elem"
            :indexElem="indexElem"
            :tagData="tagData"
            :blockProps="blockProps"
            :settingConstructor="settingConstructor"
        />


      <b-form-text v-if="$t('Constructor.Props.' + blockProps + '.' + prop.text + '.description')">
        {{ $t('Constructor.Props.' + blockProps + '.' + prop.text + '.description') }}
        <i
            v-if="$t('Constructor.Props.' + blockProps + '.' + prop.text + '.tooltip')"
            v-b-tooltip.hover
            :title="$t('Constructor.Props.' + blockProps + '.' + prop.text + '.tooltip')"
            class="far fa-question-circle"></i>
      </b-form-text>
    </b-form>
  </div>
</template>

<script>
import PropElem from './_propElems'
import ShowProp from '@/helpers/constructor/sideBar/props/MethodShowProp'

export default {
  name: "inline_form_group",
  props: ['prop', 'elem', 'tagData', 'indexElem', 'blockProps', 'settingConstructor'],
  components: { PropElem },
  computed: {
    ...ShowProp
  },
  methods: {
    getTooltipLabel() {
      return this.$t('Constructor.Props.' + this.blockProps + '.' + this.prop.text + '.labelTooltip') ?
          this.$t('Constructor.Props.' + this.blockProps + '.' + this.prop.text + '.labelTooltip') : null
    }
  }
}
</script>

<style scoped>

</style>
