// import PropsHelperMethods from "./PropsHelperMethods";

export default {
    elemTextDefaultProp: function() {
        if (this.settingConstructor.desktopMode === 'default') {
            return false
        }
        if (this.elem.css[this.nameProp] === undefined) {
            return false
        }

        if (typeof(this.elem.css[this.nameProp]) === 'string') {
            return this.elem.css[this.nameProp]; // добавить локализацию
        }
        if (typeof(this.elem.css[this.nameProp]) === 'object' && this.elem.css[this.nameProp].val !== undefined) {
            return this.elem.css[this.nameProp].val + this.elem.css[this.nameProp].unit
        }
        return this.elem.css[this.nameProp]
    },

    elemCurrentProp: function () {

        let elem = this.prop.parent !== undefined && this.prop.parent ? this.tagData.htmlElements[this.elem.parent] : this.elem
        let blockAdd = 'addMain'

        let pseudoClass = null
        if (this.blockProps === 'js') {
            blockAdd = 'addJs'
        }
        if (this.blockProps === 'css') {
            blockAdd = 'addCss'

            if (this.settingConstructor.cssPseudoClasses.hover) {
                blockAdd = 'cssPseudoClasses'
                pseudoClass = 'hover'
                if (elem.cssPseudoClasses[pseudoClass] === undefined) {
                    this.$set(elem.cssPseudoClasses, pseudoClass, {})
                }
            }
        }


        if (this.settingConstructor.js.jsBlockActive[this.indexElem] !== undefined) {
            return elem.jsBlocks[this.settingConstructor.js.jsBlockActive[this.indexElem].jsBlockId][this.blockProps]
        }

        if (this.blockProps === 'main') {
            return elem[this.blockProps]
        }

        if (this.settingConstructor.desktopMode === 'default') {
            if (!pseudoClass) {
                return elem[this.blockProps]
            }
            return elem.cssPseudoClasses[pseudoClass]
        }

        if (elem.addCss[this.settingConstructor.desktopMode] === undefined) {
            this.$set(elem[blockAdd], this.settingConstructor.desktopMode, {})
        }
        if (!pseudoClass) {
            return elem[blockAdd][this.settingConstructor.desktopMode]
        }
        return elem.cssPseudoClasses[pseudoClass]
    }
}
