import deepmerge from "deepmerge";

export const computedElemHelper = {
    elemAddCssReal: function () {
        if (this.elemSingle) {
            return this.elem.previewCss
        }
        if (this.settingConstructor.desktopMode === 'default') {
            return this.elem.css
        }
        return this.elem.addCss[this.settingConstructor.desktopMode]
    },
    elemAddCss: function () {
        if (this.elemSingle) {
            return this.elem.previewCss
        }
        let obj = this.elem.css
        if (this.elem.active && this.settingConstructor.cssPseudoClasses.hover && this.elem.cssPseudoClasses.hover !== undefined) {
            obj = deepmerge(obj, this.elem.cssPseudoClasses.hover)
        }
        if (this.settingConstructor.desktopMode === 'default' && this.settingConstructor.js.jsBlockActive[this.index] === undefined) {
            return obj
        }
        if (this.settingConstructor.desktopMode !== 'default') {
            if (this.elem.addCss[this.settingConstructor.desktopMode] === undefined) {
                this.$set(this.elem.addCss, this.settingConstructor.desktopMode, {})
            }
            let screens = Object.keys(this.elem.addCss)
            screens.sort((a, b) => parseInt(b) - parseInt(a));

            screens.forEach(screen => {
                if (parseInt(screen) < parseInt(this.settingConstructor.desktopMode)) {
                    return
                }
                obj = deepmerge(obj, this.elem.addCss[String(screen)])
                // console.log(this.index, obj, this.elem.addCss[String(screen)], screen, this.elem.addCss)
            })

            // obj = deepmerge(obj, this.elem.addCss[this.settingConstructor.desktopMode])
        }

        if (this.settingConstructor.js.jsBlockActive[this.index] !== undefined) {
            obj = deepmerge(obj, this.elem.jsBlocks[this.settingConstructor.js.jsBlockActive[this.index].jsBlockId].css)
        }
        return obj
    },
    elemAddMain: function() {
        if (this.elemSingle) {
            return this.elem.main
        }

        if (this.settingConstructor.desktopMode === 'default' && this.settingConstructor.js.jsBlockActive[this.index] === undefined) {
            return this.elem.main
        }
        let obj = this.elem.main
        if (this.settingConstructor.desktopMode !== 'default') {
            if (this.elem.addMain[this.settingConstructor.desktopMode] === undefined) {
                this.$set(this.elem.addMain, this.settingConstructor.desktopMode, {})
            } else {
                obj = deepmerge(obj, this.elem.addMain[this.settingConstructor.desktopMode])
            }
        }

        if (this.settingConstructor.js.jsBlockActive[this.index] !== undefined && this.elem.jsBlocks[this.settingConstructor.js.jsBlockActive[this.index].jsBlockId].main !== undefined) {
            obj = deepmerge(obj, this.elem.jsBlocks[this.settingConstructor.js.jsBlockActive[this.index].jsBlockId].main)
        }
        return obj
    }
}
