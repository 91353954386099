<template>
  <div>
    <div class="row ml-0 mr-0">
      <div v-if="value !== null && value !== 'none'" class="col-6">
        <div class="imageFieldPrev"
          :style = "{backgroundImage: 'url(' + value.urlThumb + ')'}"
        ></div>

<!--        <div-->
<!--            v-if="mobileData"-->
<!--            class="imageFieldPrev imageFieldMobile"-->
<!--            :style = "{backgroundImage: 'url(' + mobileData.urlThumb + ')'}"-->
<!--        ></div>-->
<!--        <img class="backgroundImagePrev" :src="value.urlThumb" alt="">-->
      </div>
<!--      <div v-if="mobileData" class="col-6">-->
<!--        -->
<!--        <img class="backgroundImagePrev" :src="mobileData.urlThumb" alt="">-->
<!--        <b-badge variant="info" class="stuckBadge">Установленно</b-badge>-->

<!--&lt;!&ndash;        <img class="backgroundImagePrev" :src="mobileData.urlThumb" alt="">&ndash;&gt;-->
<!--      </div>-->
      <div v-if="mobileData" class="col-6">
        <b-badge variant="info">Установленно</b-badge>
      </div>


      <div class="col-6">
        <b-button v-b-toggle.sidebar-gallery variant="outline-primary" size="sm" class="m-1" v-on:click.stop="selectImage">
          {{$t('General.Base.Select')}}
        </b-button>
        <b-button v-if="value !== null && value !== 'none'" variant="outline-secondary" class="m-1" size="sm" v-on:click.stop="deleteImage">
          {{$t('General.Base.Reset')}}
        </b-button>
      </div>
    </div>
    <div>

<!--      <div class="d-flex mt-2">-->
<!--        <b-input-group size="sm" prepend="w" append="px">-->
<!--          <b-form-input></b-form-input>-->
<!--        </b-input-group>-->
<!--        <i class="dripicons-cross m-1"></i>-->

<!--        <b-input-group size="sm" prepend="h" append="px">-->
<!--          <b-form-input></b-form-input>-->
<!--        </b-input-group>-->
<!--      </div>-->
<!--      <b-card-text>-->
<!--        Разрешение изображения-->

<!--        <i class="far fa-question-circle"></i>-->
<!--      </b-card-text>-->

    </div>
  </div>
</template>

<script>
export default {
  name: "ImageField",
  props: [ "value", "mobileData" ],
  data: function() {
    return {
      requestImage: false
    }
  },
  computed: {
    storeRequestImage: function() {
      return this.$store.state.userGallery.requestImage
    },
    responseImage: function() {
      if (!this.requestImage) {
        return null
      }

      return this.$store.state.userGallery.responseImage
    }
  },
  watch: {
    storeRequestImage: function(requestImage) {
      if (!requestImage && this.requestImage) {
        this.requestImage = false
      }
    },
    responseImage: function(val) {
      if (!this.requestImage) {
        return
      }
      this.$emit('change', val)
      this.$emit('input', val)

      this.$store.commit('userGallery/setRequestImage', false)
      this.$store.commit('userGallery/setResponseImage', null)
      // this.currentImg = val
    }
  },
  methods: {
    selectImage() {
      this.$store.commit('userGallery/setRequestImage', true)
      this.requestImage = true
    },
    deleteImage() {
      this.$emit('change', 'none')
      this.$emit('input', 'none')
    }
  }
}
</script>

<style scoped>
  .backgroundImagePrev {
    max-height: 66px;
  }
  .imageFieldPrev {
    width: 66px;
    height: 66px;
    background-size: cover;
    z-index: 100;
  }
  .imageFieldMobile {
    position: absolute;
    top: 0px;
    left: 20px;
    z-index: 90;
  }
</style>
