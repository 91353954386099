import {isArray} from "lodash";

export default {

    /**
     * Replace this/... in elem object
     */
    handlingLinksInElem(elem)
    {
        this.searchValueInObj(elem.js, 'this',(value, prop, path) => {
            this.$set(path, prop, elem.id)
        }, this.tagData.htmlElements[elem.id].js)
    },

    searchValueInObj(obj, searchValue, func, path) {
        for(var prop in obj) {
            if(typeof(obj[prop]) === 'object' || isArray(obj[prop])) {
                this.searchValueInObj(obj[prop], searchValue, func, path[prop]);
            } else {
                if (obj[prop] === searchValue) {
                    func(obj[prop], prop, path)
                }
            }
        }
    }
}