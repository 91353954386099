<template>
    <b-form-group :label-cols-sm="prop.labelCols !== undefined ? prop.labelCols : 4">
      <template v-slot:label v-if="prop.type === 'number'">
        <label
            v-b-tooltip.hover
            :title="getTooltipLabel()"
            :for="prop.name"
        >
          {{$t('Constructor.Props.js.EventsFields.' + prop.name + '.label') }}
        </label>
      </template>

      <b-input-group v-if="prop.type === 'number'" size="sm">
        <b-form-input
            placeholder="No set"
            v-model="valPropNumb"
            type="number"
            :name="prop.name"
            :required="prop.required"
            class="col-6"
            v-b-tooltip.hover
        >
        </b-form-input>

        <b-form-select
            v-if="prop.type === 'number'"
            class="col-6"
            :options="prop.unit"
            v-model="valPropNumbUnit"
            :disabled="(prop.unit.length===1)"
            v-b-tooltip.hover
        ></b-form-select>
      </b-input-group>

      <b-form-checkbox
          v-if="prop.type === 'checkbox'"
          switch
          v-model="valProp"
          class="mousetrap ml-2"
          :name="prop.name"
          size="sm"
      >
        <label>{{$t('Constructor.Props.js.EventsFields.' + prop.name + '.label') }}</label>
      </b-form-checkbox>

      <b-form-text v-if="$t('Constructor.Props.js.EventsFields.' + prop.name + '.description') ">
        {{$t('Constructor.Props.js.EventsFields.' + prop.name + '.description')}}
      </b-form-text>


    </b-form-group>
</template>

<script>
import HistoryHelper from '@/helpers/constructor/HistoryHelper'

export default {
  name: "renderField",
  props: ["prop", "eventData", "nameProp", "settingConstructor", "tagData"],
  data() {
    return {
      unit: null
    }
  },
  computed: {
    valProp: {
      get: function() {
        if (this.eventData[this.nameProp] === undefined) {
          return null
        }
        return this.eventData[this.nameProp]
      },
      set: function(value) {
        if (this.prop.callback) {
          this.prop.callback(this, value)
        }

        if (value === '') {
          this.HistoryDelete(this.eventData, this.nameProp)
          return
        }

        this.HistorySet(this.eventData, this.nameProp, value)
      }
    },
    valPropNumb: {
      get: function() {
        if (this.eventData[this.nameProp] === undefined) {
          return null
        }
        return this.eventData[this.nameProp].val
      },
      set: function(value) {
        if (this.prop.callback) {
          this.prop.callback(this, value)
        }
        if (value === '') {
          this.HistoryDelete(this.eventData, this.nameProp)
          return
        }
        if (this.eventData[this.nameProp] === undefined) {
          this.HistorySet(this.eventData, this.nameProp, {val: value, unit: this.valPropNumbUnit})
        } else {
          this.HistorySet(this.eventData[this.nameProp], 'val', value)
        }
      }
    },
    valPropNumbUnit: {
      get: function() {
        if (this.eventData[this.nameProp] !== undefined) {
          return this.eventData[this.nameProp].unit
        }
        if (this.unit) {
          return this.unit
        }
        return this.prop.unit[0]
      },
      set: function(value) {
        this.unit = value
        if (this.eventData[this.nameProp] !== undefined) {
          this.HistorySet(this.eventData[this.nameProp], 'unit', value)
        }
      }
    },
  },
  methods: {
    ...HistoryHelper,
    getTooltipLabel() {
      return this.$t('Constructor.Props.js.EventsFields.' + this.prop.name + '.labelTooltip') ?
          this.$t('Constructor.Props.js.EventsFields.' + this.prop.name + '.labelTooltip') : null
    }
  },
  mounted() {
    if (this.prop.mounted !== undefined) {
      this.valProp = this.prop.mounted
    }
  }
}
</script>

<style scoped>

</style>
