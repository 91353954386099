export default {
    showProp: function () {
        if (this.prop.relation === undefined || !this.prop.relation) {
            return true
        }

        function disableDependentProperties(vue) {
            if (vue.elem[vue.blockProps][vue.prop.name] !== undefined) {
                vue.HistoryAddInLastAction(vue.elem[vue.blockProps], vue.prop.name, null, 'delete')
            }
        }

        let elem = this.elem
        if (this.prop.relation.parent !== undefined && this.prop.relation.parent) {
            if (!this.elem.parent) {
                return false
            }
            elem = this.tagData.htmlElements[this.elem.parent]
        }

        // let elem = this.prop.relation.parent !== undefined && this.prop.relation.parent && this.elem.parent !== null ? this.tagData.htmlElements[this.elem.parent] : this.elem

        // elem = this.prop.parent !== undefined && this.prop.parent ? this.tagData.htmlElements[this.elem.parent] : this.elem
        if (elem[this.prop.relation.block][this.prop.relation.properties] === undefined) { //@TODO: !тут может быть проблема с мобайлом. Если свойства есть в мобайле а мы ищем его в основе
            disableDependentProperties(this)
            return false
        }

        // if (this.prop.relation.value.includes('!null') && elem[this.prop.relation.block][this.prop.relation.properties] !== null) {
        //     return true
        // }
        if (this.prop.relation.not_value !== undefined) {
            if (this.prop.relation.not_value.includes(elem[this.prop.relation.block][this.prop.relation.properties])) {
                disableDependentProperties(this)
                return false
            }
        }

        if (this.prop.relation.value === undefined) {
            return true
        }

        if (!this.prop.relation.value.includes(elem[this.prop.relation.block][this.prop.relation.properties])) {
            disableDependentProperties(this)
            return false
        }
        return true

        // if (typeof (this.prop.relation.value) === 'string' && elem[this.prop.relation.block][this.prop.relation.properties] === this.prop.relation.value) {
        //     return true
        // }
        //
        // let flag = false
        // this.prop.relation.value.forEach(val => {
        //     if (elem[this.prop.relation.block][this.prop.relation.properties] !== undefined && elem[this.prop.relation.block][this.prop.relation.properties] === val) {
        //         flag = true
        //     }
        // })
        //
        // if (!flag) {
        //     disableDependentProperties(this)
        // }
        // if (!flag && this.elem[this.blockProps][this.prop.name] !== undefined) {
        //     this.HistoryAddInLastAction(this.elem[this.blockProps], this.prop.name, null, 'delete')
        // }

        // return flag
    }
}
