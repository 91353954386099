<template>
  <b-skeleton-wrapper :loading="true">
    <template #loading>
      <b-row class="p-2">
        <b-col>
          <b-skeleton-img height="170px"></b-skeleton-img>
        </b-col>
        <b-col>
          <b-skeleton-img height="170px"></b-skeleton-img>
        </b-col>
        <b-col>
          <b-skeleton-img height="170px"></b-skeleton-img>
        </b-col>
      </b-row>
      <b-row class="p-2">
        <b-col>
          <b-skeleton-img height="170px"></b-skeleton-img>
        </b-col>
        <b-col>
          <b-skeleton-img height="170px"></b-skeleton-img>
        </b-col>
        <b-col>
          <b-skeleton-img height="170px"></b-skeleton-img>
        </b-col>
      </b-row>
    </template>
  </b-skeleton-wrapper>
</template>

<script>
export default {
  name: "loading"
}
</script>

<style scoped>

</style>
