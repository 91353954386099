<template>
  <div class="mb-3">
    <b-form-group
        inline
        :label-cols-sm="4"
        :label-cols-lg="4"
        :label="$t('General.Base.Action')"
        label-size="sm"
        class="mb-2"
    >
      <b-input-group size="sm">
        <b-form-select
            class="col-12"
            :options="actionsOption()"
            v-model="actionObj.type"
        ></b-form-select>

        <div class="input-group-append">
          <b-button
              v-if="newAction"
              size="sm"
              class=""
              variant="dark"
              v-on:click.stop="addAction"
          ><i class="mdi mdi-plus" ></i></b-button>
          <b-button
              v-if="!newAction"
              size="sm"
              class=""
              variant="dark"
              v-on:click.stop="deleteAction"
          ><i class="mdi mdi-minus" ></i></b-button>
        </div>
      </b-input-group>
    </b-form-group>

    <div v-if="!newAction" class="pl-2 codeLineDashedLeft">
      <RenderField
          v-for="(element, index) in basePropElems.js.actions[actionObj.type].fields"
          :key="index"
          :prop="element"
          :nameProp="index"
          :eventData="actionObj.data"
          :settingConstructor = settingConstructor
          :tagData = tagData
      />

      <CssActionWidget
          v-if="basePropElems.js.actions[actionObj.type].child.widgets === 'css'"
          :tagData = tagData
          :newAction = newAction
          :dataObj = actionObj
          :settingConstructor = settingConstructor
          :indexEvent = indexEvent
          :actionIndex = actionIndex
      />

      <SendFormWidget
          v-if="basePropElems.js.actions[actionObj.type].child.widgets === 'sendForm'"
          :basePropElems="basePropElems"
          :actionObj = actionObj
          :tagData = tagData
          :settingConstructor = settingConstructor
      />
      <!------------------------------ SEND FORM PARENT WIDGET ------START--------------------------------------------->
        <AutoValidateFormWidget
            v-if="basePropElems.js.actions[actionObj.type].child.widgets === 'autoValidateForm'"
            :basePropElems="basePropElems"
            :actionObj = actionObj
            :tagData = tagData
            :settingConstructor = settingConstructor
        />
      <!------------------------------ SEND FORM PARENT WIDGET -------END---------------------------------------------->


      <GetElemWidget
        v-if="basePropElems.js.actions[actionObj.type].child.widgets === 'getElem'"
        :basePropElems="basePropElems"
        :actionObj = actionObj
        :tagData = tagData
        :settingConstructor = settingConstructor
      />

      <GetElemWithAnimateWidget
          v-if="basePropElems.js.actions[actionObj.type].child.widgets === 'getElemWithAnimate'"
          :basePropElems="basePropElems"
          :actionObj = actionObj
          :tagData = tagData
          :settingConstructor = settingConstructor
      />

      <ChangeContentActionWidget
          v-if="basePropElems.js.actions[actionObj.type].child.widgets === 'changeContent'"
          :basePropElems="basePropElems"
          :dataObj = actionObj
          :tagData = tagData
          :settingConstructor = settingConstructor
      />

<!--      <CssSelectorWidget-->
<!--          v-if="basePropElems.js.actions[actionObj.type].child.widgets === 'сssSelector'"-->
<!--          :basePropElems="basePropElems"-->
<!--          :dataObj = actionObj-->
<!--          :tagData = tagData-->
<!--          :settingConstructor = settingConstructor-->
<!--      />-->

      <TooltipPositionWidget
          v-if="basePropElems.js.actions[actionObj.type].child.widgets === 'TooltipPositionWidget'"
          :basePropElems="basePropElems"
          :dataObj = actionObj
          :tagData = tagData
          :settingConstructor = settingConstructor
      />

      <ToggleShowWidget
          v-if="basePropElems.js.actions[actionObj.type].child.widgets === 'toggleShow'"
          :basePropElems="basePropElems"
          :dataObj = actionObj
          :tagData = tagData
          :settingConstructor = settingConstructor
          :eventName = parent
      />

      <ValidateMsgWidget
          v-if="basePropElems.js.actions[actionObj.type].child.widgets === 'validateMsg'"
          :basePropElems="basePropElems"
          :dataObj = actionObj
          :tagData = tagData
          :settingConstructor = settingConstructor
          :elem = elem
      />

    </div>
  </div>
</template>

<script>
import TagDataGetElemsHelper from "@/helpers/constructor/TagDataGetElemsHelper";
import CssActionWidget from './actionWidgets/CssActionWidget'
import GetElemWidget from './actionWidgets/GetElemWidget'
import RenderField from './_renderField'

export default {
  name: "RenderAction",
  props: ["tagData", "settingConstructor", "basePropElems", "action", "newAction", "actionIndex", "indexEvent", "returnData", "parent", "elem"],
  components: {
    CssActionWidget, GetElemWidget, RenderField,
    SendFormWidget: () => import('./actionWidgets/SendFormWidget.vue'),
    ChangeContentActionWidget: () => import('./actionWidgets/ChangeContentActionWidget.vue'),
    TooltipPositionWidget: () => import('./actionWidgets/TooltipPositionWidget.vue'),
    ToggleShowWidget: () => import('./actionWidgets/ToggleShowWidget.vue'),
    AutoValidateFormWidget: () => import('./actionWidgets/AutoValidateFormWidget.vue'),
    ValidateMsgWidget: () => import('./actionWidgets/ValidateMsgWidget.vue'),
    GetElemWithAnimateWidget: () => import('./actionWidgets/GetElemWithAnimateWidget.vue')
  },
  data() {
    return {
      actionObj: {
        data: {}
      },
      newActionPatternObject: {
        type: null,
        data: {}
      }
    }
  },
  computed: {
  },
  created() {
    if (!this.newAction) {
      this.actionObj =  this.action
    } else {
      this.actionObj = this.newActionPatternObject
    }
  },
  methods: {
    ...TagDataGetElemsHelper,
    getAllElements() {
      if (!this.newAction) {
        return this.getAllElementsToOptions()
      }
      return [...[{ value: null, text: this.$t('Constructor.General.SelectItem') }], ...this.getAllElementsToOptions()]
    },
    actionsOption: function() {
      let data =[
        { value: null, text: this.$t('Constructor.General.SelectAction') }
      ]
      for (let index in this.basePropElems.js.actions) {
        if (this.basePropElems.js.actions[index].parent.length && !this.basePropElems.js.actions[index].parent.includes(this.parent)) {
          continue
        }

        if (this.basePropElems.js.actions[index].parent.includes(this.parent)) {
          data.unshift({
            text: this.$t('Constructor.Props.js.Actions.' + index + '.Name'), // this.basePropElems.js.actions[index].name,
            value: index
          })
          continue
        }

        data.push({
          text: this.$t('Constructor.Props.js.Actions.' + index + '.Name'), //this.basePropElems.js.actions[index].name,
          value: index
        })
      }
      return data
    },
    addAction: function() {
      this.$emit('addAction', this.actionObj, this.returnData)
      this.actionObj = {
        type: null,
        data: {}
      }
    },
    deleteAction: function() {
      this.$emit('deleteAction', this.actionIndex, this.returnData)
    }
  }
}
</script>
